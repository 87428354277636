import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "./defaultMigration";

export const cases:SchemaTableOptions = {
    name:<string> 'cases',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
        { column: 'case_number', value: 'VARCHAR(255) NOT NULL'},
        { column: 'patient_id', value: 'INTEGER UNSIGNED'},
        { column: 'initiated_at', value: 'DATETIME DEFAULT NULL'},
        { column: 'is_followup', value: 'BOOLEAN DEFAULT FALSE'},
        { column: 'gender', value: 'TINYINT UNSIGNED DEFAULT NULL'},
        { column: 'is_reabuse', value: 'BOOLEAN DEFAULT FALSE'},
        { column: 'incident_time', value: 'INTEGER UNSIGNED'},
        { column: 'birthday_classification', value: 'TINYINT UNSIGNED'},
        { column: 'birthdate', value: 'DATE DEFAULT NULL'},
        { column: 'approximate_age_year', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'approximate_age_month', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'civil_status_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'nationality_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'religion_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'child_type', value: 'TINYINT DEFAULT NULL'},
        { column: 'is_internally_displaced', value: 'BOOLEAN DEFAULT NULL'},
        { column: 'disability_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'educational_attainment_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'employment_status_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'company_name', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'company_contact_number', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'company_address', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'hospital_number', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'hospital_area_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'is_inpatient', value: 'BOOLEAN DEFAULT NULL'},
        { column: 'is_no_guardian', value: 'BOOLEAN DEFAULT FALSE'},
        { column: 'first_name', value: 'VARCHAR(255) NOT NULL'},
        { column: 'middle_name', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'last_name', value: 'VARCHAR(255) NOT NULL'},
        { column: 'office_id', value: 'INTEGER UNSIGNED'},
        { column: 'other_religion', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'encoded_by', value: 'INTEGER UNSIGNED'},
        { column: 'indigeneous_people_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'sex_at_birth', value: 'TINYINT UNSIGNED'},
        { column: 'assigned_mss', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'endorsed_to_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'pwd', value: 'TINYINT UNSIGNED DEFAULT NULL'},
        { column: 'pwd_specify', value: 'TEXT DEFAULT NULL'},
        { column: 'other_indigeneous_people', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'other_nationality', value: 'VARCHAR(255) DEFAULT NULL'},
        ...defaultMigration
    ]
}