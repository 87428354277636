<template>
    <user-layout>
        <div class="w-full py-6 mx-auto">
            <div class="flex items-center justify-between">
                <section-title
                    title="Office"
                    description="View the relevant in the system."
                />
                <div class="flex items-center justify-end space-x-3">
                    <export-button 
                        v-if="hasPermission('can-view-department')"
                        click="exportData()"
                    />
                    <a 
                        v-if="hasPermission('can-create-department')"
                        href="/office/create"
                    >
                        <button-component
                        >
                            <PlusIcon class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon"/>
                            <span>Create</span>
                        </button-component>
                    </a>
                </div>
            </div>
            <div class="mt-6 border rounded-lg border-neutral-100">
                <div class="tabs flex items-center justify-between pl-6 py-6 pr-3.5 border-b border-neutral-100">
                    <div class="flex items-center space-x-4">
                        <tab-component :tabs="tabs" @update:tab="(value: string) => updateTab(value)" />
                    </div>
                </div>

                <div v-if="filters.tab != 'activity_logs'">
                    
                    <data-table 
                        :headers="headers" 
                        :no-action="false"
                        :count="items.total"
                        :hasSearch="true"
                        searchPlaceholder="Search office"
                        tableClass="header-frm-table"
                        dataTableClass="border-0"
                    >
                        <template #topContent>
                            <div>
                                <button type="button" class="flex items-center px-3 py-2 space-x-1 transition border border-solid rounded border-neutral-100 hover:bg-primary-100 w-max"
                                @click="showFilter = true">
                                    <span class="text-xs font-medium text-neutral-600">Showing:</span>
                                    <span class="text-xs font-semibold">All Records</span>
                                </button>
                                <FilterPanel
                                    :show="showFilter"
                                    @close="showFilter = false"
                                    @apply:reset="applyReset()"
                                    @apply:filters="applyFilters">
                                >
                                    <template #fields>
                                        <div class="space-y-5">
                                            <date-picker 
                                                id="date_created"
                                                name="date_created"
                                                label="Date Created"
                                                :range=true
                                            />
                                            <select-options 
                                                :options="regionOptions" 
                                                label="Region" 
                                                id="region"
                                                name="region" 
                                                placeholder="Select.." 
                                                v-model="filters.filterRegion"
                                            />
                                            <select-options 
                                                :options="provinceOptions" 
                                                label="Province" 
                                                id="province" 
                                                name="province" 
                                                placeholder="Select.." 
                                                v-model="filters.filterProvince" 
                                            />
                                            <select-options 
                                                :options="cityOptions" 
                                                label="City" 
                                                id="city"       
                                                name="city" 
                                                placeholder="Select.." 
                                                v-model="filters.filterCity"    
                                            />
                                            <select-options 
                                                :options="barangayOptions" 
                                                label="Barangay" 
                                                id="barangay"       
                                                name="barangay" 
                                                placeholder="Select.." 
                                                v-model="filters.filterBarangay"    
                                            />
                                        </div>

                                    </template>
                                </FilterPanel>
                            </div>
                        </template>
                        <template v-slot:body>
                            <template 
                                v-for="item in items.data" :key="item"
                            >
                                <tr>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.prefix }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item?.name }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900" v-if="item.assigned_personnels">
                                        <div class="max-w-[200px] flex">
                                            <div v-for="personnel in item.assigned_personnels[0]" :key="personnel">
                                                <img class="w-6 h-6 bg-[#FEC52E] rounded-full object-cover text-center m-0.5 inline-block" :src="personnel" />
                                            </div>
                                                
                                            <div class="w-6 h-6 bg-[#FEC52E] rounded-full object-cover text-center m-0.5 inline-block">
                                                <span class="text-white text-[0.625rem] font-bold leading-6">
                                                    {{item.assigned_personnels[1] || 0}}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td v-else>
                                        -
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.created_at_formatted }}
                                    </td>
                                    <td 
                                        class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap"
                                    >
                                        <div 
                                            class="flex items-center justify-center mx-auto w-max"
                                        >
                                            <template v-if="!filters.tab">
                                                <view-button 
                                                    v-if="hasPermission('can-view-admins')"
                                                    @click="router.replace({ path: '/personnel/index', query: { office: item.id } })" 
                                                    class="shrink-0"
                                                />
                                                <!-- v-if="hasPermission('can-update-department')" -->
                                                <edit-button                                                     
                                                    @click="router.replace(`/office/edit/${item.id}`)"
                                                    class="shrink-0"
                                                />
                                                <delete-button 
                                                    v-if="hasPermission('can-archive-department')"
                                                    @click="showDeleteModal(item)" 
                                                    class="shrink-0"
                                                />
                                            </template>
            
                                            <template v-if="filters.tab == 'archived'">
                                                <restore-button 
                                                    v-if="hasPermission('can-archive-department')"
                                                    @click="showRestoreModal(item)" 
                                                    class="shrink-0"
                                                />
                                            </template>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>

                    </data-table>
                </div>
                <ActivityLogsTable
                    v-if="filters.tab == 'activity_logs'"
                    :items="items"
                />

                <div class="w-full px-6 border-t py-7">
                    <simple-pagination 
                        :numRows="filters.rows" 
                        :currentPage="items.current_page" 
                        :last-page="items.last_page" 
                        @update="(value: any) => paginate(value)"
                    />
                </div>
            </div>
        </div>

        <!-- DELETE MODAL -->
        <confirmation-modal :typeTwo="true" :show="showConfirmation" @cancel="showConfirmation = false"
            @confirm="deleteRecord()" cancel-text="Cancel" action-text="Confirm" title="Delete Record"
            content="Are you sure you wish to delete this Office?" />
        <success-modal :show="showSuccess" @confirm="closeDeleteModal()" action-text="Close"
            title="Record Deleted!" content="Selected office has been successfully deleted!" />
        <!-- RESTORE MODAL -->
        <confirmation-modal :typeTwo="true" :show="showRestoreConfirmation"
            @cancel="showRestoreConfirmation = false" @confirm="restoreRecord()" cancel-text="Cancel"
            action-text="Confirm" title="Restore Record"
            content="Are you sure you wish to restore this office?" />
        <success-modal :show="showRestoreSuccess" @confirm="closeRestoreModal()" action-text="Close"
            title="Record Restored!" content="Selected office has been successfully office!" />
        
        <success-modal :show="showExportSuccess" @confirm="closeExport()" action-text="Close"
            title="Personnels Exported!" content="Please check your email for the exported offices" />

         <page-loader :show="loading"/>
    </user-layout>
</template>

<script setup lang="ts">
import { onIonViewWillEnter } from "@ionic/vue";
import { ref, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import UserLayout from '@/layouts/UserLayout.vue';
import SectionTitle from '@/components/partials/SectionTitle.vue';
import ExportButton from '@/components/buttons/ExportButton.vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import EditButton from "@/components/buttons/EditButton.vue";
import ViewButton from '@/components/buttons/ViewButton.vue';
import RestoreButton from "@/components/buttons/RestoreButton.vue";
import DeleteButton from '@/components/buttons/DeleteButton.vue';
import DataTable from '@/components/partials/DataTable.vue';
import FilterPanel from '@/components/partials/FilterPanel.vue';
// import MinifyPagination from '@/components/partials/MinifyPagination.vue';
import SimplePagination from "@/components/partials/SimplePagination.vue";
import TabComponent from '@/components/partials/TabComponent.vue';
import DatePicker from "@/components/inputs/DatePicker.vue";
import { PlusIcon } from '@heroicons/vue/24/outline';
import SelectOptions from "@/components/inputs/SelectOptions.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";

import { hasPermission } from "@/classes/AuthService";

import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";

import Offices from "@/classes/Offices";
import { SelectInterface } from "@/interfaces/pages/offices/OfficeInterface"

import assignValues from "@/helpers/assignValues"
import PageLoader from "@/components/loaders/PageLoader.vue"

import ActivityLogsTable from "@/views/activity-logs/ActivityLogsTable.vue"

import { filters, items } from "@/interfaces/pages/FilterPageInterface";

const router = useRouter();
const route = useRoute();
const showFilter = ref<boolean>(false);
const loading = ref<boolean>(false);

const regionOptions = ref<SelectInterface[]>([]);
const provinceOptions = ref<SelectInterface[]>([]);
const cityOptions = ref<SelectInterface[]>([]);
const barangayOptions = ref<SelectInterface[]>([]);


const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const showRestoreConfirmation = ref<boolean>(false);
const showRestoreSuccess = ref<boolean>(false);
const showExportSuccess = ref<boolean>(false);

const selectedId = ref<number|null>(null);
const tabs = ref();

const headers: { text: string, haveSort?: boolean }[] = [
    { text: 'Prefix' },
    { text: 'Office Name', haveSort: true },
    { text: 'Office Personnel' },
    { text: 'Date Created' },
];

/**
 * METHODS
 */
/**
 * METHODS
 */
const getData = (): void => {
    loading.value = true
    Offices.index(pickBy(filters.value))
        .then(({ data }) => {
            items.value = data.items;
            filters.value = assignValues(filters.value, data);
            
            regionOptions.value = data.region;
            provinceOptions.value = data.province;
            cityOptions.value = data.city;
            barangayOptions.value = data.barangay;

            loading.value = false;

            if (hasPermission('can-view-department')) {
                tabs.value = [
                    {
                        name: "All",
                        count: filters.value.allItemsCount,
                        tab: null,
                        allowed: true,
                    },
                    {
                        name: "Archived",
                        count: 0,
                        tab: "archived",
                        allowed: true,
                    },
                    {
                        name: "Action Logs",
                        count: filters.value.activityLogsCount,
                        tab: "activity_logs",
                        allowed: true,
                    }, 
                ]
            } else {
                tabs.value = [
                    {
                        name: "All",
                        count: filters.value.allItemsCount,
                        tab: null,
                        allowed: true,
                    },
                ]
            }
        })
        .catch((error: any) => {
            console.log(error);
        });
};

const applyFilters = (search: any = null) => {
    showFilter.value = false;
    filters.value.query = search;
    getData();
};

const applyReset = () => {
    filters.value.filterDate = null;
    filters.value.filterRegion = null;
    filters.value.filterProvince = null;
    filters.value.filterCity = null;
    filters.value.filterBarangay = null;

    showFilter.value = false;
    getData();

};

const paginate = ({ page, rows }): void => {
    filters.value = Object.assign(filters.value, {
        page, rows
    })
    
    getData();
};

const updateTab = (currentTab: string) => {
    filters.value.tab = currentTab;
    filters.value.page = 1;
    applyFilters();
};

/**
 * MODALS
 */
const showDeleteModal = (item: any): void => {
    showConfirmation.value = true;
    selectedId.value = item.id;
};

const closeDeleteModal = (): void => {
    showSuccess.value = false;
};

const deleteRecord = (): void => {
    Offices.delete(selectedId.value)
        .then(() => {
            showSuccess.value = true;
            showConfirmation.value = false;
            applyFilters();
        })
        .catch((error) => console.log(error));
};

const showRestoreModal = (item: any): void => {
    showRestoreConfirmation.value = true;
    selectedId.value = item.id;
};

const closeRestoreModal = (): void => {
    showRestoreSuccess.value = false;
};

const restoreRecord = (): void => {
    Offices.restore(selectedId.value)
        .then(() => {
            showRestoreSuccess.value = true;
            showRestoreConfirmation.value = false;
            applyFilters();
        })
        .catch((error) => console.log(error));
};

const exportData = (): void => {
    loading.value = true
    Offices.export(filters.value)
        .then(() => {
            loading.value = false
            showExportSuccess.value = true;
        })
        .catch((error) => console.log(error));
};


const closeExport = (): void => {
    showExportSuccess.value = false;
};

/**
 * WATCHERS
 */
watch(
    () => filters.value.query,
    throttle(() => {
        getData();
    }, 1000)
);


watch(
    () => filters.value.filterRegion,
    throttle((value) => {
        filters.value.filterProvince = null
        filters.value.filterCity = null
        filters.value.filterBarangay = null
        getData();
    }, 100)
);
watch(
    () => filters.value.filterProvince,
    throttle((value) => {
        if (value) {
            filters.value.filterCity = null
            getData();
        }
    }, 100)
);
watch(
    () => filters.value.filterCity,
    throttle((value) => {
        if (value) {
            filters.value.filterBarangay = null
            getData();
        }
    }, 100)
);
watch(
    () => filters.value.filterBarangay,
    throttle((value) => {
        if (value) {
            getData();
        }
    }, 100)
);

// onMounted(() => {
//     getData();
// });

onIonViewWillEnter(() => getData());

</script>