import { DataDictionary } from "./Interface/DataDictionaryInterface";
import DataDictionaryOfflineProcessor from "./processor/DataDictionaryOfflineProcessor";

class OfflineService implements DataDictionary {
    async index(payload: object): Promise<any> {
        const item = await DataDictionaryOfflineProcessor.index(payload);
        return {
            data: {
                items: item,
            }
        }
    } 

    async view(dataDictionary: number): Promise<any> {
        const item = await DataDictionaryOfflineProcessor.view(dataDictionary);
        return {
            data: {
                item: item,
            }
        }
    }
}

export default new OfflineService();