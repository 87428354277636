<template>
    <user-layout hasBack backTitle="System Logs" backLink="/reports">

        <template #lowerRight>
            <export-button @click="exporting()" />
        </template>

        <div class="-mx-6 w-[calc(100%+32px)] pb-6" v-if="!loading">
            <div class="mx-6 mt-8 border rounded-lg border-neutral-100">
                <div class="">
                    <data-table 
                        :hasSearch="true"
                        searchPlaceholder="Search"
                        :headers="headers" 
                        :no-action="true"
                        :count="items?.data?.length"
                        tableClass="header-frm-table"
                        dataTableClass="border-0"
                        :search="filters.query"
                        @update:searchText="(value:string) => (filters.query = value)"
                    >
                        <template #topContent>
                            <div class="flex space-x-2">
                                <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100"
                                @click="showFilter = true">
                                    <FunnelIcon class="inline-block w-5 h-5 text-neutral-900" />
                                    <p class="text-sm font-semibold text-neutral-900">Filter</p>
                                </div>
                                
                                <Popover class="relative">
                                    <PopoverButton>
                                        <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100">
                                            <ArrowsUpDownIcon class="inline-block w-5 h-5 text-neutral-900" />
                                            <p class="text-sm font-semibold text-neutral-900">Sort</p>
                                        </div>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-10 mt-3 w-fit">
                                        <div class="p-6 overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black/5 sort">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="sortOptions"
                                                    v-model="filters.column"
                                                    name="sampleoptions"
                                                    @update:modelValue="getData()"
                                                />
                                            </div>
                                            <hr class="my-4">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="orderOptions"
                                                    v-model="filters.order"
                                                    name="defaultoptions"
                                                    @update:modelValue="getData()"
                                                />
                                            </div>
                                        </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>

                                <FilterPanel
                                    :show="showFilter"
                                    @close="showFilter = false"
                                    @apply:reset="applyReset()"
                                    @apply:filters="applyFilters()"
                                    customClass="!overflow-visible overflow-x-visible"
                                    >
                                    <template #fields>
                                        <div class="space-y-5">
                                            <date-picker
                                                id="created_at"
                                                name="created_at"
                                                label="Date Created"
                                                v-model="filters.created_at"
                                                range
                                                multiCalendars
                                                class="filter-range"
                                            />
                                        </div>
                                        <div class="space-y-5">
                                            <SelectOptions
                                                name="office"
                                                id="office"
                                                label="Office"
                                                :options="offices"
                                                v-model="filters.office"
                                            />
                                        </div>
                                    </template>
                                </FilterPanel>
                            </div>
                        </template>
                        <template #body>
                            <template 
                                v-for="item in items.data" 
                                :key="item.id"
                            >
                                <tr>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ DateFormatter.customDateFormat(item?.created_at, "MMM dd, yyy - hh:mm a") }} 
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item?.causer?.office?.name }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespac e-nowrap">
                                        {{ item?.causer_name }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item?.subject_type }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item?.event }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item?.description }}
                                    </td>
                                    <!-- <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        <view-button @click="()=> {showModal = true; systemLogDetails = item.properties}" />
                                    </td> -->
                                </tr>
                            </template>
                        </template>
                    </data-table>
                    <div class="w-full px-6 border-t py-7">
						<simple-pagination 
							:numRows="filters.rows ?? 10" 
                            :currentPage="items?.current_page" 
                            :last-page="items?.last_page"
                            @update="(value: any) => paginate(value)"
						/>
					</div>
                </div>
            </div>
        </div>

        <loading-modal
            :show="loading"
            noBtn
            title="Please wait.."
            content="System is currently loading the report based on the selected filter."
        />
        <system-log-modal
            :show="showModal"
            title="System Log Details"
            @close="showModal = false"
            cancelText="Close"
            :content="systemLogDetails"
        />
    </user-layout>
</template>
<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import DataTable from '@/components/partials/DataTable.vue';
import ExportButton from '@/components/buttons/ExportButton.vue';
import SimplePagination from "@/components/partials/SimplePagination.vue";
import ViewButton from "@/components/buttons/ViewButton.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import LoadingModal from '@/components/modals/LoadingModal.vue';
import { AdjustmentsVerticalIcon, UserGroupIcon, FunnelIcon, ArrowsUpDownIcon } from "@heroicons/vue/24/outline";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import pickBy from "lodash/pickBy"; 
import ReportService from '@/classes/Reports/ReportService';
import { items } from '@/interfaces/pages/FilterPageInterface';
import { format } from 'date-fns'; 
import SystemLogModal from '@/components/modals/SystemLogModal.vue';
import DateFormatter from '@/helpers/DateFormatter';
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import debounce from "lodash/debounce";
import SelectOptions from "@/components/inputs/SelectOptions.vue";

const showFilter = ref<boolean>(false);
const showSync = ref<boolean>(false);
const loading = ref<boolean>(false);
const showModal = ref<boolean>(false);
const systemLogDetails = ref<string>("");
const sortOptions = [
    { id: '1', label: 'Created', value: 'created_at'}, 
]
const orderOptions = [
    { id: '3', label: 'Ascending', value: 'asc'},
    { id: '4', label: 'Descending', value: 'desc'},
]
const offices = ref([]);

const headers: { text: string }[] = [
    { text: 'Date & Time' },
    { text: 'Office' },
    { text: 'Personnel' },
    { text: 'Module' },
    { text: 'Action' },
    { text: 'Record' },
    // { text: 'Details' },
];

const filters = ref({
    rows: 10,   
    query: null,    
    created_at: null,
    order:'desc',
    column: 'created_at',
    office: null,
});

const applyFilters = (search: any = null) => {
	showFilter.value = false; 
    getData();
};

const applyReset = () => {
    showFilter.value = false;
    filters.value.created_at = null;
    getData();
};

const getData =  (): void =>{
    loading.value = true; 
    ReportService.index('system-log',filters.value)
        .then(({data})=>{ 
            items.value = data.items;  
            offices.value = data.offices;
        })
        .catch((error: any)=>{
            console.log(error)
        })
        .finally(() => loading.value = false)
} 

const paginate = ({ page, rows }): void => {
    filters.value = Object.assign(filters.value, {
        page, rows
    })
    getData();
};

const exporting = (): void => {
    loading.value = true;
    ReportService.export('system-log',omitBy(filters.value, isNil))
        .then(({data})=>{
             ReportService.downloadExport("system-log-export.csv",data);  
        })
        .finally(() => loading.value = false)

}
 
watch(
    () => filters.value.query,
    debounce(() => {
        getData();
    }, 1000)
);

onMounted(()=> {
    getData();
})

</script>