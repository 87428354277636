<template>
	<user-layout hasBack backLink="/patient/index" backTitle="View Patient" mainCustomClass="resetView !min-h-0 !mx-0">
		<div class="xl:grid xl:grid-cols-[384px_auto_370px] flex lg:flex-wrap lg:flex-row flex-col items-start xl:h-[calc(100vh-154px)] overflow-hidden">
			<div class="xl:sticky lg:pl-6 lg:pr-0 pr-6 xl:top-6 xl:mt-0 mt-6 xl:my-7 shrink-0 lg:w-auto w-full">
				<div class="relative grid grid-cols-2 gap-3 p-6 border border-gray-200 rounded-2xl">
					<div class="col-span-2">
						<p class="font-semibold">Patient Profile</p>
					</div>
					<div class="col-span-1">
						<p class="mb-1 text-sm font-bold">{{ patientInfo.first_name }}</p>
						<p class="text-xs font-medium text-neutral-500">Firstname</p>	
					</div>
					<div class="col-span-1">
						<p class="mb-1 text-sm font-bold">{{ patientInfo.last_name }}</p>
						<p class="text-xs font-medium text-neutral-500">Lastname</p>
					</div>
					<div class="col-span-1">
						<p class="mb-1 text-sm font-bold">{{ patientInfo.sex_at_birth_meta?.description }}</p>
						<p class="text-xs font-medium text-neutral-500">Sex Assigned at Birth</p>
					</div>
					<div class="col-span-1">
						<p class="mb-1 text-sm font-bold">{{ patientInfo.gender_meta?.description }}</p>
						<p class="text-xs font-medium text-neutral-500">Gender</p>
					</div>
					<div class="col-span-1">
						<p class="mb-1 text-sm font-bold">{{ patientInfo.registered_at_formatted }}</p>
						<p class="text-xs font-medium text-neutral-500">Date Registered Date</p>
					</div>
					<div v-if="hasPermission('can-update-patient-information')" class="col-span-2">
						<a
							@click="
								router.replace(
									`/patient/profile/${patientId}/edit`
								)
							">
							<button-component customClass="quaternary w-full" size="large">
								<span>View Profile</span>
							</button-component>
						</a>
					</div>
				</div>
			</div>
			<div
				v-if="hasPermission('can-view-patients-incident-history')"
				class="flex flex-col h-full lg:px-6 lg:pt-6 lg:mt-0 mt-6 space-y-4 overflow-y-auto xl:w-auto lg:w-[calc(100%-334px)] w-full">
				<div class="flex md:items-center md:justify-between md:flex-row flex-col md:space-y-0 space-y-3">
					<div>
						<p class="block font-semibold">Case History</p>
						<p class="text-xs font-normal text-gray-700">Please select the Case Number to access the patient's details.</p>
					</div>
					<a
						v-if="hasPermission('can-create-patient-incident')"
						@click="
							router.replace(`/patient/${patientId}/case/create`)
						">
						<button-component size="large">
							<PlusIcon class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
							<span>Add New Case</span>
						</button-component>
					</a>
				</div>
				<div class="flex flex-col space-y-3 md:items-center md:justify-between md:flex-row md:space-y-0">
					<div class="relative md:min-w-[265px] w-auto">
						<button
							type="submit"
							class="border-0 absolute top-0 left-0 z-[1] px-3 py-[14px] hover:bg-primary-600 rounded-l group transition"
							title="Search cases">
							<!-- <img
								src="/assets/icon/ic-search-2.svg"
								alt="search_icon"
								class="w-5 h-5 object-contain grayscale brightness-[0.3] group-hover:invert group-hover:brightness-0 transition" /> -->
							<search-two-icon class="w-5 h-5 object-contain grayscale brightness-[0.3] group-hover:invert group-hover:brightness-0 transition" />
						</button>
						<text-input
							type="text"
							placeholder="Search Cases"
							name="search_cases"
							id="search_cases"
							customClass="!pl-12 frm-input search"
							v-model="filterSearch" />
					</div>
					<div class="self-end">
						<button
							type="button"
							class="border-neutral-100 hover:bg-primary-100 transition border-solid border rounded px-3 py-[15px] flex space-x-1 items-center w-max"
							@click="showFilter = true">
							<span class="text-xs font-medium text-neutral-600">Showing:</span>
							<span class="text-xs font-semibold">All Records ({{ patientCases.total }})</span>
						</button>
						<FilterPanel
							:show="showFilter"
							@close="showFilter = false"
							@apply:reset="applyReset()"
							@apply:filters="applyFilters()">
							<template #fields>
								<div class="space-y-5">
									<date-picker
										id="date_initiated"
										name="date_initiated"
										label="Date Initiated"
										:range="true"
										v-model="filterInitiatedAt" />
									<date-picker
										id="date_created"
										name="date_created"
										label="Date Created"
										v-model="filterDate" />
								</div>
							</template>
						</FilterPanel>
					</div>
				</div>

				<template v-for="item in patientCases.data" :key="item">
					<case-card
						:routeLink="`/patient/${patientId}/cases/${item.id}/edit`"
						:date="item.initiated_at_formatted"
						:caseNumber="item.case_number"
						:primaryDiagnosis="item?.diagnoses?.primary_diagnoses?.name"
						:comorbidList="item.comorbidities?.map((item) => item.name)"
						:perpetratorList="item.perpetrators?.map((item) => item.name)" />
				</template>
				<div class="w-full px-6 border-t py-7">
					<!-- Temporary -->
					<minify-pagination
						:numRows="numRows"
						@update:rows="(value: any) => changeRows(value)"
						@update:page="(value: number) => paginate(value)"
						:current-page="page"
						:last-page="lastPage"></minify-pagination>
				</div>
			</div>

			<div
				v-if="hasPermission('can-view-intervations')"
				class="xl:w-[370px] xl:mt-0 mt-6 w-full h-full overflow-y-auto p-6 bg-gray-100 ">
				<p class="mb-4 font-bold">Latest Interventions</p>
				<div class="grid grid-cols-1 gap-4 md:grid-cols-2">
					<template v-for="item in patientInterventions.data" :key="item">
						<intervention-card
							typeTwo
							:item="item"
							:status="item.status"
							:interventionType="item.intervention_type_name"
							:intervention="item.type"
							:caseNumber="item.case_number"
							:doneBy="item.done_by"
							:doneOn="item.done_on" 
							class="col-span-1 xl:col-span-2"
						/>
					</template>
				</div>

				<button-component 
					v-if="!loadingIntervention && patientInterventions.last_page != patientInterventions.page" 
					class="w-auto mx-auto my-4 xl:w-full xl:mx-0" 
					size="large" 
					@click="loadMoreIntervention"
				>
					<span>Load more</span>
				</button-component>

				<p class="mb-4 text-xs text-center text-secondary-600" v-if="loadingIntervention">
					Loading more...
				</p>

			</div>
		</div>
		<page-loader :show="loading" />
	</user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted, watch, computed } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import CaseCard from "@/components/cards/CaseCard.vue";
import InterventionCard from "@/components/cards/InterventionCard.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import { useRouter, useRoute } from "vue-router";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import MinifyPagination from "@/components/partials/MinifyPagination.vue";
import { ViewPatientInterface } from "@/interfaces/PatientInterface";
import { CardCaseInterface, CardCaseIntervetionInterface, CardCaseFilterInterface } from "@/interfaces/Cases/Case";
import { hasPermission } from "@/classes/AuthService";
import PageLoader from "@/components/loaders/PageLoader.vue";
import { useNetworkStore } from "@/store/network";
import { onIonViewWillEnter } from "@ionic/vue";
import SearchTwoIcon from "@/components/icons/searchTwoIcon.vue";
import InterventionService from "@/classes/Interventions/InterventionService";
import CaseHistoryOfflineService from "@/classes/Cases/CaseHistory/Offline/OfflineService";
import CaseHistoryService from "@/classes/Cases/CaseHistory/CaseHistoryService";
import OnlineSynching from "@/classes/Synching/OnlineSynching";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const router = useRouter();
const route = useRoute();
const showFilter = ref<boolean>(false);
const loadingIntervention = ref<boolean>(false);
const patientInfo = ref<ViewPatientInterface>({});
const patientCases = ref<CardCaseInterface[]>([]);
const filterSearch = ref<string | null>(route.query.query);
const filterDate = ref<string | null>(route.query.date);
const filterInitiatedAt = ref<string | null>(route.query.initiated_at);
const page = ref<string | null>(route.query.page);
const lastPage = ref<string | null>(null);
const rows = ref<number | null>(route.query.rows || 5);
const numRows = computed<string | null>(() => (rows.value ? rows.value + " Rows" : "All"));
const patientId = ref<number>(route.params.patient);
const loading = ref<boolean>(false);
const onlineSync = new OnlineSynching();

const filters = computed<CardCaseFilterInterface>(() => {
	return {
		query: filterSearch.value,
		page: page.value,
		rows: rows.value,
		initiated_at: filterInitiatedAt.value,
		date: filterDate.value,
		interventionPage: 1
	};
});

const patientInterventions = ref < {
	data: CardCaseIntervetionInterface[]
	page: number,
	last_page: number,
} > ({
	data: [],
	page: filters.value.interventionPage,
	last_page: 0,
});

const getData = async (search: any = null, setLoading = true): void => {
	loading.value = setLoading;
	if (search) {
		filterSearch.value = search;
	}
    
    // Call this service when network is online or offline
    const response = await CaseHistoryService.index(
        patientId.value,
        pickBy({ ...filters.value }),
        isNetworkAvailable.value
    );

    if (response?.response?.status === 404) {
        loading.value = false;
        return;
    }
    
    if (response?.status === 200 || response?.data != undefined) {
        const data = response.data;

		patientInfo.value = data.item;
		patientCases.value = data.cases;
		page.value = data.cases.current_page;
		lastPage.value = data.cases.last_page;

        // If network is available sync data to offline
        if (isNetworkAvailable.value) {
            await CaseHistoryOfflineService.syncToOffline(data);
        }

		await getInterventions();
        loading.value = false;
    } else {
        const error = response;
        loading.value = false;
        console.log(error);
    }
};

const reloadPage = async () => {
	return await router.push({
		path: `/patient/${patientId.value}/view`,
		query: pickBy(filters.value),
	});
};

const applyFilters = async (search: any = null, setLoading = true) => {
    await reloadPage();
    await getData(search, setLoading);
    showFilter.value = false;
};

const applyReset = async () => {
	filterDate.value = "";
	filterInitiatedAt.value = "";
    await reloadPage();
    await getData();
    showFilter.value = false;
};


const loadMoreIntervention = async () => {
	filters.value.interventionPage += 1;
	loadingIntervention.value = true;

	await getInterventions();
};


const getInterventions = async ():Promise<void> => {
	loading.value = true;
    await CaseHistoryService.latestInterventions(patientId.value, null, { page: filters.value.interventionPage }, isNetworkAvailable.value)
		.then(({data}) => {
			setInterventions(data.items);
		})
		.catch(errors => console.error(errors))
		.finally( () =>loading.value = false)
}

const setInterventions = (latestInterventions:any) => {
	loadingIntervention.value = false;

	if (patientInterventions.value.data.length == 0) {
		patientInterventions.value.data = latestInterventions.data;
	} else if (latestInterventions.to !== latestInterventions.total) {
		patientInterventions.value.data.push(...latestInterventions.data);
	}

	if(patientInterventions.value.data.length > 0 && isNetworkAvailable.value) {
		InterventionService.storeOffline(latestInterventions.data)
	}

	patientInterventions.value.last_page =  latestInterventions.last_page
	patientInterventions.value.page =  latestInterventions.current_page

}

const changeRows = async (row: any): void => {
	rows.value = row;
	await applyFilters();
};

const paginate = async (data: number): void => {
	page.value = data;
	await applyFilters();
};

/**
 * WATCHERS
 */
watch(
	filterSearch,
	throttle(async (val: string) => {
		await applyFilters(val, false);
	}, 1000)
);

watch(
    () => isNetworkAvailable.value,
    async () => {
        loading.value = false;
        await getData();
        await onlineSync.checkDrafts();
    }
)


onIonViewWillEnter(async () => {
    await getData();
    await onlineSync.checkDrafts();
}); 
</script>
@/classes/Databases/SqlLite@/classes/Databases/SqlLite
