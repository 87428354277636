<template>
    <div class="flex items-start 2xl:items-center 2xl:space-x-3">
        <template v-if="isNetworkAvailable">
            <a
                v-if="status"
                @click="gotoDashboard()"
                class="2xl:block flex whitespace-nowrap justify-between w-full 2xl:px-3 2xl:py-2 py-[14px] text-white 2xl:text-xs text-sm 2xl:rounded cursor-pointer"
                :class="currentRouteName == 'DashboardIndex' ? '2xl:bg-[#529ffc] font-semibold 2xl:border-b-0 border-b border-white' : '2xl:bg-primary-600 2xl:font-semibold opacity-70'">
                Dashboard
            </a>
        </template>
        <a
            v-if="hasPermission('can-search-patients')"
            @click="router.push('/patient/search')"
            class="2xl:block flex whitespace-nowrap justify-between w-full 2xl:px-3 2xl:py-2 py-[14px] text-white 2xl:text-xs text-sm 2xl:rounded cursor-pointer"
            :class="
                currentRouteName == 'PatientSearchForm' ||
                currentRouteName == 'PatientIndex' ||
                currentRouteName == 'PatientEdit' ||
                currentRouteName == 'PatientView' ||
                currentRouteName == 'PatientCreate' ||
                currentRouteName == 'PatientProfileEdit' ||
                currentRouteName == 'PatientGuardianCreate' ||
                currentRouteName == 'PatientGuardianEdit' ||
                currentRouteName == 'PatientCompanionCreate' ||
                currentRouteName == 'PatientCompanionEdit' ||
                currentRouteName == 'PatientAddressCreate' ||
                currentRouteName == 'PatientAddressEdit' ||
                currentRouteName == 'PatientPerpetratorSearch' ||
                currentRouteName == 'PatientPerpetratorIndex' ||
                currentRouteName == 'PatientPerpetratorCreate' ||
                currentRouteName == 'PatientIntakeCreate' ||
                currentRouteName == 'PatientIntakeView' ||
                currentRouteName == 'PatientSafetyAssessmentCreate' ||
                currentRouteName == 'PatientSafetyAssessmentView'
                    ? '2xl:bg-[#529ffc] font-semibold 2xl:border-b-0 border-b border-white' 
                    : '2xl:bg-primary-600 2xl:font-semibold opacity-70'
            ">
            Patient
        </a>
        <a
            v-if="hasPermission('can-search-patients-intervations')"
            @click="router.push('/intervention/search')"
            class="2xl:block flex whitespace-nowrap justify-between w-full 2xl:px-3 2xl:py-2 py-[14px] text-white 2xl:text-xs text-sm 2xl:rounded cursor-pointer"
            :class="
                currentRouteName == 'InterventionSearchForm' ||
                currentRouteName == 'InterventionIndex' ||
                currentRouteName == 'InterventionView' ||
                currentRouteName == 'InterventionSocialWorkCreate' ||
                currentRouteName == 'InterventionMedicalCreate' ||
                currentRouteName == 'InterventionMentalHealthCreate' ||
                currentRouteName == 'InterventionDevelopmentalPediaCreate' ||
                currentRouteName == 'InterventionLegalCreate' ||
                currentRouteName == 'InterventionTeleconsultationCreate' ||
                currentRouteName == 'InterventionTelepsychiatryCreate' ||
                currentRouteName == 'InterventionAdminCreate'
                    ? '2xl:bg-[#529ffc] font-semibold 2xl:border-b-0 border-b border-white' 
                    : '2xl:bg-primary-600 2xl:font-semibold opacity-70'
            ">
            Intervention
        </a>
        <a
            v-if="hasPermission('can-search-perpetrator')"
            @click="router.push('/perpetrator/index')"
            class="2xl:block flex whitespace-nowrap justify-between w-full 2xl:px-3 2xl:py-2 py-[14px] text-white 2xl:text-xs text-sm 2xl:rounded cursor-pointer"
            :class="
                currentRouteName == 'PerpetratorIndex' ||
                currentRouteName == 'PerpetratorEdit' ||
                currentRouteName == 'PerpetratorView'
                    ? '2xl:bg-[#529ffc] font-semibold 2xl:border-b-0 border-b border-white' 
                    : '2xl:bg-primary-600 2xl:font-semibold opacity-70'
            ">
            Perpetrator
        </a>
        <template v-if="isNetworkAvailable">
            <a
                v-if="hasPermission('can-view-admins') && status"
                @click="router.push('/personnel/index')"
                class="2xl:block md:flex hidden whitespace-nowrap justify-between w-full 2xl:px-3 2xl:py-2 py-[14px] text-white 2xl:text-xs text-sm 2xl:rounded cursor-pointer"
                :class="
                    currentRouteName == 'PersonnelIndex' ||
                    currentRouteName == 'PersonnelView' ||
                    currentRouteName == 'PersonnelEdit' ||
                    currentRouteName == 'PersonnelCreate'
                        ? '2xl:bg-[#529ffc] font-semibold 2xl:border-b-0 border-b border-white' 
                        : '2xl:bg-primary-600 2xl:font-semibold opacity-70'
                ">
                Personnel
            </a>
            <!-- hasPermission('can-view-department') -->
            <a
                v-if="status"
                @click="router.push('/office/index')"
                class="2xl:block md:flex hidden whitespace-nowrap justify-between w-full 2xl:px-3 2xl:py-2 py-[14px] text-white 2xl:text-xs text-sm 2xl:rounded cursor-pointer"
                :class="
                    currentRouteName == 'OfficeIndex' ||
                    currentRouteName == 'OfficeView' ||
                    currentRouteName == 'OfficeCreate'
                        ? '2xl:bg-[#529ffc] font-semibold 2xl:border-b-0 border-b border-white' 
                        : '2xl:bg-primary-600 2xl:font-semibold opacity-70'
                ">
                Office
            </a>
        </template>
        <a
            v-if="hasPermission('manage-data-dictionary')"
            @click="router.push('/data-dictionary/index')"
            class="2xl:block flex whitespace-nowrap justify-between w-full 2xl:px-3 2xl:py-2 py-[14px] text-white 2xl:text-xs text-sm 2xl:rounded cursor-pointer"
            :class="
                currentRouteName == 'DataDictionaryIndex' || currentRouteName == 'DataDictionaryView'
                    ? '2xl:bg-[#529ffc] font-semibold 2xl:border-b-0 border-b border-white' 
                    : '2xl:bg-primary-600 2xl:font-semibold opacity-70'
            ">
            Data Dictionary
        </a>        
        <template v-if="isNetworkAvailable">
            <a
                v-if="hasPermission('can-access-report')"
                @click="router.push('/reports')"
                class="2xl:block md:flex hidden whitespace-nowrap justify-between w-full 2xl:px-3 2xl:py-2 py-[14px] text-white 2xl:text-xs text-sm 2xl:rounded cursor-pointer"
                :class="currentRouteName == 'ReportsIndex' || currentRouteName == 'ReportsView' ? '2xl:bg-[#529ffc] font-semibold 2xl:border-b-0 border-b border-white' : '2xl:bg-primary-600 2xl:font-semibold opacity-70'">
                Reports
            </a>
            <a
                v-if="hasPermission('manage-primary-diagnoses') && status"
                @click="router.push('/settings/roles/index')"
                class="2xl:block md:flex hidden whitespace-nowrap justify-between w-full 2xl:px-3 2xl:py-2 py-[14px] text-white 2xl:text-xs text-sm 2xl:rounded cursor-pointer"
                :class="
                    currentRouteName == 'SettingsRolesIndex' ||
                    currentRouteName == 'SettingsRolesView' ||
                    currentRouteName == 'SettingsPrimaryDiagnosisIndex' ||
                    currentRouteName == 'SettingsPrimaryDiagnosisView' ||
                    currentRouteName == 'SettingsPrimaryDiagnosisCreate' ||
                    currentRouteName == 'SettingsFormOptionsIndex' ||
                    currentRouteName == 'SettingsFormOptionsView' ||
                    currentRouteName == 'SettingsFormOptionsCreate' ||
                    currentRouteName == 'SettingsFormOptionsEdit' ||
                    currentRouteName == 'SettingsDepartmentIndex' ||
                    currentRouteName == 'SettingsDepartmentView' ||
                    currentRouteName == 'SettingsDepartmentCreate' ||
                    currentRouteName == 'SettingsTelecpuLocationsIndex' ||
                    currentRouteName == 'SettingsTelecpuLocationsView' ||
                    currentRouteName == 'SettingsTelecpuLocationsCreate' ||
                    currentRouteName == 'SettingsTelecpuLocationsOfficeEdit' ||
                    currentRouteName == 'SettingsTelecpuLocationsOfficeCreate' ||
                    currentRouteName == 'SettingsDataDictionaryIndex' ||
                    currentRouteName == 'SettingsDataDictionaryView' ||
                    currentRouteName == 'SettingsDataDictionaryCreate' ||
                    currentRouteName == 'SettingsNotificationsIndex' ||
                    currentRouteName == 'SettingsNotificationsView'
                        ? '2xl:bg-[#529ffc] font-semibold 2xl:border-b-0 border-b border-white' 
                        : '2xl:bg-primary-600 2xl:font-semibold opacity-70'
                ">
                Settings
            </a>
            <a
                v-if="hasPermission('manage-patient-log') && status"
                @click="router.push('/patient-logs/index')"
                class="2xl:block flex whitespace-nowrap justify-between w-full 2xl:px-3 2xl:py-2 py-[14px] text-white 2xl:text-xs text-sm 2xl:rounded cursor-pointer"
                :class="currentRouteName == 'PatientLogsIndex' ? '2xl:bg-[#529ffc] font-semibold 2xl:border-b-0 border-b border-white' : '2xl:bg-primary-600 2xl:font-semibold opacity-70'">
                Patient Logs
            </a>
        </template>
    </div>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { useRouter } from 'vue-router';
import { hasPermission } from "@/classes/AuthService";
import { useNetworkStore } from "@/store/network";

import { RoleTypes } from "@/views/enums/RoleTypes"
import Storage from "@/helpers/storage";

const tokens = new Storage();

const user = JSON.parse(tokens.get("user"));

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const router = useRouter();
const currentRouteName = router.currentRoute.value.name;

defineProps({
    status: {
        default: true,
        type: Boolean
    }
})

const gotoDashboard = ():void =>{
    const wcpuRoles = [
        RoleTypes.OFFICE_ADMIN,
        RoleTypes.OFFICE_NORMAL,
        RoleTypes.NURSE,
        RoleTypes.WCPU,
        RoleTypes.OFFICE_TRAINEE,
    ]

    if(wcpuRoles.includes(user.role_id)){
        window.location.href = "/dashboard/wcpu";
    } else {
        window.location.href = "/dashboard";
    }
}

</script>