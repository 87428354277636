import CurrentLocation from "@/enums/CurrentLocation";
import Gender from "@/enums/Gender";
import FormOptions from "@/classes/Forms/FormOptions";
import PerpetratorIdentity from "@/enums/PerpetratorIdentity";
import PerpetratorProcessor from "../Processor/PerpetratorProcessor";

const options = new FormOptions();

class PerpetratorTemplateForm {
    async createTemplate() {
        const nationalities = await options.nationalities();
        const perpetratorRelationships = await options.perpetratorRelationships();
        const religions = await options.religions();
        return {
            currentLocations: CurrentLocation,
            genders: Gender,
            identities: PerpetratorIdentity,
            nationalities: nationalities,
            perpetratorRelationships: perpetratorRelationships,
            religions: religions,
        };
    }

    async createPerpetratorTemplate(patient: any) {
        const template = await this.createTemplate();
        return {
            ...template,
            patient: `${patient?.last_name}, ${patient?.first_name} ${patient?.middle_name || ''}`,
            unknownTag: `000(Offline) - Unknown Perpetrator of ${patient?.first_name} ${patient?.last_name}`,
        }
    }

    async editPerpetratorTemplate(patient: any, perpertrator_id: number, table: any = 'case_perpetrators') {
        const template = await this.createTemplate();
        const item = await PerpetratorProcessor.getLatestPerpetrator(perpertrator_id, table);
        return {
            ...template,
            patient: `${patient?.last_name}, ${patient?.first_name} ${patient?.middle_name || ''}`,
            unknownTag: `000(Offline) - Unknown Perpetrator of ${patient?.first_name} ${patient?.last_name}`,
            item: item,
        }
    }
}

export default new PerpetratorTemplateForm();