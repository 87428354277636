import FormOptions from "@/classes/Forms/FormOptions";
import DateFormatter from "@/helpers/DateFormatter";
import { OfflineAction } from "@/views/enums/OfflineAction";
import FactorsService from "./OfflineClasses/FactorsService";
import RecommendationsService from "./OfflineClasses/RecommendationsService";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";

const options  = new FormOptions();
const sqlite = new SqliteProcessor;
const syncable = new Syncable;
const table = 'case_conferences';

class OfflineService {
	async index(patient: number, patientCase: number): Promise<any> {
        await sqlite.connect();
        const condition = ` patient_id = ${patient} AND cases_id = ${patientCase}`;
		const data = await syncable.selectIndex(sqlite.db, table, condition);

		return {
			data: {
				...data.data,
			}
		};
	}

	async storeIndexOffline(items: object): Promise<void> {
		items = Object.values(items).map((item:any) => {

			delete item.agencies_formatted ;
			delete item.invitees_formatted ;
			// delete item.recommendations ;

			item.agencies = JSON.stringify(item.agencies);
			item.invitees = JSON.stringify(item.invitees);
			item.recommendations = JSON.stringify(item.recommendations);
			return item;
		});
        await sqlite.connect();
        syncable.table = table;
        for (const index in items) {
            const item = items[index as keyof object];
            syncable.table = table;
            const checkRecord = await syncable.checkUnsyncRecord(sqlite.db, item['id']);
            if (checkRecord) {
                return;
            }
            await syncable.sync(sqlite.db, [item]);
        }
	}

	async create(patient: number, patientCase: number): Promise<any> {
        await sqlite.connect();
		const patientData = await sqlite.db.select(`SELECT * FROM patients where id = ${patient}`);
		const wcpuPersonnels = await options.wcpuPersonnels();
        const agencies = await options.agencies();
        const socialWorkers = await options.socialWorkers();
		return {
			data : {
				invitees: wcpuPersonnels,
				agencies: agencies,
				socialWorkers: socialWorkers,
				patient_name: `${patientData[0].first_name} ${patientData[0].last_name}` ,
				recommendations: [],
			}
		}
	}

	async store(patient: any, patientCase: any, payload: any): Promise<any> {
        const social_worker = await this.getSocialWorkerName(payload);
		const data = {
			...payload,
			social_worker: social_worker, 
			case_conference_number: 'offline',
			patient_id: parseInt(patient),
			cases_id: parseInt(patientCase),
			is_created_offline: true, 
			date_time: DateFormatter.customDateFormat(payload.date_time, 'yyyy-MM-dd hh:mm:ss'),
			created_at: DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
			updated_at: DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
			agencies: JSON.stringify(payload.agencies),
			invitees: JSON.stringify(payload.invitees),
            is_sync: false,
            synching_remarks: null,
		}
        syncable.table = table;
        await syncable.sync(sqlite.db, [data]);
        const conference = await sqlite.db.select(`SELECT * FROM ${table} ORDER BY id DESC LIMIT 1`);

		return {
			data: {
				data: {
					item: conference.length > 0 ? conference[0] : null,
				}
			}
		}
	}

	async edit(patient: number, patientCase: number, caseConference: any): Promise<any> {
        await sqlite.connect();
        const result = await sqlite.db.select(`SELECT * FROM ${table} WHERE id=${parseInt(caseConference)}`);

		const item = await this.setFormParamsData(result.length > 0 ? result[0] : null);
        const recommendations = await this.recommendationFormatter(caseConference);
        const invitees = await options.wcpuPersonnels();
        const agencies = await options.agencies();
        const socialWorkers = await options.socialWorkers();
		return {
			data : {
				item: {
					...item,
					recommendations: recommendations,
				},
				invitees: invitees,
				agencies: agencies,
				socialWorkers: socialWorkers,
			}
		}
	}

	async update(patient: number, patientCase: number, caseConference: number, payload: any): Promise<any> {
        await sqlite.connect();
		const item = await sqlite.db.select(`SELECT * FROM case_conferences where id = ${caseConference}`);
        const social_worker = await this.getSocialWorkerName(payload);
		const data = Object.assign(
			item[0],
			{	
				...payload,    
				social_worker: social_worker, 
				is_updated_offline: payload.is_created_offline ? false : true, 
				updated_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
                date_time: DateFormatter.customDateFormat(payload.date_time, 'yyyy-MM-dd hh:mm:ss'),
				agencies: JSON.stringify(payload.agencies),
				invitees: JSON.stringify(payload.invitees),
			}
		);
        syncable.table = table;
        await syncable.sync(sqlite.db, [{
            id: data.id,
            patient_id: data.patient_id,
            cases_id: data.cases_id,
            social_worker_id: data.social_worker_id,
            case_conference_number: data.case_conference_number,
            date_time: data.date_time,
            other_aggravating: data.other_aggravating,
            other_mitigating: data.other_mitigating,
            meeting_minutes: data.meeting_minutes,
            invitees: data.invitees,
            agencies: data.agencies,
            recommendations: data.recommendations,
            encoded_by: data.encoded_by,
            social_worker: data.social_worker,
            patient_name: data.patient_name,
            is_sync: false,
            synching_remarks: null,
            is_updated_offline: data.is_updated_offline,
            updated_at: data.updated_at,
        }]);
	}

	async storeMinutes(patient: number, patientCase: number, caseConference: any, payload: any): Promise<any> {
        await sqlite.connect();
		const case_conferences = await sqlite.db.select(`SELECT * FROM ${table}`);

        const item = await sqlite.db.searchTable(table, 'id', parseInt(caseConference));

		const data = Object.assign(
			item[0],
			{	
				meeting_minutes: payload.meeting_minutes,
                is_sync: false,
                synching_remarks: null,
                is_updated_offline: payload.is_created_offline ? false : true, 
				updated_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
			}
		);
        syncable.table = table;
        await syncable.sync(sqlite.db, [data]);
        const response = await sqlite.db.searchTable(table, 'id', parseInt(caseConference));
        return response.length > 0 ? response[0] : null;
	}

	async delete(patient: number, patientCase: number, caseConference: number, forceDelete = false): Promise<any> {
        await sqlite.connect();
        if (forceDelete) {
            await this.forceDelete(caseConference, patientCase)
       }

        const item = await sqlite.db.select(`SELECT * FROM ${table} where id = ${caseConference}`);
        if (item.length) {
            if (item[0].is_created_offline) {
                await this.forceDelete(caseConference, patientCase);
            } else {
                const data = Object.assign(
                    item[0],
                    {	
                        action: OfflineAction.DELETE_RECORD,
                        deleted_at: DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
                        is_deleted_offline: true,
                        is_sync: false,
                        synching_remarks: null,
                    }
                );
                syncable.table = table;
                await syncable.sync(sqlite.db, [data]);
            }
        }
		
        const response = await sqlite.db.select(`SELECT * FROM ${table} WHERE id=${caseConference}`);
        return response.length > 0 ? response[0] : null;
	}


	//Sub Pages
	async indexFactors(patient: number, patientCase: number, caseConference: number): Promise<any> {
		return await FactorsService.index(patient, patientCase, caseConference);
	}

	async storeFactorsOffline(item: any): Promise<void> {
		return await FactorsService.storeOffline(item);
	}

	async storeFactors(patient: number, patientCase: number, caseConference: number, payload: any): Promise<any> {
		return await FactorsService.store(patient, patientCase, caseConference, payload);
	}

	async indexRecommendations(patient: number, patientCase: number, caseConference: number): Promise<any> {
		return await RecommendationsService.index(patient, patientCase, caseConference);
	}

	async storeRecommendations(patient: number, patientCase: number, caseConference: any, payload: any): Promise<any> {
		return await RecommendationsService.store(patient, patientCase, caseConference, payload);
	}
	
	async storeRecommendationsOffline(items: any): Promise<any> {
		return await RecommendationsService.storeRecommendationsOffline(items)
	}

	async indexMinutes(patient: number, patientCase: number, caseConference: number): Promise<any> {
        await sqlite.connect();
        const result = await sqlite.db.searchTable(table, 'id', caseConference)

		return {
			data : {
				item: {
					case_conference_id: caseConference,
					meeting_minutes: result.length > 0 ? result[0].meeting_minutes : null,
				},
			}
		}
	}

	//Formatters
	async recommendationFormatter(caseConference: number): Promise<string[]|null> {
		const items:any = []
		const recommendations = await sqlite.db.select(`SELECT * FROM case_conference_recommendations where case_conference_id = ${caseConference}`);
		const recommendationOptions = await options.recommendationOptions();


		if(recommendations.length == 0) {
			return null;
		}

		return recommendations.map((item:any) => {
			const index = recommendationOptions.map((item:any) => item.id).indexOf(item.recommendation_option_id);
			const recommendations = recommendationOptions[index];

			return `${recommendations.name}: ${item?.remarks ?? ''}`;
		});

		return items;
	}

	private async setFormParamsData(data: any): Promise<any>{	
		const patientData = await sqlite.db.select(`SELECT * FROM patients WHERE id = ${data.patient_id}`);

		data.agencies = JSON.parse(data.agencies);
		data.agencies_formatted = await this.agenciesFormatter(data.agencies);

		data.invitees = JSON.parse(data.invitees);
		data.invitees_formatted = await this.inviteesFormatter(data.invitees);

		data.recommendations = JSON.parse(data.recommendations);
		data.patient_name = `${patientData[0].first_name} ${patientData[0].last_name}`;
		return data;
	}

	private async agenciesFormatter(data:any): Promise<any> {
		const agenciesOptions = await options.agencies();

		const items:any = [];

		data.forEach((item:any) => {
			const index = Object.values(agenciesOptions).map((item:any) => item.id).indexOf(item.agency_id);

			if(index != -1){
				items.push({	
					name: `${item.name} - ${agenciesOptions[index].name}`
				});
			}
		});	

		return items;
	}

	private async inviteesFormatter(data:any): Promise<any> {
		const inviteesOption = await options.wcpuPersonnels();

		const items:any = [];

		data.forEach((item:any) => {
			const index = Object.values(inviteesOption).map((item:any) => item.id).indexOf(item);

			if(index != -1){
				items.push({	
					name: inviteesOption[index].name
				});
			}
		});	

		return items;
	}

	private async getSocialWorkerName(data:any): Promise<string> {
		const workers = await options.socialWorkers();

		const index = Object.values(workers).map((item:any) => item.id).indexOf(data.social_worker_id);

		if(index != -1){
			return workers[index]['name'];
		}


		return "";
	}

    async forceDelete(id: number, case_id: number) {
        await sqlite.db.delete(table,{id: id, cases_id: case_id});
    }
}

export default new OfflineService();
