import Syncable from "@/extenders/models/Syncable";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Form from "@/classes/Cases/CaseInformant/Offline/Formatters/CaseCompanionForm"
import useFormatFormStructure from "@/helpers/Offlinehelpers/useFormatFormStructure";
import Storage from "@/helpers/storage";
import FormOptions from "@/classes/Forms/FormOptions";
import Gender from "@/enums/Gender";
import Relationships from "@/classes/Forms/Relationships";
import DefaultFormStructureBuild from "@/classes/Cases/CaseHistory/Offline/Formatters/DefaultFormStructureBuild";
import PatientOfflineProcessor from "@/classes/Patients/Offline/processor/PatientOfflineProcessor";
const relationship = new Relationships();
const options  = new FormOptions();

const storage = new Storage();
const sqlite = new SqliteProcessor;
const syncable = new Syncable;
const table = "case_companions";

class OfflineProcessor extends Syncable {

    /**
     * Render Index page
     * @param cases_id 
     * @returns 
     */
   async index(cases_id: number){
       await sqlite.connect();
       const query = `SELECT * FROM ${table} WHERE cases_id = ${cases_id} AND deleted_at IS NULL`;
       const companions = await sqlite.db.select(query);
     
       return {
           'data': {
               'items': {
                   data: companions.length > 0 ? companions : [],
                   total: companions.length
               }
           },
       };
   }

    /**
    * Render form data
    * @returns data
    */
    async create(patient:number){
        await sqlite.connect();

        const companionRelationships = await options.companionRelationships();
        const patientDetail = await PatientOfflineProcessor.getFullName(patient);
        return {
            data : {  
                genders: Gender,
                companionRelationships: companionRelationships,
                patient: patientDetail,
            }
        }
    }

   /**
    * Update or create of data from storage
    * @param patient
    * @param patientCase
    * @param payload
    */
   async updateCreate(companion:number, patient: any, patientCase: any, payload: any) {
       this.table = table; //this needs to be instantiate to used in this.sync method.
       let structuredForm;
       if(companion){
           structuredForm  = useFormatFormStructure(payload, Form.updateForm(payload));
       }else{
           structuredForm  = useFormatFormStructure(payload, Form.createForm());
       }
       
       const encoded_by = JSON.parse(storage.get('user'));
       structuredForm.cases_id = patientCase;
       structuredForm.encoded_by =  encoded_by.id;
       structuredForm.birthdate = payload.birthdate ? relationship.dateFormatFromIso(payload.birthdate) : null;
      
       await sqlite.connect();
       const companions = await this.sync(sqlite.db, [structuredForm]); 
      
       return {
           data: {
               data: {
                   item: companions
               }
           }
       }
    
   }

   /**
    * Render data form
    * @param patient 
    * @param patientCase 
    * @param companion 
    * @returns 
    */
   async edit( patient: any, patientCase: any, companion: any){
       await sqlite.connect();
       const query = `SELECT * FROM ${table} WHERE  cases_id = ${patientCase} AND id = ${companion} LIMIT 1`;
       const item = await sqlite.db.select(query)

       const patientDetail = await PatientOfflineProcessor.getFullName(patient);
       const companionRelationships = await options.companionRelationships();
       return {
           data : {
               item: {
                   ...item,
               },
               patient: patientDetail,
               genders: Gender,
               companionRelationships: companionRelationships,
           }
       }
   }

   /**
    * Remove data from storage
    * @param companion 
    * @param patientCase 
    * @returns 
    */
   async delete(companion:number, patientCase: number, forceDelete = false){
       await sqlite.connect();
       if (forceDelete) {
            await this.forceDelete(companion, patientCase)
        }

        // check if record exist offline
        const response = await sqlite.db.select(`SELECT * FROM ${table} WHERE id=${companion} AND cases_id=${patientCase}`);
        if (response.length > 0) {
            const item = response[0];

            if (item.is_created_offline) {
                await this.forceDelete(companion, patientCase);
            } else {
                const form = {
                    id: companion,
                    is_sync: false,
                    synching_remarks: null,
                    is_deleted_offline: true,
                    deleted_at: relationship.dateFormatFromIso(new Date),
                }
                await sqlite.db.updateWithFilters(table, form, { cases_id: patientCase, id: companion });
            }
        }
       return this.index(patientCase);
   }

   async getCount(case_id: number) {
        const response = await sqlite.db.select(`SELECT count(id) as count FROM ${table} WHERE cases_id=${case_id} AND (deleted_at IS NULL OR deleted_at = 0)`);
        return response.length > 0 ? response[0].count : 0;
    }

    async syncToOffline(case_id: number, payload: any) {
        await sqlite.connect();
        const form = useFormatFormStructure(payload, Form.syncToOffline(payload));
        form.cases_id = case_id;
        syncable.table = table;
        const checkRecord = await syncable.checkUnsyncRecord(sqlite.db, form.id);
        if (checkRecord) {
            return;
        }
        await syncable.sync(sqlite.db, [DefaultFormStructureBuild.resetStatus(form)]);
    }

    async forceDelete(companion: number, case_id: number) {
        await sqlite.db.delete(table,{id: companion, cases_id: case_id});
    }
}


export default new OfflineProcessor();