const assignValues = (source: any, data: any): any => {
    Object.entries(data).forEach(([key, value]) => {
        if (typeof source[key] != 'undefined') {
            // find boolean value true|false that was converted into string
            if (value === 'true' || value === 'false') {
                source[key] = eval(value);
            } else {
                source[key] = value;
            }
        }
    });

    return source;
}

export default assignValues;



