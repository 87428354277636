<template>
	<intake-layout :patient="patient">
		<!-- <template #actionButtons>
			<button-component btnWidth="quinary">Cancel</button-component>
			<button-component 
				customClass="primary" 
				@click="save"
			>
				Save Changes
			</button-component>
		</template> -->

		<div class="col-span-12">
			<data-table
				:headers="headers"
				:no-action="false"
				:count="items?.data?.length"
				dataTableClass="border-t-0 border-x-0 "
			>
				<template #body>
					<template v-for="item in items.data" :key="item">
						<tr>
							<td class="p-6 text-sm font-normal text-gray-900 ">
								{{ item.email }}
							</td>
							<td class="text-sm font-normal text-gray-900">
								{{ item.label }}
							</td>
							<td class="text-sm font-normal text-gray-900">
								{{ item.updated_at_formatted }}
							</td>
							<td class="flex justify-center p-6 space-x-2">
								<view-button
									:isEyeIcon="false"
									@click="showModal(item)"
									class="shrink-0"
								/>
								<delete-button
									@click="showDeleteModal(item)"
									class="shrink-0"
								/>
							</td>
						</tr>
					</template>
				</template>
			</data-table>
		</div>

		<div class="flex justify-end col-span-12">
			<button-component 
				customClass="primary sm:w-auto w-full"
				@click="showCreateModal()"
			>
				<PlusIcon class="w-3.5 h-3.5"/>
				<p>Add New</p>
			</button-component>
		</div>

		<!-- MODAL -->
		<success-modal
			noBtn
			:show="showSuccess"
			action-text="Close"
			title="Record Save"
			content="Intake has been updated" 
            @close="showSuccess = false"
		/>

		<confirmation-modal
			:typeTwo="true"
			:show="showConfirmation"
			@cancel="showConfirmation = false"
			@confirm="deleteItem()"
			cancel-text="Cancel"
			action-text="Confirm"
			title="Delete Record?"
			content="Are you sure you wish to delete this record?" 
		/>

		<!-- Delete Record Success -->
		<success-modal	
			noBtn
			:show="showDeleteSuccess"
			action-text="Close"
			title="Record Deleted!"
			content="Selected record has been successfully deleted." 
            @close="showDeleteSuccess = false"
		/>

		<content-modal
			:show="showContent" 
			title="Add Email Address"
		>
			<div>
				<text-input
					label="Label"
					placeholder="Label"
					name="label"
					id="label"
					v-model="form.label"
					:error="errors?.label"
				/>
			</div>

			<div>
				<text-input
					type="email"
					label="Email Address"
					placeholder="sample@gmail.com"
					name="email_address"
					id="email_address"
					v-model="form.email"
					:error="errors?.email"
				/>
			</div>

			<template #button>
				<button-component  
					btnWidth="quinary"
					@click="showContent = false"
				>
					Cancel
				</button-component >
				<button-component 
					customClass="primary" 
					@click="validateForm()"
				>
					Save Changes
				</button-component>
			</template>
		</content-modal>
		<page-loader :show="loading"/>
	</intake-layout>
</template>

<script setup lang="ts">
import IntakeLayout from '../../Component/IntakeLayout.vue';
import ViewButton from "@/components/buttons/ViewButton.vue";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import DataTable from "@/components/partials/DataTable.vue";
import ContentModal from "../../Component/ContentModal.vue";
import { ref, onMounted,computed } from 'vue'; 
import { PlusIcon } from "@heroicons/vue/24/solid"; 
import SuccessModal from "@/components/modals/SuccessModal.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import EmailService from '@/classes/Cases/IntakeForm/EmailService';
import { EmailIndex } from '@/interfaces/Cases/IntakeForm/Email';
import { useRoute } from "vue-router";
import pickBy from "lodash/pickBy";
import PageLoader from "@/components/loaders/PageLoader.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import { errorResponse } from "@/helpers/errorMessage"; 
import { onIonViewWillEnter } from '@ionic/vue'; 

/******************************* For Offline Imports************************************ */
  import { useNetworkStore } from "@/store/network";
 import Rules from "@/classes/Cases/Intake/EmailAddresses/Validation/Rules";
 import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
 import useVuelidate from "@vuelidate/core"; 
 import  EmailForm from "@/classes/Cases/Intake/EmailAddresses/Formatter/EmailForm";
 import EmailAddressService from "@/classes/Cases/Intake/EmailAddresses/EmailAddressService";
 import EmailOfflineProcessor from '@/classes/Cases/Intake/EmailAddresses/Processor/OfflineProcessor';
 import OnlineSynching from '@/classes/Synching/OnlineSynching';

 const { createRules } = Rules;
 const networkStore = useNetworkStore();
 const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable); 
 /*************************************************************************************** */
 
const route = useRoute();
const showSuccess = ref<boolean>(false);
const showContent = ref<boolean>(false);
const items = ref<EmailIndex[]>([]);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const loading = ref<boolean>(false);
const isUpdate = ref<boolean>(false)
const showConfirmation = ref<boolean>(false)
const selectedItemId = ref<number | null>(null)
const showDeleteSuccess = ref<boolean>(false)
const errors = ref<object | null>(null);
const patient = ref<string>(''); 
const isIonWillEnter = ref<boolean>(false);
const onlineSync = new OnlineSynching();

const headers = [
	{ text: 'Email Address' },
	{ text: 'Label' },
	{ text: 'Date Updated' },
]

const form = ref(EmailForm.createForm() )

const clearForm = () => { EmailForm.createForm() }

const showCreateModal =() => {
	form.value = EmailForm.createForm();
	isUpdate.value = false;
	showContent.value = true;
	errors.value = null;
}
const showModal = (item:any) => { 
	form.value = EmailForm.updateForm(item);
	selectedItemId.value = item.id
	showContent.value = true
	isUpdate.value = true
    errors.value = null;
}

const showDeleteModal = (item:any) => {
	form.value = EmailForm.deleteForm(item);
	showConfirmation.value = true
	selectedItemId.value = item.id
	clearForm();
}
 /**
 * Validate the form using Vuelidator
 */
 const validateForm = () =>{
	
	const vuelidate = useVuelidate(createRules({...form.value}), form.value)
	const result = vuelidate.value.$validate();
 
	result.then((res) => {
		if(res) { 
			addNew()
		} else {
			errors.value = ErrorValidation.getErrors(vuelidate.value)
            errorResponse(errors.value);
		}
	})
}
const addNew = async () => {
	loading.value = true;
    errors.value = null; 
 
	if(isUpdate.value){
		await EmailAddressService.update(patientId.value, caseId.value, selectedItemId.value, isNetworkAvailable.value, form.value)
			.then((response: object) => {
				showSuccess.value = true;
				setTimeout(() => {
					showSuccess.value = false;
				}, 2000)
				
				loading.value = false;
				showContent.value = false;
			
				getData()
			})
			.catch((error: object) => {
				loading.value = false;
				errors.value = errorResponse(error.response?.data?.errors);
			});
	}else{
		await EmailAddressService.store(patientId.value, caseId.value, isNetworkAvailable.value, form.value)
			.then((response: object) => {
				showSuccess.value = true;
				setTimeout(() => {
					showSuccess.value = false;
				}, 2000)
				
				loading.value = false;
				showContent.value = false;
			
				getData()
			})
			.catch((error: object) => {
				loading.value = false;
				errors.value = errorResponse(error.response?.data?.errors);
			});
	}
}



const getData = async () => { 
	loading.value = true;
	if (caseId.value) {
		await EmailAddressService.index(
			patientId.value,
			caseId.value,
			isNetworkAvailable.value,
			pickBy({ ...{ rows: 10 } }),
		)
			.then(async (response: object) => {
				items.value = response.data.items;
				console.log(items.value);
				patient.value = response.data.patient; 
                if (isNetworkAvailable.value) {
                    await syncToOffline();
                }
				loading.value = false;
			})
			.catch((error: object) => {
				errors.value = errorResponse(error.response?.data?.errors);
				loading.value = false;
			});
	}
};

const syncToOffline = async () => {
    for (const index in items.value.data) {
        const item = items.value.data[index];
        await EmailOfflineProcessor.syncToOffline(caseId.value, item);
    }
}

const deleteItem = async () => {
	loading.value = true; 
	await EmailAddressService.delete(
		patientId.value,
		caseId.value,
		selectedItemId.value,
		isNetworkAvailable.value,
		form.value,
	)
		.then(async () => {
            if (isNetworkAvailable.value) {
                await EmailOfflineProcessor.delete(selectedItemId.value, caseId.value, true);
            }
			loading.value = false;
			showDeleteSuccess.value = true;
			setTimeout(() => {
				showDeleteSuccess.value = false;
			}, 2000)
			showConfirmation.value = false;
			getData()
		})
		.catch((error: object) => {
			errors.value = errorResponse(error.response?.data?.errors);
			loading.value = false;
		});
  
};


onIonViewWillEnter(async () => {
    if (isIonWillEnter.value) {
        await getData();
        await onlineSync.checkDrafts();
    }
});

onMounted(async () => {
    await getData();
    await onlineSync.checkDrafts();
    isIonWillEnter.value = true;
});

</script>