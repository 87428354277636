<template>
	<user-layout>
		<div class="max-w-[500px] w-full mx-auto py-16">
			<div class="bg-primary-400 rounded-lg px-6 py-6">
				<h1 class="text-xl mb-6 font-bold">Search Patient</h1>
				<h2 class="text-sm font-bold mb-6">Patient Name</h2>
				<form class="flex flex-col space-y-6" @submit.prevent="submit">
					<text-input
						type="text"
						label="First Name"
						placeholder="First name"
						name="first_name"
						id="first_name"
						v-model="form.first_name"
						:error="errors?.first_name" />

					<text-input
						type="text"
						label="Last Name"
						placeholder="Last name"
						name="last_name"
						id="last_name"
						v-model="form.last_name"
						:error="errors?.last_name" />

					<Disclosure v-slot="{ open }">
						<DisclosureButton class="text-left cursor-default w-full md:pt-0 pt-3">
							<button-component
								customClass="secondary w-full"
								@click="addFilter = true"
							>
								<PlusIcon v-if="!open" class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
								<MinusIcon v-if="open" class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
								<span>Add Filter Search</span>
							</button-component>
						</DisclosureButton>
						<DisclosurePanel>
							<date-picker
								id="birthdate"
								name="birthdate"
								label="Birthdate"
								v-model="form.birthdate"
								:error="errors?.birthdate" />
						</DisclosurePanel>
					</Disclosure>

					<div>
						<button-component type="submit" size="large" btnWidth="w-full"> Search </button-component>
					</div>
				</form>
			</div>
		</div>
		<page-loader :show="loading" />
	</user-layout>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { PlusIcon, MinusIcon } from "@heroicons/vue/24/outline";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { errorResponse } from "@/helpers/errorMessage";
import { useRouter } from "vue-router";  
import { useNetworkStore } from "@/store/network";
import PatientRecordService from "@/classes/Patients/PatientRecordService";
import Rules from "@/classes/Patients/Offline/Validation/Rules";
import useVuelidate from "@vuelidate/core";
import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
import Storage from "@/helpers/storage";
import { onIonViewWillEnter } from "@ionic/vue";
import { useSecurityFeature } from "@/store/securityFeature";
 
const router = useRouter();
const form = ref<{ last_name: string; first_name: string; birthdate: any }>({
	last_name: "",
	first_name: "",
	birthdate: "",
});
const addFilter = ref<boolean>(false);
const errors = ref<object>({});
const loading = ref<boolean>(false);
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const { searchRules } = Rules;
const storage = new Storage();

/**
 * Validate the form using Vuelidator
 */
 const validateForm = async () =>{
	const vuelidate = useVuelidate(searchRules({...form.value}), form.value)
	const result = await vuelidate.value.$validate();

    if (result) {
        return null;
    } else {
        errors.value = ErrorValidation.getErrors(vuelidate.value);
        errorResponse(errors.value);
        return errors.value;
    }
}

const submit = async (): void => {
	loading.value = true;

    // Set validation rule for Online and Offline
    const validate = await validateForm();
    if (validate) {
        return;
    }
    
    // Call this service when network is online or offline
    const response = await PatientRecordService.search({
			last_name: form.value.last_name,
			first_name: form.value.first_name,
			birthdate: form.value.birthdate,
		}, isNetworkAvailable.value);

    if (response?.status === 200 || response?.data != undefined) {
        errors.value = null;
        
        loading.value = false;
        latestSearchHistory();
        router.push({ path: "/patient/index", query: form.value });
    } else {
        const error = response;
        loading.value = false;
        errors.value = errorResponse(error.response?.data?.errors);
    }
};

const latestSearchHistory = () => {
    storage.set('search', JSON.stringify({
        last_name: form.value.last_name,
        first_name: form.value.first_name,
        birthdate: form.value.birthdate,
    }));
}

const checkHasPasswordBlocker = () => {
    const item = sessionStorage.getItem('hasPasswordBlocker');
    if (!item) {
        useSecurityFeature().isActivated = false
        useSecurityFeature().isPasswordBlocker = true;
        sessionStorage.setItem('hasPasswordBlocker', true);
    }
}

onIonViewWillEnter(() => {
    if (!isNetworkAvailable.value) {
        checkHasPasswordBlocker();
    }
});
</script>
