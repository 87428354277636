import CaseAssessmentStatus from "@/enums/CaseAssessmentStatus";
import FormOptions from "@/classes/Forms/FormOptions";
import { risk_codes } from "@/migrations/tables/Options/risk_codes";
import SqliteProcessor from "@/processors/SqliteProcessor";
import useFormatFormStructure from "@/helpers/Offlinehelpers/useFormatFormStructure";
import CaseSafetyAssessmentForm from "../Formatters/CaseSafetyAssessmentForm";
import Syncable from "@/extenders/models/Syncable";
import Storage from "@/helpers/storage";
const sqlite = new SqliteProcessor();
const options = new FormOptions(); 
const table = 'case_safety_assessments';
class OfflineProcessor extends Syncable {

    /**
     * Renders additional form data for create page.
     * @param patientCase 
     * @returns 
     */
    async create(patientCase: number){ 
        await sqlite.connect();  
        const caseQuery = `SELECT * FROM cases WHERE id=${patientCase} LIMIT 1`;
        const patient_case = await sqlite.db.select(caseQuery);
         
        const patientQuery = `SELECT * FROM patients WHERE id=${patient_case[0].patient_id}`;
        const patient = await sqlite.db.select(patientQuery); 

        //same response as well in backend
        const questions = await options.safetyAssessmentQuestions();
        const riskCodes = await options.riskCodes();
        return {
            data: {
                case: {
                    ...patient[0],
                    case_number: patient_case[0].case_number,
                    assessment_date: patient_case[0].initiated_at
                },
                status: CaseAssessmentStatus,
                questions: questions,
                riskCodes: riskCodes,
            }
        }
    }

    /**
     * Render item data from storage with additional data
     * @param patientCase 
     * @param assessmentId 
     * @returns 
     */
    async edit(patientCase: number, assessmentId:number){ 
        await sqlite.connect();
 
        const safetyAssessmentQuery = `SELECT * FROM ${table} WHERE id=${assessmentId} AND cases_id=${patientCase} LIMIT 1`;
        const assessment = await sqlite.db.select(safetyAssessmentQuery);
 
        const questionsTable = `case_safety_assessment_questions`;
        const assessmentQuestionQuery = `SELECT * FROM  ${questionsTable} WHERE case_safety_assessment_id=${assessmentId}`;
        const questions = await sqlite.db.select(assessmentQuestionQuery);
        
        //this is the same response from the backend
        const questionOptions = await options.safetyAssessmentQuestions();
        const riskCodeOptions = await options.riskCodes();
        return {
            data: {
                item: {
                    name: `${assessment[0]?.first_name || ""} ${assessment[0]?.last_name || ""}`,
                    ...assessment[0],
                    safety_assessment_questions: questions,
                }, 
                status: CaseAssessmentStatus,
                questions: questionOptions,
                riskCodes: riskCodeOptions,
            }
        }
    }
    /**
    * Update or create of data from storage
    * @param patient
    * @param patientCase
    * @param payload
    */
    async updateCreate(patientID:number, patientCase: any, assessmentId: any, payload: any) {
        this.table = table; //this needs to be instantiate to used in this.sync method.
        let structuredForm;
    
        if(assessmentId){  
            structuredForm  = useFormatFormStructure(payload, CaseSafetyAssessmentForm.updateForm(payload));
            structuredForm.is_updated_offline = true;   
        }else{
            structuredForm  = useFormatFormStructure(payload, CaseSafetyAssessmentForm.createForm());
            structuredForm.cases_id = patientCase;
            structuredForm.is_created_offline = true;
        } 
        //store to `case_safety_assessments` table
        structuredForm.is_sync = false;
        structuredForm.is_sync_failed = false;
        await sqlite.connect();  
        await this.sync(sqlite.db, [structuredForm]);  

        //Sync the questions to `case_safety_assessment_questions` and remove question if existed
        let assessment = [];
        if (assessmentId) {
            assessment = await sqlite.db.select(`SELECT * FROM ${table} WHERE id=${assessmentId}  ORDER BY created_at desc LIMIT 1`);
        } else {
            assessment = await sqlite.db.select(`SELECT * FROM ${table} ORDER BY id DESC LIMIT 1`);
        }

        if (assessment.length > 0) {
            const builder = await this.questionsFormBuilder(payload, assessment[0].id);            
            //remove and create many for deleting existing and set again
            await sqlite.connect();
            this.table = `case_safety_assessment_questions`;
            await this.removeCreateMany(sqlite.db, {case_safety_assessment_id: assessment[0].id}, builder);    
        }

        return {
            data: {
                data: {
                    item: assessment
                }
            }
        }
  
    }
    
    /**
     * Check if an assessment record existed
     * @returns 
     */
    async check(id: number){
        await sqlite.connect();
        return  await sqlite.db.select(`SELECT * FROM ${table} WHERE id=${id}  ORDER BY created_at desc LIMIT 1`);
    }

    /**
     * Builds the form to match in `case_safety_assessment_questions` table
     */
    questionsFormBuilder = async(payload:any, assessmentId: number) =>{
        const questions =  await options.safetyAssessmentQuestions();
        const formBuilder = questions.map((item: any) => {
            const foundQuestion = payload.questions.find((q_item: any) => q_item.question_id === item.id);
            if (foundQuestion) {
                //match to the tables migration
                return {
                    case_safety_assessment_id: assessmentId,
                    question_id: item.id,
                    selected_answer: foundQuestion.selected_answer,
                    supporting_information: foundQuestion.supporting_information,
                    status: foundQuestion.status,
                };
            } 
        });
        const filteredFormBuilder = formBuilder.filter((item:any) => item);
        return filteredFormBuilder;
    }

    async syncToOffline(case_id: number, payload: any) {
        await sqlite.connect();  
        const form = useFormatFormStructure(payload, CaseSafetyAssessmentForm.updateForm(payload));

        //store to `case_safety_assessments` table
        form.is_created_offline = false;
        form.is_updated_offline = false;
        form.is_deleted_offline = false;
        form.is_sync = false;
        form.is_sync_failed = false;
        form.synching_remarks = false;
        this.table = table;
        await this.sync(sqlite.db, [form]);  

        // //Sync the questions to `case_safety_assessment_questions` and remove question if existed
        const assessment = await sqlite.db.select(`SELECT * FROM ${table}  ORDER BY created_at desc LIMIT 1`);
        const builder = await this.questionsFormBuilder(payload, assessment[0].id); 

        //remove and create many for deleting existing and set again
        await sqlite.connect();
        this.table = `case_safety_assessment_questions`;
        await this.removeCreateMany(sqlite.db, {case_safety_assessment_id: assessment[0].id}, builder);
    }

    async getItem(case_id: number) {
        const response = await sqlite.db.select(`SELECT * FROM ${table} WHERE cases_id=${case_id} ORDER BY ID LIMIT 1`);

        if (response.length > 0) {
            return response[0];
        }
        return null;
    }
}
export default new OfflineProcessor();