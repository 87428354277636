<template>
	<user-layout hasBack backLink="/personnel/index">
		<div class="max-w-[500px] mx-auto py-6">
			<form-section
				:form="form"
				:errors="errors"
				:departments="departmentOptions"
				:positions="positionOptions"
				:userGroups="userGroupOptions"
				:offices="officeOptions">
				<div class="col-span-2">
					<div class="flex items-center justify-end w-full space-x-3">
						<a @click="router.replace('/personnel/index/')">
							<button-component btnWidth="quinary">Cancel</button-component>
						</a>

						<button-component btnWidth="primary" @click="save()">Save Changes</button-component>
					</div>
					<confirmation-modal
						:typeTwo="true"
						:show="showConfirmation"
						@cancel="showConfirmation = false"
						@confirm="
							showSuccess = true;
							showConfirmation = false;
						"
						cancel-text="Cancel"
						action-text="Confirm"
						title="Save Record"
						content="Are you sure you wish to save this record?" />
					<success-modal
						:show="showSuccess"
						@confirm="redirectSuccess()"
						action-text="Close"
						title="Record Saved!"
						:content="successMessage" />
				</div>
			</form-section>
		</div>
		<page-loader :show="loading" />
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import FormSection from "./FormSection.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { useRouter } from "vue-router";
import Personnels from "@/classes/Personnels";
import { errorResponse } from "@/helpers/errorMessage";
import PageLoader from "@/components/loaders/PageLoader.vue";

const router = useRouter();
const loading = ref<boolean>(false);
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const successMessage = ref<string>("Data has been successfully saved!");
const form = ref<{
	email: string;
	last_name: string;
	first_name: string;
	middle_name: string;
	mobile_number: string;
	home_address: string;
	is_temporary: boolean;
	department_id: any;
	office_id: any;
	role_id: any;
	position_id: any;
    is_active: boolean | null,
}>({
	email: "",
	last_name: "",
	first_name: "",
	middle_name: "",
	mobile_number: "",
	home_address: "",
	is_temporary: false,
	department_id: "",
	office_id: "",
	position_id: "",
	role_id: "",
    is_active: null,
});
const errors = ref<any>();
const officeOptions = ref<[]>([]);
const userGroupOptions = ref<[]>([]);
const positionOptions = ref<[]>([]);
const departmentOptions = ref<[]>([]);

const save = (): void => {
	loading.value = true;
	Personnels.store(form.value)
		.then((response: any) => {
			loading.value = false;
			showConfirmation.value = true;
			successMessage.value = response.data.message;
		})
		.catch((error: any) => {
			loading.value = false;
			(errors.value = errorResponse(error.response?.data?.errors))
		});
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.replace("/personnel/index");
};

onMounted(() => {
	Personnels.create()
		.then((response: any) => {
			officeOptions.value = response.data.offices;
			positionOptions.value = response.data.positions;
			userGroupOptions.value = response.data.userGroups;
			departmentOptions.value = response.data.departments;
		})
		.catch((error: any) => console.log(error));
});
</script>
