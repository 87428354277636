<template>
	<user-layout
		hasBack
		backTitle="Intake Details"
		:backLink="`/patient/${patientId}/cases/${caseId}/intake/view${offlineParams ? '?offline=true' : ''}`"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">

        <button-component
            @click="router.push(`/patient/${patientId}/cases/${caseId}/edit`)"
            customClass="tertiary text-black hover:text-primary-600 transition lg:pl-4 pl-0 md:py-0 py-0 mt-6"
        >
            <arrow-left-icon class="object-contain w-6 h-6 mr-2" />
            <span class="text-sm font-semibold">Patient Case</span>
        </button-component>
		<p class="mt-6 md:text-2xl text-lg font-medium text-neutral-600">Patient's Name:
			<span class="md:inline-block block font-semibold text-neutral-900">{{ patient }}</span>
		</p>
		<div class="w-full lg:p-6 p-4 mx-auto my-6 border border-gray-200 rounded">
			
			<div class="flex lg:flex-row flex-col lg:justify-between lg:items-center lg:space-x-[76px] lg:gap-0 gap-6">

				<div v-if="!isShowNav">
					<slot name="header" />
				</div>

				<!-- Navigation -->
				<div 
					v-else
					class="flex pt-4 pb-2 space-x-8 overflow-x-scroll side-scroll"
				>
					<div class="flex-shrink-0">
						<p 
							@click.prevent="redirect(navLink('personal-information'))"
							class="cursor-pointer"
							:class="[ routeNav('personal-information') ? 'text-primary-600': 'text-neutral-400']"
						>
							Personal Information
						</p>
					</div>

					<div class="flex-shrink-0">
						<p 
							@click.prevent="redirect(navLink('address-present-location'))"
							class="cursor-pointer"
							:class="[ routeNav('address-present-location') ? 'text-primary-600': 'text-neutral-400']"
						>
							Address and Present Location
						</p>
					</div>

					<div class="flex-shrink-0">
						<p 
							@click.prevent="redirect(navLink('persons-related-case'))"
							class="cursor-pointer"
							:class="[ routeNav('persons-related-case') ? 'text-primary-600': 'text-neutral-400']"
						>
							Persons Related to Case
						</p>
					</div>

					<div class="flex-shrink-0">
						<p 
							@click.prevent="redirect(navLink('contact-numbers'))"
							class="cursor-pointer"
							:class="[ routeNav('contact-numbers') ? 'text-primary-600': 'text-neutral-400']"
						>
							Contact Numbers
						</p>
					</div>

					<div class="flex-shrink-0">
						<p 
							@click.prevent="redirect(navLink('email-addresses'))"
							class="cursor-pointer"
							:class="[ routeNav('email-addresses') ? 'text-primary-600': 'text-neutral-400']"
						>
							Email Addresses
						</p>
					</div>

					<div class="flex-shrink-0">
						<p 
							@click.prevent="redirect(navLink('incest-cases'))"
							class="cursor-pointer"
							:class="[ routeNav('incest-cases') ? 'text-primary-600': 'text-neutral-400']"
						>
							Incest Cases 
						</p>
					</div>
					
				</div>

				<!-- Action Buttons -->
				<div class="flex sm:flex-row flex-col flex-shrink-0 sm:space-x-3 sm:space-y-0 space-y-3">
					<slot name="actionButtons"  />
				</div>
			</div>

			<!-- Message when changes inputs -->
			<p 
				v-if="isDirty"
				class="text-xs font-medium text-red-600"
			>
				Kindly save your changes first before browsing through other pages / tabs*
			</p>

			<div class="w-full my-4 border border-neutral-100"></div>

			<!-- BODY -->
			<div class="grid grid-cols-12 gap-6 ">
				<slot />
			</div>
		</div>

		<error-modal @close="closeError" :show="showModal" content="You have unsaved changes that will be lost if you leave the page. Please save changes before proceeding." />
		
	</user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import { useRouter, useRoute } from "vue-router";
import ErrorModal from "@/components/modals/ErrorModal.vue";
import arrowLeftIcon from "@/components/icons/arrowLeftIcon.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";

const props = defineProps({
	isWarningMessage: {
		type: Boolean,
		default: false
	},
	isShowNav: {
		type: Boolean,
		default: true
	},
	patient: {
		type: String,
		default: ''
	},
	isDirty: {
		type: Boolean,
		default: false
	}
})

const router = useRouter();
const route = useRoute();
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const offlineParams = ref<string>(route.query.offline);
const showModal = ref<boolean>(false)

const navLink = (name) => `/patient/${patientId.value}/cases/${caseId.value}/intake/${name}${offlineParams.value ? '?offline=true' : ''}`;
const routeNav = (name) => useRoute().path.split('/').includes(name);

const closeError = () => {
    showModal.value = false
}

const redirect = (tab: string) => {
	if (!props.isDirty) {
		router.push(tab)
	} else {
		showModal.value = true
	}

}


</script>
