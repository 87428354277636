import http from "@/axios";
import Storage from "@/helpers/storage";
import router from "@/router";
import CryptoJS from 'crypto-js'
import { RoleTypes } from "@/views/enums/RoleTypes"
import SqliteProcessor from "@/processors/SqliteProcessor";

const storage = new Storage();
const databaseInitialization = new SqliteProcessor;

const login = async (email: string, password: string) => {
	return await http
		.post("login", {
			email,
			password,
		})
		.then(async (result: any) => {
            return result;
		});
};

const securityFeature = async (email: string, password: string) => {
    return await http.post("check-password", {
        email: email,
        password: password,
    });
}

const downloadResources = async (response: any) => {
    storage.set('isDownloadedResources', false);
    storage.set('enable-security-feature', false);
    storage.set('hasPasswordBlocker', false);
    sessionStorage.setItem('hasPasswordBlocker', 'false');
    storage.clearItem('isSessionLock');
    await databaseInitialization.init();
    gotoDashboard(response.data.user.role_id, response);
    
}

const logout = async () => {
	return await http
		.post("logout")
		.then(async () => {
            await removeCacheWhenLoggingOut();
		})
		.catch(async (err: any) => {
            await removeCacheWhenLoggingOut();
			console.log(err);
		});
};

const removeCacheWhenLoggingOut = async () => {
    try {
        caches.delete('child-protection-v1');
        caches.delete('child-protection-dynamic');
        window.location.reload();
    } catch (error) {
        console.log(error)
    }
    
    storage.clearItems(["access_token", "permissions", "user", "isSessionLock", "search", "hasDraft", "enable-security-feature"]);
    window.location.href = '/login';
}

const hasPermission = (permission: string): boolean => {
	const checker = storage.get("permissions");

	if (checker) {
		const decryptedPermissions = CryptoJS.AES.decrypt(storage.get("permissions"), process.env.VUE_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
		const permissions = JSON.parse(decryptedPermissions);

		if (permissions) {
			return permissions.includes(permission);
		}
	}

	return false;
};

const migrateBackEndOptions = async (office_id?:number): Promise<void> => {

    const response = await http.get(`fetch-options/${office_id}`,).catch(error => error)
    const table = 'dropdown_options';
	
    if (response.status === 200) {
        for (const item in response.data) {
            console.log(item);
        }
    } else {
        await logout();
    }
}

const gotoDashboard = (role:number, result: any):void =>{
    const wcpuRoles = [
        RoleTypes.OFFICE_ADMIN,
        RoleTypes.OFFICE_NORMAL,
        RoleTypes.NURSE,
        RoleTypes.WCPU,
        RoleTypes.OFFICE_TRAINEE,
    ]
    const tokens = new Storage();
            
    tokens.set("access_token", result.data?.token);
    tokens.set("user", JSON.stringify(result.data?.user));
    tokens.set("permissions", CryptoJS.AES.encrypt(JSON.stringify(result.data.permissions), process.env.VUE_APP_SECRET_KEY).toString());
    
    localStorage.removeItem('cpn-offline-draft-count');
    localStorage.removeItem('cpn-online-sync-attemp');
    
    if(wcpuRoles.includes(role)){
        window.location.href = "/dashboard/wcpu";
    } else {
        window.location.href = "/dashboard";
    }
}

export { login, logout, hasPermission, downloadResources, removeCacheWhenLoggingOut, securityFeature };
