import { PatientAddressCreate } from "../Interface/CasePatientInterface";
import Relationships from "@/classes/Forms/Relationships";
const relationship = new Relationships();
import FormOptions from "@/classes/Forms/FormOptions";
import DateFormatter from "@/helpers/DateFormatter";
import { barangays } from "@/migrations/tables/Options/barangays";
import { defaultCreateForm, defaultUpdateForm } from "@/classes/General/GeneralOfflineForms";


const options = new FormOptions();
 class CasePatientAddressForm  {
   
  async viewForm(item: any) { 
    const region = await relationship.regions(item);
    const province = await relationship.provinces(item);
    const city = await relationship.cities(item);
    const barangay = await relationship.barangays(item);
     return { 
       id: item.id,
       region:  region,
       province:  province,
       city: city,
       barangay:  barangay,
       residence_type_meta: relationship.residenceTypes(item),
       address:   item.address,
       direction: item.direction || null,
       remarks: item.remarks || null,
     } 
   }


   updateForm(item: any){ 
       return {
       id: item.id,
       region_id:  item.region_id,
       province_id:   item.province_id,
       city_id: item.city_id,
       barangay_id:  item.barangay_id,
       residence_type:  item.residence_type,
       address:   item.address,
       direction: item.direction || null,
       remarks: item.remarks || null,
         
       //syncing tagging
       ...defaultUpdateForm()
       }
   }


   createForm(){
       return {   
           region_id:  null,
           province_id:   null,
           city_id: null,
           barangay_id:  null,
           residence_type:  null,
           address:   null,
           direction: null,
           remarks: null,
        
          //syncing tagging
          ...defaultCreateForm(),
       }
   }

   syncToOffline(payload: any) {
        const item = this.updateForm(payload);

        return item;
    }
  
}
export default new CasePatientAddressForm();