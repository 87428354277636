import http from "@/axios";

class InterventionTypeService {
	async index(params: object = {}): Promise<any> {
		return http.get(`settings/intervention-forms/`, { params: params });
	}

	async create(parent:number|null = null, params: object = {}): Promise<any> {
		return await http.get(`settings/intervention-forms/create/${parent}`, { params: params });
	}

	async store(parent:number|null = null, payload: object): Promise<any> {
		return await http.post(`settings/intervention-forms/store/${parent}`, payload);
	}

	async edit(form:number, payload: object = {}): Promise<any> {
		return await http.get(`settings/intervention-forms/${form}/edit`, { params:  payload, });
	}
	
	async update(form:number, payload: object): Promise<any> {
		return await http.post(`settings/intervention-forms/${form}/update`, payload);
	}
	
	async delete(form:number): Promise<any> {
		return await http.delete(`settings/intervention-forms/${form}/delete`);
	}
	
	async restore(form:number): Promise<any> {
		return await http.patch(`settings/intervention-forms/${form}/restore`);
	}

	async getChildForms(form:number, payload: object = {}): Promise<any> {
		return await http.get(`settings/intervention-forms/${form}/get-child-forms`, { params:  payload, });
	}
	
	async updateChildrenOrder(form:number, payload: object): Promise<any> {
		return await http.post(`settings/intervention-forms/${form}/update-children-order`, payload);
	}

    async removeField(id: number | null) {
        return await http.delete(`settings/intervention-forms/field/${id}/delete`);
    }
}
export default new InterventionTypeService();
