<template>
    <div class="w-full">
        <!-- View Mode -->
        <div v-if="!isEdit && !isCreate" class="grid items-start grid-cols-12 gap-x-6 gap-y-3">
            <div class="grid items-start grid-cols-6 col-span-12 md:col-span-6 gap-x-6 gap-y-3">
                <div class="col-span-6">
                    <p class="mb-1 text-xs font-medium text-neutral-500">
                        Fullname
                    </p>
                    <p class="mb-1 text-sm font-bold">
                        {{ item?.patient_name }}
                    </p>
                </div>
                <div class="col-span-6">
                    <p class="mb-1 text-xs font-medium text-neutral-500">
                        Social Worker
                    </p>
                    <p class="mb-1 text-sm font-bold">
                        {{ item?.social_worker }}
                    </p>
                </div>
                <div class="col-span-6">
                    <p class="mb-1 text-xs font-medium text-neutral-500">
                        CPU Invitees
                    </p>
                    <ol class="pl-5 mb-1 text-sm font-bold">
                        <li class="list-decimal" v-for="i in item?.invitees_formatted" :key="i">
                            {{ i.name }}
                        </li>
                    </ol>
                </div>
            </div>
            <div class="grid items-start grid-cols-6 col-span-12 md:col-span-6 gap-x-6 gap-y-3">
                <div class="col-span-6">
                    <p class="mb-1 text-xs font-medium text-neutral-500">
                        CPU Case No.
                    </p>
                    <p class="mb-1 text-sm font-bold">
                        {{ item?.case_conference_number }}
                    </p>
                </div>
                <div class="col-span-6">
                    <p class="mb-1 text-xs font-medium text-neutral-500">
                        Date and Time
                    </p>
                    <p class="mb-1 text-sm font-bold">
                        {{ DateFormatter.defaultTimeStamp(item?.date_time) }}
                    </p>
                </div>
                <div class="col-span-6">
                    <p class="mb-1 text-xs font-medium text-neutral-500">
                        Other Agencies
                    </p>
                    <ol class="pl-5 mb-1 text-sm font-bold">
                        <li class="list-decimal" v-for="i in item?.agencies_formatted" :key="i">
                            {{ i.name }}
                        </li>
                    </ol>
                </div>
            </div>
            <div class="col-span-6">
                <p class="mb-1 text-xs font-medium text-neutral-500">
                    Recommendations
                </p>
                <ul class="pl-5 mb-1 text-sm font-bold">
                    <li class="list-disc" v-for="recommendation in recommendations" :key="recommendation">
                        {{ recommendation }}
                    </li>
                </ul>
                <p v-if="recommendations?.length === 0" class="w-full text-xs font-medium text-center text-gray-400">No Recommendations</p>
            </div>
        </div>


        <!-- Create and Edit Mode -->
        <div v-if="isEdit || isCreate" class="grid grid-cols-12 gap-6" :class="!isEdit && !isCreate ? 'cursor-not-allowed' : ''">
            <div class="col-span-12 lg:col-span-2 md:col-span-4">
                <text-input
                    type="number"
                    label="Conference No."
                    placeholder="Conference No."
                    name="conference_number"
                    id="conference_number"
                    :disabled="true" 
                    v-model="form.case_conference_number"
                />
            </div>
            <div class="col-span-12 lg:col-span-3 md:col-span-4" :class="!isEdit && !isCreate ? 'pointer-events-none' : ''">
                <multi-select
                    :options="socialWorkers"
                    label="Social Worker"
                    id="social_worker"
                    name="social_worker"
                    placeholder="Select.."
                    searchable
                    required
                    v-model="form.social_worker_id"
                    :error="errors?.social_worker_id"
                />
            </div>
            <div class="col-span-12 lg:col-span-3 md:col-span-4" :class="!isEdit ? 'pointer-events-none' : ''">
                <date-picker
                    id="date_and_time"
                    name="date_and_time"
                    label="Date and Time"
                    :key="form.date_time"
                    required
                    :enableTimePicker="true"
                    :timePickerInline="true"
                    v-model="form.date_time"
                    :error="errors?.date_time"
                />
            </div>
            <div class="lg:col-span-6 col-span-12 border lg:rounded-2xl rounded-lg border-neutral-100 flex flex-col min-h-[250px]">
                <div class="flex items-center justify-between p-6 border-b border-neutral-100">
                    <p class="font-semibold text-gray-900">CPU Invitees
                        <!-- <span class="text-red-600">*</span> -->
                    </p>
                    <button-component
                        :class="!isEdit ? 'pointer-events-none' : ''"
                        size="small"
                        customClass="quaternary"
                        :disabled="!allowToFill"
                        @click="showCPUInviteesModal = true"
                    >
                        Add
                    </button-component>
                </div>
                <p
                    v-if="errors"
                    class="text-[10px] mt-1 text-red-600 text-center"
                    id="email-error"
                >
                    {{ errors?.invitees }}
                </p>

                <template v-for="(i, index) in selectedInvitees" :key="index">
                    <div class="flex items-center justify-between px-6 py-3 odd:bg-gray-50">
                        <p class="pr-4 text-sm font-medium text-gray-500">{{ i.label }}</p>
                        <div class="flex space-x-3 shrink-0">
                            <button :class="!isEdit ? 'cursor-not-allowed' : ''">
                                <XMarkIcon
                                    :class="!isEdit ? 'pointer-events-none' : ''"
                                    class="w-5 h-5 text-gray-900 transition hover:text-red-500"
                                    aria-hidden="true"
                                    @click="removeInvitee(i.id, index)"
                                />
                            </button>
                        </div>
                    </div>
                </template>
    
                <!-- else -->
                <div v-if="selectedInvitees?.length == 0" class="flex items-center justify-between h-full px-6 py-3">
                    <p class="w-full text-xs font-medium text-center text-gray-400">No Selected</p>
                </div>
            </div>
            <div class="lg:col-span-6 col-span-12 border lg:rounded-2xl rounded-lg border-neutral-100 flex flex-col min-h-[250px]">
                <div class="flex items-center justify-between p-6 border-b border-neutral-100">
                    <p class="font-semibold text-gray-900">Other Agencies
                        <!-- <span class="text-red-600">*</span> -->
                    </p>
                    <button-component
                        :class="!isEdit ? 'pointer-events-none' : ''"
                        size="small"
                        customClass="quaternary"
                        :disabled="!allowToFill"
                        @click="showOtherAgenciesModal = true"
                    >
                        Add
                    </button-component>
                </div>
                <p
                    v-if="errors"
                    class="text-[10px] mt-1 text-red-600 text-center"
                    id="email-error"
                >
                    {{ errors?.agencies }}
                </p>
                <template v-for="(i, index) in selectedAgencies" :key="index">
                    <div class="flex items-center justify-between px-6 py-3 odd:bg-gray-50">
                        <p class="pr-4 text-sm font-medium text-gray-500">{{ i.name }} - {{ getAgencyName(i.agency_id) }}</p>
                        <div class="flex space-x-3 shrink-0">
                            <button :class="!isEdit ? 'cursor-not-allowed' : ''">
                                <PencilSquareIcon 
                                    :class="!isEdit ? 'pointer-events-none' : ''"
                                    class="w-5 h-5 text-gray-900 transition hover:text-secondary-500"
                                    aria-hidden="true"
                                    @click="editAgency(i, index)"
                                />
                            </button>
                            <button :class="!isEdit ? 'cursor-not-allowed' : ''">
                                <XMarkIcon
                                    :class="!isEdit ? 'pointer-events-none' : ''"
                                    class="w-5 h-5 text-gray-900 transition hover:text-red-500"
                                    aria-hidden="true"
                                    @click="removeAgency(index)"
                                />
                            </button>
                        </div>
                    </div>
                </template>
    
                <!-- else -->
                <div v-if="selectedAgencies?.length == 0" class="flex items-center justify-between h-full px-6 py-3">
                    <p class="w-full text-xs font-medium text-center text-gray-400">No Selected</p>
                </div>
            </div>
            <div v-if="recommendations" class="col-span-12 p-6 border rounded-2xl border-neutral-100">
                <p class="mb-3 font-semibold text-gray-900">Recommendations</p>
                <ul class="flex flex-col pl-5 space-y-4">
                    <li class="text-sm font-medium text-gray-500 list-disc" v-for="recommendation in recommendations" :key="recommendation">
                        {{ recommendation }}
                    </li>
                    <!-- else -->
                    <li v-if="recommendations?.length === 0 " class="w-full text-xs font-medium text-center text-gray-400">No Recommendations</li>
                </ul>
            </div>
    
            <!-- CPU Invitees Modal -->
            <content-modal
                :show="showCPUInviteesModal" 
                title="Select CPU Invitees"
            >
                <div class="flex flex-col space-y-2">
                    <template v-for="(item, index) in invitees" :key="index">
                        <checkbox-input
                            :checked="inviteeIds.includes(item.id)"
                            :id="'invitee' + index"
                            :name="'invitee' + index"
                            :label="item.label"
                            @update:modelValue="(value) => updateInvitees(item.id, index, value)"
                        />
                    </template>
                </div>
    
                <template #button>
                    <button-component  
                        btnWidth="quinary"
                        @click="showCPUInviteesModal = false"
                    >
                        Cancel
                    </button-component >
                    <button-component 
                        customClass="primary" 
                        @click="setInvitees"
                    >
                        Save Changes
                    </button-component>
                </template>
            </content-modal>
    
            <!-- Other Agency Modal -->
            <content-modal
                :show="showOtherAgenciesModal" 
                title="Select Other Agencies"
                :showInformation="true"
                showInformationText="Go to setting to add another “other agency”"
            >
                <div class="flex flex-col space-y-6">
                    <multi-select
                        :options="agencies"
                        label="Other Agencies"
                        id="other_agencies"
                        name="other_agencies"
                        placeholder="Select.."
                        searchable
                        v-model="agencyForm.agency_id"
                        required
                        :error="errorMessage.agency_id"
                    />
    
                    <text-input
                        label="Name"
                        placeholder="Name"
                        name="name"
                        id="name"
                        v-model="agencyForm.name"
                        required
                        :error="errorMessage.name"
                    />
                </div>
    
                <template #button>
                    <button-component  
                        btnWidth="quinary"
                        @click="showOtherAgenciesModal = false"
                    >
                        Cancel
                    </button-component >
                    <button-component 
                        customClass="primary" 
                        @click="setAgencies"
                    >
                        Add
                    </button-component>
                </template>
            </content-modal>

            <!-- Record Save -->
            <success-modal
                noBtn
                :show="showSuccessSave"
                action-text="Close"
                title="Record Save"
                content="Case Conference has been updated" 
            />
    
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import TextInput from "@/components/inputs/TextInput.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import { PencilSquareIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import ContentModal from "../Component/ContentModal.vue";
import DateFormatter from "@/helpers/DateFormatter";
import SuccessModal from '@/components/modals/SuccessModal.vue';
import AgencyRules from "@/classes/Cases/CaseConference/Rules/AgencyRules";
import useVuelidate from "@vuelidate/core";
import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";

const props = defineProps({
    createMode: {
        type: Boolean,
        default: false
    },
    editMode: {
        type: Boolean,
        default: false
    },
    form: {
        type: Object,
        required: true
    },
    isEdit: {
        type: Boolean,
        default: false
    },
    isCreate: {
        type: Boolean,
        default: true
    },
    errors: {
        type: Object,
        required: true
    },
    canRefresh: {
        type: Boolean,
        default: false,
    },
    agencies: {
        type: Object,
        required: true
    },
    invitees: {
        type: Object,
        required: true
    },
    socialWorkers: {
        type: Object,
        required: true
    },
    recommendations: {
        type: Object,
        required: true
    },
    item: {
        type: Object,
        required: true
    },
});

const emit = defineEmits(['setInvitees', 'setAgencies', 'setView', 'update:form']);

const form = ref(props.form)

const showCPUInviteesModal = ref<boolean>(false);
const showOtherAgenciesModal = ref<boolean>(false);

const showSuccessSave = ref(false);

const inviteeIds = ref<any[]>(props.inviteeIds ?? [])
console.log(inviteeIds.value);
const selectedInvitees = ref([])
const selectedAgencies = ref([])

const isEdit = computed(() => props.isEdit)
const isEditAgency = ref(false)
const editAgencyIndex = ref<any>(null)

const recommendations = ref(props.recommendations)

const agencyForm = ref({
    agency_id: null,
    name: null
})

const { createRule } = AgencyRules;
const errorMessage = ref({});

// Sample
const allowToFill = ref<boolean>(true);

const updateInvitees = (id: number, index: number, isChecked: boolean): void => {
	if (isChecked) {
		inviteeIds.value.push(id)
	}
	if (!isChecked) {
        inviteeIds.value = inviteeIds.value.filter((item: number) => item != id);
	}
};

const setInvitees = () => {
    selectedInvitees.value = props.invitees.filter(x => inviteeIds.value.includes(x.id))
    showCPUInviteesModal.value = false
    emit('setInvitees', inviteeIds.value)
}

const removeInvitee = (id: number, index: number) => {
    const indx = inviteeIds.value.indexOf(id)

    if (indx !== -1) {
        inviteeIds.value.splice(indx, 1)
        emit('setInvitees', inviteeIds.value)
    }
    selectedInvitees.value.splice(index, 1)
}

/**
 * Validate the form using Vuelidator
 */
 const validateForm = async () =>{
	const vuelidate = useVuelidate(createRule({...form.value}), agencyForm.value)
	const result = await vuelidate.value.$validate();

    if (result) {
        return null;
    } else {
        errorMessage.value = ErrorValidation.getErrors(vuelidate.value);
        return errorMessage.value;
    }
}

const setAgencies = async () => {
    // Set validation rule for Online and Offline
    const validate = await validateForm();
    if (validate) {
        return;
    }

    if (!isEditAgency.value) {
        selectedAgencies.value.push({...agencyForm.value})
        agencyForm.value.agency_id = null
        agencyForm.value.name = null
    } else {
        selectedAgencies.value[editAgencyIndex.value].agency_id = agencyForm.value.agency_id
        selectedAgencies.value[editAgencyIndex.value].name = agencyForm.value.name

        agencyForm.value.agency_id = null
        agencyForm.value.name = null
    }

    emit('setAgencies', selectedAgencies.value)
    isEditAgency.value = false
    showOtherAgenciesModal.value = false
}

const removeAgency = (index: number) => {
    selectedAgencies.value.splice(index, 1)
}

const editAgency = (item: any, index: number) => {
    isEditAgency.value = true
    editAgencyIndex.value = index
    showOtherAgenciesModal.value = true
    agencyForm.value.agency_id = item.agency_id
    agencyForm.value.name = item.name  
}

const getAgencyName = (id:any) => {
    const agency = props.agencies.find((x:any) => x.id === id);
    return agency ? agency.label : null;
};

const initItems = () => {
    inviteeIds.value = props.item.invitees
    
    selectedInvitees.value = props.invitees.filter((x:any) => inviteeIds.value?.includes(x.id))
    
    selectedAgencies.value = props.item.agencies
}

watch(
    () => props.item,
    (value) => {
        if (value) {
            inviteeIds.value = value.invitees
            selectedInvitees.value = props.invitees.filter(x => inviteeIds.value.includes(x.id))

            selectedAgencies.value = value.agencies
        }
    }
)

watch(
    form.value,
    () => {
        emit('update:form', form.value);
    }
);

onMounted(() => initItems());
</script>