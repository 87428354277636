import PatientOfflineProcessor from "@/classes/Patients/Offline/processor/PatientOfflineProcessor";
import { Perpetrator } from "./Interface/PerpetratorInterface";
import PerpetratorProcessor from "./Processor/PerpetratorProcessor";
import SqliteProcessor from "@/processors/SqliteProcessor";

const sqlite = new SqliteProcessor;
class OfflineService implements Perpetrator{
    async getPatient(patient: number, case_id: number): Promise<any> {
        await sqlite.connect();
        const patientDetail = await PatientOfflineProcessor.getFullName(patient);
        return { 
            data: {
                patient: patientDetail,
            }
         };
    }

    async index(patient: number, case_id: number, payload: object): Promise<any> {
        return await PerpetratorProcessor.index(patient, case_id, payload);
    }

    async link(patient: number, case_id: number, payload: any): Promise<any> {
        const item = await PerpetratorProcessor.linkPerpetrator(case_id, payload);
        return {
            data: {
                item: item,
                message: 'Perpetrator successfully linked!' 
            }
        }
    }

    async create(patient: number, case_id: number, payload: object): Promise<any> {
        const data = await PerpetratorProcessor.create(patient);
        return {
            data: data
        };
    }

    async store(patient: number, case_id: number, payload: object): Promise<any> {
        const data = await PerpetratorProcessor.store(patient, case_id, payload);
        return {
            data: data
        };
    }

    async edit(patient: number, case_id: number, perpetrator_id: number, payload: object): Promise<any> {
        const data = await PerpetratorProcessor.edit(patient, perpetrator_id);
        return {
            data: data,
        }
    }

    async update(patient: number, case_id: number, perpetrator_id: number, payload: object): Promise<any> {
        const item = await PerpetratorProcessor.update(patient, case_id, perpetrator_id, payload);
        return {
            data: {
                item: item,
                message: 'Perpetrator successfully saved!' 
            }
        }
    }

    async view(peretrator_id: number, payload: object): Promise<any> {
        return await PerpetratorProcessor.view(peretrator_id, payload);
    }

    async updateProfile(perpetrator_id: number, payload: object): Promise<any> {
        const item = await PerpetratorProcessor.updateProfile(perpetrator_id, payload);
        return {
            data: {
                item: item,
                message: 'Perpetrator successfully saved!'
            },
            
        }
    }

    async syncToOffline(patient: number, case_id: number, payload: object): Promise<any> {
        return await PerpetratorProcessor.syncToOffline(patient, case_id, payload); 
    }

    async delete(patient: number, case_id: number, perpetrator_id: number, payload: object): Promise<any> {
        const item = await PerpetratorProcessor.unlinkPerpetrator(case_id, perpetrator_id);
        return {
            data: {
                item: item,
                message: 'Perpetrator successfully deleted!'
            }
        };
    }

    async search(payload: object): Promise<any> {
        return await PerpetratorProcessor.search(payload);
    }

    async cases(peretrator_id: number, payload:  object): Promise<any> {
        return await PerpetratorProcessor.cases(peretrator_id, payload);
    }

    async editProfile(perpetrator_id: number, payload: object): Promise<any> {
        const data = await PerpetratorProcessor.edit(null, perpetrator_id, payload);
        return {
            data: data,
        };
    }
}

export default new OfflineService();