import { CompanionCreate } from "../Interface/CompanionInterface";
import Relationships from "@/classes/Forms/Relationships";
const relationship = new Relationships();
import FormOptions from "@/classes/Forms/FormOptions";
import DateFormatter from "@/helpers/DateFormatter";
import { defaultCreateForm, defaultUpdateForm } from "@/classes/General/GeneralOfflineForms";


const options = new FormOptions();
 class CaseCompanionForm  {
   
  async viewForm(item: any) { 
     const birthdate = item.birthdate ? relationship.dateFormatFromIso(item.birthdate) : null;
     const relationshipDetail = await relationship.companionRelationship(item);
     return { 
       id: item.id,
       gender_meta: relationship.gender(item),
       name: `${item.first_name  } ${item.last_name }`,
       birthdate: item.birthdate,
       computed_age: birthdate ? relationship.computeAge(birthdate) : relationship.computeApproximateAge(item.approximate_age_year, item.approximate_age_month), //change it when the dropdown options is available
       relationship: relationshipDetail,
       contacts: item.contacts
     } 
   }


   updateForm(item: any){ 
       return {
         id: item.id,
         first_name: item.first_name, 
         last_name: item.last_name, 
         middle_name: item.middle_name ?? null,  
         other_relationship: item.other_relationship ?? null, 
         companion_relationship_id: item.companion_relationship_id,
         gender: item.gender,  
         approximate_age_month: item.approximate_age_month,
         approximate_age_year: item.approximate_age_year,
         birthday_classification: item.birthday_classification,
         contacts: item.contacts,
         birthdate: item.birthdate ?? null,
         encoded_by:   item.encoded_by,

         //sync tagging
         ...defaultUpdateForm(), 
       }
   }


   createForm() {
       return {  
           cases_id:  null,
           first_name:  null,
           middle_name: null,
           last_name: null,
           gender: null,
           birthday_classification: null,
           birthdate: null,
           approximate_age_year: null,
           approximate_age_month: null,
           companion_relationship_id: null,
           other_relationship: null,
           contacts: null,

            //syncing tagging
           ...defaultCreateForm(), 
       }
   }

   syncToOffline(payload: any) {
    const item = this.updateForm(payload);

    return item;
}
  
}
export default new CaseCompanionForm();