import Syncable from "@/extenders/models/Syncable";
import useFormatFormStructure from "@/helpers/Offlinehelpers/useFormatFormStructure";
import Storage from "@/helpers/storage";
import SqliteProcessor from "@/processors/SqliteProcessor";
import IncestForm from "../Formatter/IncestForm";
import FormOptions from "@/classes/Forms/FormOptions";
import PatientOfflineProcessor from "@/classes/Patients/Offline/processor/PatientOfflineProcessor";
import parseArray from "@/helpers/Offlinehelpers/parseArray";
const sqlite = new SqliteProcessor();

const options = new FormOptions();
const storage = new Storage();
const table = `intake_incest_cases`;
class OfflineProcessor extends Syncable{ 

    /**
     * Show data from storage
     * @param patient 
     * @param patientCase 
     * @returns 
     */
    async show(patient:number, patientCase: number){
        await sqlite.connect(); 
        const patientQuery = `SELECT * FROM patients WHERE id=${patient}`;
        const query = `SELECT * FROM ${table} WHERE cases_id = ${patientCase}`; 
        const incest = await sqlite.db.select(query);
        const patientItem = await sqlite.db.select(patientQuery);
        
        let incestViewForm = {};
        if(incest.length > 0){
            incestViewForm = {
                abused_living_arrangement: parseArray(incest[0].abused_living_arrangement),
                present_living_arrangement: parseArray(incest[0].present_living_arrangement),
                same_bed_with: parseArray(incest[0].same_bed_with),
                same_room_with: parseArray(incest[0].same_room_with)
            }
        }
     
       const incestView = {
            ...incest[0],
            ...incestViewForm
       }
       
       const sleepingArrangement = await options.sleepingArrangements();
       const sleepingArrangementWith = await options.sleepipngArrangmentWith();
       const livingArrangements = await options.livingArrangements();
       const patientDetail = await PatientOfflineProcessor.getFullName(patient);
        return {
            'data': {
                'item': incest.length > 0 ? incestView : [],
                'sleepingArrangement': sleepingArrangement,
                'sleepingArrangementWith': sleepingArrangementWith,
                'livingArrangements': livingArrangements,
                'patient': patientDetail
            }, 
        }; 
    }

    /**
     * Update or Create item from storage
     * @param incestId 
     * @param patient 
     * @param patientCase 
     * @param payload 
     * @returns 
     */
    async updateCreate(incestId:number, patient: number, patientCase: number, payload: any){
        await sqlite.connect();
        this.table = table; 

        const encoded_by = JSON.parse(storage.get('user'));
        let structuredForm;
        if(incestId){
            structuredForm = useFormatFormStructure(payload,IncestForm.updateForm(payload));
        }else{
            structuredForm = useFormatFormStructure(payload,IncestForm.createForm());
            structuredForm.cases_id = patientCase;
            structuredForm.encoded_by = encoded_by.id;
        }
        
        //form values
        structuredForm.abused_living_arrangement = payload.abused_living_arrangement;
        structuredForm.sleeping_arrangement = payload.sleeping_arrangement;
        structuredForm.present_living_arrangement = payload.present_living_arrangement;
        structuredForm.same_bed_with = payload.same_bed_with;
        structuredForm.same_room_with = payload.same_room_with;
        
        const incest = await this.sync(sqlite.db, [structuredForm]);
        const query = `SELECT * FROM ${table} WHERE cases_id = ${patientCase}`; 
 
        return {
            data: {
                data: {
                    item: incest
                }
            }
        }
    }

    async syncToOffline(case_id: number, payload: any) {
        await sqlite.connect();
        this.table = table; 

        const encoded_by = JSON.parse(storage.get('user'));
        const structuredForm = useFormatFormStructure(payload,IncestForm.updateForm(payload));
        structuredForm.cases_id = case_id;
        structuredForm.encoded_by = encoded_by.id;
        structuredForm.abused_living_arrangement = payload.abused_living_arrangement;
        structuredForm.sleeping_arrangement = payload.sleeping_arrangement;
        structuredForm.present_living_arrangement = payload.present_living_arrangement;
        structuredForm.same_bed_with = payload.same_bed_with;
        structuredForm.same_room_with = payload.same_room_with;
        structuredForm.is_created_offline = false;
        structuredForm.is_updated_offline = false;
        structuredForm.is_deleted_offline = false;
        structuredForm.is_sync = false;
        structuredForm.is_sync_failed = false;
        structuredForm.synching_remarks = false;
        
        await this.sync(sqlite.db, [structuredForm]);
    }
}
export default new OfflineProcessor();