<template>
    <base-modal
        :customClass="customClass"
        :show="show"
        :whiteBg="whiteBg"
    >
        <div class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto mb-2 rounded-full bg-primary-300">
            <!-- <img src="/assets/icon/ic-lock-circle.svg" class="object-contain w-6 h-6" > -->
            <lock-circle-icon class="object-contain w-6 h-6" />
        </div>
        <DialogTitle
            as="p"
            class="text-xl font-bold text-center text-neutral-900 focus-visible:outline-none"
            tabindex="0"
        >
            {{ title }}
        </DialogTitle>
        <div class="text-sm leading-5 text-center text-gray-500">
            <p v-if="content">{{ content }}</p>
            <slot name="content" />
            <form action="#">
                <div class="w-full mx-auto mb-6">
                    <div class="flex justify-center space-x-3">
                        <div class="relative flex justify-center">
                            <ion-input
                            id="otp1"
                            type="password"
                            v-model="otpNum.num1"
                            ref="otp1" 
                            @keyup="moveFocus($event, $refs.otp2.$el, '')" 
                            placeholder="○" 
                            class="otp pin-input" 
                            maxlength="1" 
                            inputmode="numeric" 
                            autocomplete="off">
                            </ion-input>
                        </div>
                        <div class="relative flex justify-center">
                            <ion-input 
                            type="password"
                            v-model="otpNum.num2"
                            ref="otp2"
                            @keyup="moveFocus($event, $refs.otp3.$el, $refs.otp1.$el)" 
                            placeholder="○" 
                            class="otp pin-input" 
                            maxlength="1" 
                            inputmode="numeric" 
                            autocomplete="off">
                            </ion-input>
                            <span class="underline"></span>
                        </div>
                        <div class="relative flex justify-center">
                            <ion-input 
                            type="password"
                            v-model="otpNum.num3"
                            ref="otp3"
                            @keyup="moveFocus($event, $refs.otp4.$el, $refs.otp2.$el)" 
                            placeholder="○" 
                            class="otp pin-input" 
                            maxlength="1" 
                            inputmode="numeric" 
                            autocomplete="off">
                            </ion-input>
                            <span class="underline"></span>
                        </div>
                        <div class="relative flex justify-center">
                            <ion-input 
                            type="password"
                            v-model="otpNum.num4" 
                            ref="otp4"
                            @keyup="moveFocus($event, $refs.otp5.$el, $refs.otp3.$el)"
                            placeholder="○" 
                            class="otp pin-input" 
                            maxlength="1" 
                            inputmode="numeric" 
                            autocomplete="off">
                            </ion-input>
                            <span class="underline"></span>
                        </div>
                        <div class="relative flex justify-center">
                            <ion-input 
                            type="password"
                            v-model="otpNum.num5" 
                            ref="otp5"
                            @keyup="moveFocus($event, $refs.otp6.$el, $refs.otp4.$el)"
                            placeholder="○" 
                            class="otp pin-input" 
                            maxlength="1" 
                            inputmode="numeric" 
                            autocomplete="off">
                            </ion-input>
                            <span class="underline"></span>
                        </div>
                        <div class="relative flex justify-center">
                            <ion-input 
                            type="password"
                            v-model="otpNum.num6" 
                            ref="otp6"
                            @keyup="moveFocus($event, '', $refs.otp5.$el)"
                            placeholder="○" 
                            class="otp pin-input" 
                            maxlength="1" 
                            inputmode="numeric" 
                            autocomplete="off">
                            </ion-input>
                            <span class="underline"></span>
                        </div>
                    </div>
                </div>
            </form>

            <p v-if="error" class="text-[10px] mt-1" :class="error ? 'text-red-600' : 'text-gray-800'" id="email-error">
                Pin Code dose not match
            </p>
        </div>

        <div class="flex justify-between space-x-3 mt-9">
            <button-component
                v-if="isNetworkAvailable"
                size="large"
                btnWidth="quinary rounded-lg w-full"
                @click="$emit('cancel')"
            >{{ cancelText }}</button-component>

            <button-component
                size="large"
                btnWidth="primary rounded-lg w-full"
                @click="confirm"
            >{{ actionText }}</button-component>
        </div>
    </base-modal>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";

import {
    IonInput
} from '@ionic/vue';

import {
  DialogTitle,
} from '@headlessui/vue';
import BaseModal from '@/components/modals/BaseModal.vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import SixInput from '@/components/forms/SixInput.vue';
import PinCodeProcessor from '@/helpers/hashPin';
import lockCircleIcon from "@/components/icons/lockCircleIcon.vue";
import { useRecordStatusChecker } from "@/store/recordStatusChecker";

const emit = defineEmits(['confirm']);

const props = defineProps({
    show: {
        type: Boolean
    },
    title: {
        type: String,
    },
    content: {
        type: String,
    },
    actionText: {
        type: String,
    },
    cancelText: {
        type: String,
        default: 'Logout'
    },
    customClass: {
        type: String,
        default: 'py-6 px-6 w-[480px]'
    },
    whiteBg: {
        type: Boolean,
        default: false
    },
    validate: {
        type: Boolean,
        default: true
    },
    pinCode: {
        type: String,
        default: '',
    },
    hasPinCode: {
        type: Boolean,
        default: false
    },
    isNetworkAvailable: {
        type: Boolean,
        default: true,
    }
})

const otpNum = [
    { num1: ref(null) },
    { num2: ref(null) },
    { num3: ref(null) },
    { num4: ref(null) },
    { num5: ref(null) },
    { num6: ref(null) }
];

const status = ref<boolean>(false)
const error = ref<boolean>(false)
const recordStatusChecker = useRecordStatusChecker();

const moveFocus = (event:any , nextElement:any, previousElement:any) => {
    if (event.keyCode == 8 && previousElement) {
        previousElement.setFocus();
    } else if (event.target.value && event.keyCode >= 48 && event.keyCode <= 57) {
        if (nextElement) {
            nextElement.setFocus();
        }
    }
};

let otp = '';
const formatOTP = ():string => {
    // otpNum.forEach((value:any, key:number) => {
    //     otp += otpNum[`num${key + 1}`];
    // });
    return `${(otpNum.num1||"")}${(otpNum.num2||"")}${(otpNum.num3||"")}${(otpNum.num4||"")}${(otpNum.num5||"")}${(otpNum.num6||"")}`;
    // return otp;
}

const setPinCode = (): void => {  
    props.pinCode.split('').forEach((value: any, key: number) => {
        otpNum[`num${key + 1}`] = value;
    }); 
}

const clearPin  = (): void => { 
    otp= "";
    otpNum.num1 = null;    
    otpNum.num2 = null;    
    otpNum.num3 = null;    
    otpNum.num4 = null;    
    otpNum.num5 = null;  
    otpNum.num6 = null;  
}

const confirm = (): void => {
    if (props.validate) {
        status.value = PinCodeProcessor.validate(formatOTP());
        console.log(status.value);
        error.value = !status.value;
        if (status.value) {
            resetModals();
            emit('confirm',  status.value);
        }
    }

    emit('confirm', false); 

    //clear Pin when modal appear
    if(status.value){
        clearPin();
    }  

}

const resetModals = () => {
    const modals = document.querySelectorAll('#headlessui-portal-root > div');
    // Remove all child divs except the last one
    const arrayModals = Array.from(modals);
    for (const index in arrayModals) {
        document.getElementById('app')?.removeAttribute('aria-hidden');
        document.getElementById('app')?.removeAttribute('inert');
        arrayModals[index].remove();
    }
}

onMounted(() => {
    if (props.hasPinCode) {  
        setPinCode()
    }

    if (!props.isNetworkAvailable) {
        if (!recordStatusChecker.isDownloadingResources) {
            setTimeout(() => {
                document.querySelector('form #otp1')?.setFocus();
                const modals = document.querySelectorAll('#headlessui-portal-root > div');
                // Remove all child divs except the last one
                const arrayModals = Array.from(modals);
                for (const index in arrayModals) {
                    if (parseInt(index) > 0) {
                        document.getElementById('app')?.removeAttribute('aria-hidden');
                        document.getElementById('app')?.removeAttribute('inert');
                        arrayModals[index].remove();
                    }
                }
            }, 1000);
        }
    }
});


</script>