import { defaultCreateForm, defaultUpdateForm } from "@/classes/General/GeneralOfflineForms";

class PersonalInforForm{ 

    createForm(){
        return { 
            legal_status: null,
            housing_classification_id: null,
            socio_economic_status_id: null,
            no_children_in_family: null,
            no_household_members: null,
            no_family_in_household: null,
            date_initiated: null,
            ...defaultCreateForm()
        }
    }

    updateForm(item: any){
        return { 
            id: item.id,
            legal_status: item.legal_status,
            housing_classification_id: item.housing_classification_id,
            socio_economic_status_id: item.socio_economic_status_id,
            no_children_in_family: item.no_children_in_family,
            no_household_members: item.no_household_members,
            no_family_in_household: item.no_family_in_household,
            date_initiated: item.date_initiated,
            ...defaultUpdateForm()
        }
    }
}
export default new PersonalInforForm();