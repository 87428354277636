import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "../../defaultMigration";


export const intake_personal_information: SchemaTableOptions = {
   name:<string> 'intake_personal_information',
   schema:<Array<SchemaColumnOptions>> [
    { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
    { column: 'cases_id', value: 'INTEGER UNSIGNED'},
    { column: 'legal_status', value: 'TINYINT UNSIGNED'},
    { column: 'housing_classification_id', value: 'TINYINT UNSIGNED'},
    { column: 'socio_economic_status_id', value: 'TINYINT UNSIGNED'},
    { column: 'no_children_in_family', value: 'TINYINT UNSIGNED'},
    { column: 'no_household_members', value: 'TINYINT UNSIGNED'},
    { column: 'no_family_in_household', value: 'TINYINT UNSIGNED'},
    { column: 'encoded_by', value: 'TINYINT UNSIGNED'},
    { column: 'date_initiated', value: 'DATETIME DEFAULT NULL'},
    ...defaultMigration
   ]
}
