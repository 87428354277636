<template>
	<user-layout
		hasBack
		:backLink="`/patient/${patientId}/cases/${caseId}/edit${offlineParams ? '?offline=true' : ''}`"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>
			<div class="flex md:flex-row flex-col items-center md:space-x-3 md:space-y-0 space-y-3">
				<a
					@click="
						router.replace(
							`/patient/${patientId}/cases/${caseId}/edit${offlineParams ? '?offline=true' : ''}`
						)
					"
					class="md:w-auto w-full md:mt-0 mt-6">
					<button-component btnWidth="quinary" customClass="quinary md:w-auto w-full">Cancel</button-component>
				</a>
				<button-component @click="validateForm()" customClass="primary md:w-auto w-full md:mt-0 mt-6">
					<span>Save Changes</span>
				</button-component>
			</div>
		</template>
		<p class="mt-6 md:text-2xl text-lg font-medium text-neutral-600">Patient's Name:
			<span class="md:inline-block block font-semibold text-neutral-900">{{ `${form.last_name}, ${form.first_name}` }}</span>
		</p>
		<div class="w-full lg:p-6 p-4 mx-auto my-6 border border-gray-200 rounded">
			<div class="flex items-center justify-between">
				<section-title title="Immediate Safety Assessment	" />
			</div>
			<form class="grid grid-cols-6 md:gap-6 gap-3 mt-6" v-if="loaded">
				<div class="col-span-6 border-t border-gray-200"></div>

				<div class="md:col-span-3 col-span-6">
					<text-input
						:disabled="true"
						type="text"
						label="Case Number"
						placeholder="Case Number"
						name="case_number"
						id="case_number"
						v-model="form.case_number" 
					/>
				</div>
				<div class="md:col-span-3 col-span-6">
					<date-picker
						:disabled="true"
						id="assessment_date"
						name="date_initiated"
						label="Date Initiated"
						v-model="form.assessment_date"
						:error="errors?.assessment_date"
						:max-date="new Date()" />
				</div>
				<div class="md:col-span-2 col-span-6">
					<text-input
						type="text"
						label="Last Name"
						placeholder="Last name"
						name="last_name"
						id="last_name"
						:disabled="true"
						required
						v-model="form.last_name"
						:error="errors?.last_name" />
				</div>
				<div class="md:col-span-2 col-span-6">
					<text-input
						:disabled="true"
						type="text"
						label="First Name"
						placeholder="First name"
						name="first_name"
						id="first_name"
						required
						v-model="form.first_name"
						:error="errors?.first_name" />
				</div>
				<div class="md:col-span-2 col-span-6">
					<text-input
						:disabled="true"
						type="text"
						label="Middle Name"
						placeholder="Middle name"
						name="middle_name"
						id="middle_name"
						v-model="form.middle_name"
						:error="errors?.middle_name" />
				</div>
				<div class="col-span-6">
					<checkbox-input
						:checked="form.is_assessment_patient_sibling"
						id="is_assessment_patient_sibling"
						name="assessment_sibling"
						label="Assessment is for patient's sibling"
						v-model="form.is_assessment_patient_sibling" 
					/>
				</div>
				<div class="col-span-6">
					<text-input
						textarea
						label="Appointments"
						placeholder="Appointments"
						name="appointments"
						id="appointment"
						:textAreaRows="1"
						v-model="form.appointment"
						:error="errors?.appointment" />
				</div>
				<div class="grid grid-cols-6 col-span-6 gap-6">
					<div class="col-span-6">
						<p class="text-sm font-bold">Safety Factor</p>
					</div>
					<template v-for="(question, index) in questions" :key="question">
						<div class="md:col-span-3 col-span-6">
							<p class="text-xs font-medium text-neutral-600">
								{{ question.description }}
							</p>
						</div>
						<div class="md:col-span-3 col-span-6 md:mt-0 -mt-3">
							<div class="flex items-center space-x-6">
								<radio-group
									:options="safetyFactorOptions"
									:name="`caretaker_${index}`"
									:id="``"
									v-model="form.questions[index]['status']" 
								/>
							</div>
						</div>
						<div class="col-span-6">
							<ck-editor
								label="Information supporting safety factor:"
								v-model="form.questions[index]['supporting_information']"
							></ck-editor> 
						</div>
					</template>
				</div>
				<div class="col-span-6">
					<ck-editor
						label="Brief Family Background"
						v-model="form.brief_family_background"
					></ck-editor> 
				</div>
				<div class="col-span-6">
					<ck-editor
						label="Family Background"
						v-model="form.family_background"
					></ck-editor> 
				</div>
				<div class="col-span-6">
					<p class="text-sm font-bold">Safety Decision Plan</p>
				</div>
				<div class="col-span-6">
					<p class="text-sm font-bold">Risk Codes</p>
				</div>
				<div class="md:col-span-2 col-span-6">
					<text-input
						:disabled="true"
						type="number"
						label="Total number of “Yes”"
						placeholder="#"
						name="number_yes"
						id="number_yes"
						v-model="form.yes_count"
						:error="errors?.yes_count" />
				</div>
				<div class="md:col-span-2 col-span-6">
					<text-input
						:disabled="true"
						type="number"
						label="Total number of “No”"
						placeholder="#"
						name="number_no"
						id="number_no"
						v-model="form.no_count"
						:error="errors?.no_count" />
				</div>
				<div class="md:col-span-2 col-span-6">
					<text-input
						:disabled="true"
						type="number"
						label="Total number of “Unknown”"
						placeholder="#"
						name="number_unknown"
						id="number_unknown"
						v-model="form.unknown_count"
						:error="errors?.unknown_count" />
				</div>
                <div class="col-span-6" id="risk_code_id">
					<p class="text-sm font-bold" >Safety Decisions:</p>
				</div>
				<div class="col-span-6" v-for="riskCode in riskCodes" :key="riskCode">
					<label
						for="action_plan-1"
						class="relative flex items-center h-full py-3 pr-3 pl-3 border rounded cursor-pointer border-neutral-100 min-h-[98px]"
					>
                        <radio-group
                            :options="[{ id: riskCode.id, value: riskCode.id, label: '' }]"
                            :name="`caretaker_${index}`"
                            v-model="form.risk_code_id" 
						/>
						<p class="text-xs font-medium text-neutral-600">
							{{ riskCode.description }}
						</p>
					</label>
				</div>
				<p
					v-if="errors?.risk_code_id"
					class="text-[10px] mt-1"
					:class="errors?.risk_code_id ? 'text-red-600' : 'text-gray-800'"
					id="email-error">
					{{ errors?.risk_code_id }}
				</p>
				<div class="col-span-6">
					<ck-editor
						label="Immediate Safety Plan"
						v-model="form.immediate_safety_plan"
					></ck-editor>
				</div>
				<div class="col-span-6">
					<ck-editor
						label="Rationale of Overall Immediate Safety Assessment"
						v-model="form.overall_risk_assessment"
					></ck-editor>
				</div>
			</form>
		</div>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Save!"
			:content="successMessage" />
	</user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { useRouter, useRoute } from "vue-router"; 
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface"; 
import { errorResponse } from "@/helpers/errorMessage";
import { SafetyAssessmentStatus } from "@/views/enums/SafetyAssessmentStatus"; 
import CkEditor from '@/components/inputs/CKEditor.vue';
import pickBy from "lodash/pickBy"; 
import FormOptions from "@/classes/Forms/FormOptions";  
import { onIonViewWillEnter } from "@ionic/vue"; 

/******************************** For Offline Imports ***************************** */
import CaseImmediateService from "@/classes/Cases/CaseImmediateAssessment/CaseImmediateService";
import CaseSafetyAssessmentForm from "@/classes/Cases/CaseImmediateAssessment/Formatters/CaseSafetyAssessmentForm"; 
import { useNetworkStore } from "@/store/network";
import Rules from "@/classes/Cases/CaseImmediateAssessment/Validation/Rules";
import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
import useVuelidate from "@vuelidate/core";
import AssessmentOfflineProcessor from "@/classes/Cases/CaseImmediateAssessment/Processor/OfflineProcessor";

const { createRules } = Rules;
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
/********************************************************************************** */
/********************************************************************************** */

const router = useRouter();
const route = useRoute();
const loaded = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const assessmentId = ref<number | null>(route.params.assessment);
const questions = ref<renderSelect[]>([]);
const riskCodes = ref<renderSelect[]>([]);
const safetyFactorOptions = ref<any>([]);
const form = ref<any>(CaseSafetyAssessmentForm.createForm({}));
const errors = ref<object>({});
const successMessage = ref<string>("Safety Assessment has been updated!");
const offlineParams = ref<string>(route.query.offline); 

//validation
const validateForm = () => {
	const vuelidate = useVuelidate(createRules({...form.value}), form.value)
	const result = vuelidate.value.$validate();
	result
		.then((res) => {
			if(res) { 
				save()
			} else {
				errors.value = ErrorValidation.getErrors(vuelidate.value)
                errorResponse(errors.value);
			}
		})
}
const save = (): void => {
	errors.value = {};
	if(assessmentId.value){
		CaseImmediateService.update(
		patientId.value,
		caseId.value,
		assessmentId.value,
		isNetworkAvailable.value,
		{ ...form.value, ...{ isOfflineData: offlineParams.value } },
	)
		.then(async (response: object) => {
            await getData();
			showSuccess.value = true;
			successMessage.value = response.data.message;
		})
		.catch((error: object) => {
			errors.value = errorResponse(error.response?.data?.errors);
		});
	}else{
		CaseImmediateService.store(
		patientId.value,
		caseId.value, 
		isNetworkAvailable.value,
		{ ...form.value, ...{ isOfflineData: offlineParams.value } },
	)
		.then(async (response: object) => {
            await getData(response.data.data.id);
			showSuccess.value = true;
			successMessage.value = response.data.message;
		})
		.catch((error: object) => {
			errors.value = errorResponse(error.response?.data?.errors);
		});
	}
	
};

const redirectSuccess = async (): void => {
	router.replace(`/patient/${patientId.value}/cases/${caseId.value}/edit`);
	showSuccess.value = false;
};

const template = (items: any): void => {
	form.value.questions = questions.value.map((item: any) => {
		const newForm = items?.filter((question: any) => {
			if (question.question_id == item.id) {
				return {
					question_id: question.question_id,
					supporting_information: question.supporting_information || "",
					status: question.status,
				};
			}
		});
		return newForm.length > 0
			? newForm[0]
			: { question_id: item.id, supporting_information: null, status: SafetyAssessmentStatus.UNKNOWN };
	});
};

const renderCount = (data: [], type: number): number => { 
	return data.filter((item: object) => {
		if (item.status == type) {
			return item;
		}
	}).length;
};

const maxDate = () => {
	return new Date().toISOString().split("T")[0];
};

const date = ref(new Date());

// const checkIfAssessmentIsCreated = async () => { 	
//     if (assessmentId.value && !isNetworkAvailable.value) {
// 		const response = await CaseImmediateService.checIfCreated(assessmentId.value || 0);  
//         if (response.length > 0) {
//             // router.push(`/patient/${patientId.value}/cases/${caseId.value}/safety-assessment/${response[0].id}/view`);
//         }
//     }
// }

const getData = async (id= null): void => { 
    const setId = id || assessmentId.value;
	if (setId) { 
		await CaseImmediateService.edit(
			patientId.value,
			caseId.value,
			setId,
			isNetworkAvailable.value,
			pickBy({ isOfflineData: offlineParams.value ? true : false }),
		)
			.then(async (response: object) => { 
				safetyFactorOptions.value = response.data.status;
				questions.value = response.data.questions;
				riskCodes.value = response.data.riskCodes;
				form.value = CaseSafetyAssessmentForm.updateAutoFill(response.data.item);
				form.value = CaseSafetyAssessmentForm.updateForm(response.data.item); 
				template(response.data.item.safety_assessment_questions); 
                await syncToOffline();
				loaded.value = true; 
			})
			.catch((error: object) => console.log(error));
	} else {
		await CaseImmediateService.create(
			patientId.value,
			caseId.value,
			isNetworkAvailable.value,
			pickBy({ isOfflineData: offlineParams.value ? true : false }),
		)
			.then(async (response: any) => {
				if (isNetworkAvailable.value) {
					safetyFactorOptions.value = response.data.status;
					questions.value = response.data.questions;
					riskCodes.value = response.data.riskCodes;
					form.value = CaseSafetyAssessmentForm.updateAutoFill(response.data.case);
					form.value.questions = questions.value;
				} else {
					safetyFactorOptions.value = response.data.status;
					questions.value = response.data.questions;
					riskCodes.value = response.data.riskCodes;
					form.value = CaseSafetyAssessmentForm.updateAutoFill(response.data.case);
					form.value.questions = questions.value;
				}
                template(form.value.questions)
                loaded.value = true; 
			})
			.catch((error: any) => console.log(error));
	}
};
 
const syncToOffline = async () => {
    if (isNetworkAvailable.value) {
        await AssessmentOfflineProcessor.syncToOffline(caseId.value, form.value);
    }
}

watch([() => form.value.questions], (val: object) => { 
	form.value.yes_count = renderCount(val[0] ?? [], SafetyAssessmentStatus.YES);
	form.value.no_count = renderCount(val[0] ?? [], SafetyAssessmentStatus.NO);
	form.value.unknown_count = renderCount(val[0] ?? [], SafetyAssessmentStatus.UNKNOWN);
},{deep:true});

onIonViewWillEnter(async () => { 
    // await checkIfAssessmentIsCreated();
    await getData()     
}); 
</script>
