<template>
	<user-layout
		hasBack
		:backLink="`/perpetrator/${perpetratorId}/view${offlineParams ? '?offline=true' : ''}`"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col"
	>
		<template #lowerRight>
			<div class="flex-col items-center space-y-3 md:flex-row md:space-x-3 md:space-y-0">
				<a @click="router.replace(`/perpetrator/${perpetratorId}/view${offlineParams ? '?offline=true' : ''}`)">
					<button-component
					btnWidth="quinary"
					customClass="quinary md:w-auto w-full md:mt-0 mt-6">
						Cancel
					</button-component>
				</a>
				<button-component
					form="form"
					@click="save()"
					customClass="primary md:w-auto w-full md:mt-0 mt-6"
				>
					<span>Save Changes</span>
				</button-component>
			</div>
		</template>
		<div class="w-full p-4 mx-auto my-6 border border-gray-200 rounded lg:p-6">
			<div class="flex items-center justify-between">
				<section-title title="Perpetrator Details" />
			</div>
			<form-section
				v-if="loaded"
				:form="form"
				:errors="errors"
				:genders="genders"
				:relationships="relationships"
				:nationalities="nationalities"
				:religions="religions"
				:currentLocations="currentLocations"
				:identities="identities"
				:item="item" 
			/>
		</div>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Updated!"
			:content="successMessage" />
	</user-layout>
</template>
<script setup lang="ts">
import { computed, ref, watch, } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import FormSection from "@/views/patients/otherInformations/perpetrators/FormSection.vue";
import { useRouter, useRoute } from "vue-router";
import PerpetratorService from "@/classes/Perpetrators/PerpetratorService";
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import { PerpetratorCreate } from "@/interfaces/Cases/Perpetrator";
import PerpetratorForm from "@/classes/Forms/Cases/PerpetratorForm";
import { errorResponse } from "@/helpers/errorMessage"; 
import pickBy from "lodash/pickBy"; 
import { onIonViewWillEnter } from "@ionic/vue";
import { useNetworkStore } from "@/store/network";
import PerpetratorOfflineForm from "@/classes/Perpetrators/Offline/Formatters/PerpetratorOfflineForm";
import Rules from "@/classes/Perpetrators/Offline/Validation/Rules";
import useVuelidate from "@vuelidate/core";
import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
import PerpetratorOfflineService from "@/classes/Perpetrators/Offline/OfflineService";
import OnlineSynching from "@/classes/Synching/OnlineSynching";
import PerpetratorProcessor from "@/classes/Perpetrators/Offline/Processor/PerpetratorProcessor";

const router = useRouter();
const route = useRoute();
const showSuccess = ref<boolean>(false);
const perpetratorId = ref<number | null>(route.params.perpetrator);
const genders = ref<renderSelectV2[]>([]);
const identities = ref<renderSelectV2[]>([]);
const nationalities = ref<renderSelect[]>([]);
const religions = ref<renderSelect[]>([]);
const relationships = ref<renderSelect[]>([]);
const currentLocations = ref<renderSelect[]>([]);
const form = ref<PerpetratorCreate>(PerpetratorOfflineForm.formStructure());
const errors = ref<object>({});
const successMessage = ref<string>("Perpetrator has beed updated!");
const loaded = ref<boolean>(false);
const item = ref<PerpetratorCreate>(PerpetratorOfflineForm.formStructure());
const offlineParams = ref<string>(route.query.offline); 
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const { createRules } = Rules;
const onlineSync = new OnlineSynching();

/**
 * Validate the form using Vuelidator
 */
 const validateForm = async () =>{
	const vuelidate = useVuelidate(createRules({...form.value}), form.value)
	const result = await vuelidate.value.$validate();

    if (result) {
        return null;
    } else {
        errors.value = ErrorValidation.getErrors(vuelidate.value);
        errorResponse(errors.value);
        return errors.value;
    }
}

const save = async (): Promise<any> => {
	errors.value = {};

    // Set validation rule for Online and Offline
    const validate = await validateForm();
    if (validate) {
        return;
    }


    const response = await PerpetratorService.updateProfile(
		perpetratorId.value,
		{ ...form.value, },
        isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        if (isNetworkAvailable.value) {
            await PerpetratorOfflineService.syncToOffline(null, null, response.data.data.item)
        }
        showSuccess.value = true;
        successMessage.value = response.data.message;
    } else {
        const error = response;
        errors.value = errorResponse(error.response?.data?.errors);
    }

};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.replace(`/perpetrator/${perpetratorId.value}/view${offlineParams.value ? "?offline=true" : ""}`);
};

const getData = async (): Promise<any> => {
    const response = await PerpetratorService.editProfile(
		perpetratorId.value,
		pickBy({ is_perpetrator: true }),
        isNetworkAvailable.value
	);

    if (response?.response?.status === 404) {
        loaded.value = false;
        return;
    }

    if (response?.status === 200 || response?.data != undefined) {
		genders.value = response.data.genders;
		identities.value = response.data.identities;
		nationalities.value = response.data.nationalities;
		religions.value = response.data.religions;
		relationships.value = response.data.perpetratorRelationships;
		currentLocations.value = response.data.currentLocations;
        form.value = PerpetratorOfflineForm.renderSyncToOffline(response.data.item);
		item.value = response.data.item;
        if (isNetworkAvailable.value) {
            await PerpetratorProcessor.syncToOfflinePerpetrator(perpetratorId.value, item.value);
        }
        loaded.value = true;
    } else {
        console.log(response);
        redirectSuccess();
    }
};

watch(
    () => isNetworkAvailable.value,
    async () => {
        loaded.value = false;
        await getData();
        await onlineSync.checkDrafts();
    }
);

onIonViewWillEnter(async () => {
    await getData();
    await onlineSync.checkDrafts();
});
 
</script>
