import FormOptions from "@/classes/Forms/FormOptions";
import Behavior from "@/enums/Behavior";
import Gender from "@/enums/Gender";
import HighSuicidalRisk from "@/enums/HighSuicidalRisk";
import IncidentTime from "@/enums/IncidentTime";
import SexAtBirth from "@/enums/SexAtBirth";
import Pwd from "@/enums/Pwd";
import ChildType from "@/enums/ChildType";

const options = new FormOptions();

class PatientTemplateForm {
    async createTemplate() {
        const behaviorTypes = await options.behaviorTypes();
        const civilStatuses = await options.civilStatusOptions();
        const comorbidities = await options.comorbidities();
        const disabilities = await options.disabilities();
        const endorsedToOptions = await options.endorsedToOptions();
        const indigeneous = await options.indigeneous();
        const mentalHealthDiagnoses = await options.mentalHealthDiagnoses();
        const mentalHealthStaticRadioButtonId = await options.mentalHealthStaticRadioButtonId();
        const mms = await options.mms();
        const nationalities = await options.nationalities();
        const physicians = await options.physicians();
        const primaryDiagnoses = await options.primaryDiagnoses();
        const psychiatrists = await options.psychiatrists();
        const referralSources = await options.referralSources();
        const religions = await options.religions();
        const socialWorkers = await options.socialWorkers();
        const soughtServices = await options.soughtServices();
        const trainees = await options.trainees();
        return {
            'behaviorTypes': behaviorTypes,
            'behaviors': Behavior,
            'civilStatuses': civilStatuses,
            'comorbidities': comorbidities,
            'disabilities': disabilities,
            'endorsedToOptions': endorsedToOptions,
            'genders': Gender,
            'highSuicidalRisks': HighSuicidalRisk,
            'incidentTimes': IncidentTime,
            'indigeneous': indigeneous,
            'mentalHealthDiagnoses': mentalHealthDiagnoses,
            'mentalHealthStaticRadioButtonId': mentalHealthStaticRadioButtonId,
            'mms': mms,
            'nationalities': nationalities,
            'physicians': physicians,
            'primaryDiagnoses': primaryDiagnoses,
            'psychiatrists': psychiatrists,
            'pwdSpecific': [],
            'pwds': Pwd,
            'referralSources': referralSources,
            'religions': religions,
            'sexAtBirth': SexAtBirth,
            'socialWorkers': socialWorkers,
            'soughtServices': soughtServices,
            'trainees': trainees,
        }
    }

    async createPatientCaseTemplate() {
        const defaultTemplate = await this.createTemplate();
        const educationalAttainments = await options.educationalAttainments();
        const employmentStatuses = await options.employmentStatus();
        const hospitalAreas = await options.hospitalAreas();
        return {
            ...defaultTemplate,
            ...{
                childTypes: ChildType,
                educationalAttainments: educationalAttainments,
                employmentStatuses: employmentStatuses,
                hospitalAreas: hospitalAreas
            }
        }
    }
}

export default new PatientTemplateForm();