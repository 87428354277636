
// import DB from "@/classes/Databases/Crud";

// import Pagination from "@/classes/Databases/Pagination";
import FormOptions from "@/classes/Forms/FormOptions";
import DateFormatter from "@/helpers/DateFormatter";
import SqliteProcessor from "@/processors/SqliteProcessor";
// import { OfflineAction } from "@/views/enums/OfflineAction";
import Syncable from "@/extenders/models/Syncable";

const options  = new FormOptions();
// const db = new DB('case_conferences', new Pagination('case_conferences')); // @TODO for removal soon
const sqlite = new SqliteProcessor;
const syncable = new Syncable;
class FactorsService {
	async index(patient: number, patientCase: number, caseConference: number): Promise<any> {
        await sqlite.connect();
		let aggravatingFactors:any = [];
		let mitigatingFactors:any = [];

        const conference = await sqlite.db.searchTable('case_conferences', 'id', caseConference);
        const factors = await sqlite.db.searchTable('case_conference_aggravating_factors', 'case_conference_id', caseConference);

		if(factors.length > 0){
			aggravatingFactors = JSON.parse(factors[0].aggravatingFactors);
			mitigatingFactors = JSON.parse(factors[0].mitigatingFactors);
		}		
		
		const item = {
			"case_conference_id": caseConference,
			"aggravatingFactors": aggravatingFactors,
			"mitigatingFactors": mitigatingFactors,
			// "other_aggravating": conference[0].other_aggravating,
			// "other_mitigating": conference[0].other_mitigating
			"other_aggravating": factors[0]?.other_aggravating,
			"other_mitigating": factors[0]?.other_mitigating
		};
        const aggravatingFactorsOptions = await options.aggravatingFactors();
        const mitigatingFactorsOptions = await options.mitigatingFactors();
		return {
			data: {
				aggravatingFactors: aggravatingFactorsOptions,
				mitigatingFactors: mitigatingFactorsOptions,
				item: item,
			}
		}
	}

	async storeOffline(item: any): Promise<void> {
		await sqlite.connect();
        item = this.buildFactorVariables(item)
        syncable.table = 'case_conference_aggravating_factors';
        const checkRecord = await syncable.checkUnsyncRecord(sqlite.db, item.id, 'case_conference_id');
        if (checkRecord) {
            return;
        }
        await syncable.sync(sqlite.db, [item]);
	}

	async store(patient: number, patientCase: number, caseConference: any, payload: any): Promise<any> {

        const factor = await sqlite.db.searchTable('case_conference_aggravating_factors', 'case_conference_id', caseConference);

		if(factor.length > 0){
			await this.processUpdate(factor[0], caseConference, payload);
		} else {
			await this.processStore(caseConference, payload);
		}

		
	}

	private async processStore(caseConference: any, payload: any): Promise<any>{
		const data =  {
			...this.buildFactorVariables(payload), 
			case_conference_id: parseInt(caseConference), 
			is_created_offline: true, 
			created_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
			updated_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
            id: null,
            is_sync: false,
            synching_remarks: null,
		};
        return await sqlite.db.insert('case_conference_aggravating_factors', data);
	}

	private async processUpdate(factor: any, caseConference: any, payload: any): Promise<any>{
		const data =  Object.assign( factor,
			{
				...this.buildFactorVariables(payload), 
				case_conference_id: parseInt(caseConference), 
				is_updated_offline: factor.is_created_offline ? false : true, 
				updated_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
                is_sync: false,
                synching_remarks: null,
			}
		);

        return await sqlite.db.update('case_conference_aggravating_factors', data);
	}

	private buildFactorVariables(payload:any): any 
	{
		// delete payload.other_aggravating;
		// delete payload.other_mitigating;
		
		payload.id = payload.case_conference_id;
		payload.aggravatingFactors = JSON.stringify(payload.aggravatingFactors);
		payload.mitigatingFactors = JSON.stringify(payload.mitigatingFactors);

		return payload;
	}
}

export default new FactorsService();