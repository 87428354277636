import CaseReferralOfflineForm from "../Formatters/CaseReferralOfflineForm";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";
import Relationships from "@/classes/Forms/Relationships";

const sqlite = new SqliteProcessor;
const table = 'case_referral_services'
const syncable = new Syncable;
const relationship = new Relationships();

class CaseReferralServicesProcessor {
    async store(case_id: number, payload: any) {
        const form = CaseReferralOfflineForm.renderStoreForm(case_id, payload);
        syncable.table = table;
        await syncable.removeCreate(
            sqlite.db,
            { cases_id: case_id},
            form
        );
    }

    async getReferralService(case_id: number) {
        return await sqlite.db.select(`SELECT * FROM ${table} WHERE cases_id=${case_id}`);
    }

    async view(item: any) {
        const response = await this.getReferralService(item.id);
        if (response.length > 0) {
            return response[0];
        }

        return null;
    }

    async renderReferralService(item: any) {
        if (!item) {
            return null;
        }
        
        const referral_source = await relationship.referral_source(item.referral_source_id);
        const second_referral_source = await relationship.referral_source(item.second_referral_source_id);
        const sought_service = await relationship.sought_service(item);
        const physician = await relationship.physician(item);
        const mental_health_personnel = await relationship.mental_health_personnel(item);
        const social_worker = await relationship.social_worker(item.social_worker_id);
        const intake_social_worker = await relationship.social_worker(item.intake_social_worker_id);
        const trainee = await relationship.trainee(item);

        return {
            ...item,
            referral_source: referral_source,
            second_referral_source: second_referral_source,
            sought_service: sought_service,
            physician: physician,
            mental_health_personnel: mental_health_personnel,
            social_worker: social_worker,
            intake_social_worker: intake_social_worker,
            trainee: trainee,
        }
    }
}

export default new CaseReferralServicesProcessor();