import Syncable from "@/extenders/models/Syncable";
import SqliteProcessor from "@/processors/SqliteProcessor";
import RelatedToCaseForm from "@/classes/Cases/Intake/RelatedToCase/Formatters/RelatedToCaseForm";
import FormOptions from "@/classes/Forms/FormOptions";
import Storage from "@/helpers/storage";
import Gender from "@/enums/Gender";
import useFormatFormStructure from "@/helpers/Offlinehelpers/useFormatFormStructure";
import { defaultDeleteForm } from "@/classes/General/GeneralOfflineForms";
import Relationships from "@/classes/Forms/Relationships";
import PatientOfflineProcessor from "@/classes/Patients/Offline/processor/PatientOfflineProcessor";
import CaseGuardianOfflineProcessor from "@/classes/Cases/CaseGuardian/Offline/Processor/OfflineProcessor";

const sqlite = new SqliteProcessor();
const options = new FormOptions();
const storage = new Storage();
const table = `case_guardians`;
const relationship = new Relationships();
class OfflineProcessor extends Syncable{
    /**
     * Render form create page
     * @param patient
     * @param patientCase
     * @returns
     */
    async index(patient:number, patientCase:number){
 
        await sqlite.connect();
        const patientQuery = `SELECT * FROM patients WHERE id=${patient} AND deleted_at IS NULL`;
        const personsRelatedQuery = `SELECT * FROM ${table} WHERE cases_id=${patientCase}`;
        const patientItem = await sqlite.db.select(patientQuery);
        const relatedToCase = await sqlite.db.select(personsRelatedQuery);
        const items = relatedToCase.length > 0 ? await RelatedToCaseForm.viewForm(relatedToCase) : [];
        const birthdayClassification = await options.birthdayClassification();
        const personRelatedType = await options.personRelatedTypes();
        const companionRelationship = await options.companionRelationships();
        const civilStatus = await options.civilStatusOptions();
        const educationalAttainment = await options.educationAttainments();
        const housingClassification = await options.housingClassifications();
        const patientDetail = await PatientOfflineProcessor.getFullName(patient);
        return {
            'data': {
                'items': {
                    data: items,
                },
                birthdayClassification: birthdayClassification,
                personRelatedType: personRelatedType,
                companionRelationship: companionRelationship,
                civilStatus: civilStatus,
                educationalAttainment: educationalAttainment,
                housingClassification: housingClassification,
                patient: patientDetail,
                genders: Gender,
            }
        }
    }
 
    async updateItem(id:number, patient:number, patientCase:number, payload:any){
        await sqlite.connect();
        this.table = table;
        const encoded_by = JSON.parse(storage.get('user'));
        let finalForm = null;
        if(id){
            finalForm = useFormatFormStructure(payload,RelatedToCaseForm.updateForm(payload));
        }else{
            finalForm = useFormatFormStructure(payload,RelatedToCaseForm.createForm(null));
            finalForm.cases_id = patientCase;
            finalForm.encoded_by = encoded_by.id;
        }
        finalForm.birthdate = relationship.dateFormatFromIso(payload.birthdate);
        finalForm.person_related_types = payload.person_related_types;
        const persons = await this.sync(sqlite.db, [finalForm]);

        return {
            data: {
                data: {
                    item: persons
                }
            }
        }
    }

    async delete(patient:number,patientCase: number, companionId: number){
        await sqlite.connect();
        const response = await CaseGuardianOfflineProcessor.delete(companionId, patientCase)
        // this.table = table; 
        // const query = await sqlite.db.select(`SELECT * FROM ${table} WHERE id=${companionId} LIMIT 1`);
        // const form = {
        //     ...query[0],
        //     ...defaultDeleteForm(),
        // } 
        // console.log(form);
        // const companion = this.sync(sqlite.db, form);
        return {
            data: {
                data: {
                    item: response
                }
            }
        }
    }

    async syncToOffline(case_id: number, payload: any) {
        await sqlite.connect();
        const encoded_by = JSON.parse(storage.get('user'));
        const form = useFormatFormStructure(payload,RelatedToCaseForm.updateForm(payload));
        form.cases_id = case_id;
        form.encoded_by = encoded_by.id;
        form.is_created_offline = false;
        form.is_updated_offline = false;
        form.is_deleted_offline = false;
        form.is_sync = false;
        form.is_sync_failed = false;
        form.synching_remarks = false;
        
        this.table = table;
        await this.sync(sqlite.db, [form]);
    }
}
export default new OfflineProcessor();