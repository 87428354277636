import Storage from "@/helpers/storage";
import SexAtBirth from "@/enums/SexAtBirth";
import Behavior from "@/enums/Behavior";
import Gender from "@/enums/Gender";
import HighSuicidalRisk from "@/enums/HighSuicidalRisk";
import IncidentTime from "@/enums/IncidentTime";        
import ChildType from "@/enums/ChildType";
import Pwd from "@/enums/Pwd";
import PwdSpecific from "@/enums/PwdSpecific";
import { BirthdayClassification } from "@/views/enums/BirthdayClassification";
// import { items } from "@/interfaces/pages/FilterPageInterface";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Position, { getPosition } from "@/enums/Position";
import PersonnelProcessor from "../Personnels/PersonnelProcessor";

const storage = new Storage(); 
const sqlite = new SqliteProcessor;
export default class FormOptions {

    intervention_type_id: any;
	patient_id: any;

	constructor(intervention_type_id: any = null, patient_id: any = null) { 
		this.intervention_type_id = intervention_type_id;
		this.patient_id = patient_id;
	}

	async civilStatusOptions() { 
        const response = await sqlite.db.select(`SELECT * FROM civil_statuses`);
        if (response.length > 0) { 
            return response?.map((item: any) => {
                return { 
                    id: item.id, 
                    value: item.id, 
                    label: item.name, 
                    name: item.name 
                };
			}) || [];
        } else {
            return [];
        }
	}

	async indigeneous() { 
        const response = await sqlite.db.select(`SELECT * FROM indigeneous_peoples`);
        if (response.length > 0) { 
            const items = response?.map((item: any) => {
                return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
            items.unshift(
                {id: 'none', value: 'none', label: 'None', name: 'None'}
            );
            items.push({id: 'others', value: 'others', label: 'Others', name: 'Others'});
            return items;
        } else {
            return [];
        }
	}

	async indigeneousMultiSelect() {
        const response = await sqlite.db.select(`SELECT * FROM indigeneous_peoples`);
        if (response.length > 0) {
            const items = response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
            
            items.unshift(
                {id: 'none', value: 'none', label: 'None', name: 'None'}
            );

            items.push(
                {id: 'others', value: 'others', label: 'Others', name: 'Others'},
              
            );
            return items;
        } else {
            return [];
        }
	}

	async educationAttainments() {
        const response = await sqlite.db.select(`SELECT * FROM educational_attainments`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async riskCodes() {
        const response = await sqlite.db.select(`SELECT * FROM risk_codes`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.description, description: item.description };
			}) || [];
        } else {
            return [];
        }
	}

	async employmentStatus() {
        const response = await sqlite.db.select(`SELECT * FROM employment_statuses`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async referralSources() {
        const response = await sqlite.db.select(`SELECT * FROM referral_sources`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async comorbidities() {
        const response = await sqlite.db.select(`SELECT * FROM comorbidities`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { 
                    id: item.id, 
                    value: item.name, 
                    label: item.name, 
                    name: item.name, 
                    comorbidity_id: item.id 
                };
			}) || [];
        } else {
            return [];
        }
	}

	async departments() {
        const response = await sqlite.db.select(`SELECT * FROM departments`);
        if (response.length > 0) {
            return response.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name, comorbidity_id: item.id };
			}) || [];
        } else {
            return [];
        }
	}

	async guardianRelationships() {
        const response = await sqlite.db.select(`SELECT * FROM  guardian_relationships`); 
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { 
                    id: item.id, 
                    value: item.id, 
                    label: item.name, 
                    name: item.name,  
                };
			}) || [];
        } else {
            return [];
        }
	}

	async safetyAssessmentQuestions() {
        const response = await sqlite.db.select(`SELECT * FROM safety_assessment_questions`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.description, description: item.description };
			}) || [];
        } else {
            return [];
        }
	}

	async relationships() {
        const response = await sqlite.db.select(`SELECT * FROM relationships`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async hospitalAreas() {
        const response = await sqlite.db.select(`SELECT * FROM hospital_areas`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async barangays(id: any = null) {
        if(id){
            const response = await sqlite.db.select(`SELECT * FROM barangays WHERE city_id=${id}`);
            if(response.length > 0){
                return response.map((item) => {
                    return { 
                        id: item.id, 
                        value: item.id, 
                        label: item.name, 
                        name: item.name, 
                        city_id: item.city_id, 
                        code: item.code 
                    }; 
                });
            }else{
                return [];
            }
        }else{
            return [];
        } 
	}

	async religions() {
        const response = await sqlite.db.select(`SELECT * FROM religions ORDER BY name ASC`);
        if (response.length > 0) {
            const items = response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];

            items.push({id: 'others', value: 'others', label: 'Others', name: 'Others'});
            return items;
        } else {
            return [];
        }
	}

    async regions() {
        const response = await sqlite.db.select(`SELECT * FROM regions`);
        if (response.length > 0) {
            return response.map((item: any) => {
				return { 
                    id: item.id, 
                    value: item.id, 
                    label: item.name, 
                    name: item.name, 
                    code: item.code 
                };
			}) || [];
        } else {
            return [];
        }
	}

	async cities(id: any = null) { 
        if(id){
            const response = await sqlite.db.select(`SELECT * FROM  cities WHERE province_id=${id}`);
            if(response.length > 0 ){
                return response.map((item) => {
                    return { 
                        id: item.id, 
                        value: item.id, 
                        label: item.name, 
                        name: item.name, 
                        province_id: item.province_id 
                    };  
                });
            }else{
                return [];
            }
        }else{  
            return [];
        }
	}

	async provinces(id: any = null) {
        if(id){
            const response = await sqlite.db.select(`SELECT * FROM provinces WHERE region_id=${id}`);
            if(response.length > 0){
                return response.map((item) => {
                    return { 
                        id: item.id, 
                        value: item.id, 
                        label: item.name, 
                        name: item.name, 
                        region_id: item.region_id 
                    }; 
                });
            }else{
                return [];
            }
        }else{
            return [];
        }
	}

	async perpetratorRelationships() {
        const response = await sqlite.db.select(`SELECT * FROM perpetrator_relationships`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async companionRelationships() {
        const response = await sqlite.db.select(`SELECT * FROM  companion_relationships`); 
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { 
                    id: item.id, 
                    value: item.id, 
                    label: item.name, 
                    name: item.name,  
                };
			}) || [];
        } else {
            return [];
        }
	}

	async soughtServices() {
        const response = await sqlite.db.select(`SELECT * FROM sought_services`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async behaviorTypes() {
        const response = await sqlite.db.select(`SELECT * FROM behavior_types`);
        if (response.length > 0) {
            return response?.map((item: any) => {
                return {
                    id: item.id,
                    value: item.id,
                    label: item.name,
                    type: item.type,
                    name: item.name,
                }
            });
        } else {
            return [];
        }
	}

	async socioEconomicStatuses() {
        const response = await sqlite.db.select(`SELECT * FROM  socio_economic_statuses`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { 
                    id: item.id, 
                    value: item.id, 
                    label: item.name, 
                    name: item.name 
                };
			}) || [];
        } else {
            return [];
        }
	}

	async housingClassifications() {
        const response = await sqlite.db.select(`SELECT * FROM  housing_classifications`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

    birthdayClassification() {
        return [
            { id: BirthdayClassification.BIRTHDATE, value: BirthdayClassification.BIRTHDATE, label: "Birthday" },
            { id: BirthdayClassification.APPROXIMATE_AGE, value: BirthdayClassification.APPROXIMATE_AGE, label: "Approximate Age" },
            { id: BirthdayClassification.UNKNOWN, value: BirthdayClassification.UNKNOWN, label: "Unknown" },
        ];
    }

    personRelatedTypes() {
        return [
            { id: 0, value: 0, label: 'Lives with child?' },
            { id: 1, value: 1, label: 'Is present caretaker?' },
            { id: 3, value: 3, label: 'Deceased?' },
            { id: 4, value: 4, label: 'Is child?' },
            { id: 5, value: 5, label: 'Is contact person?' },
        ];
    }

    livingArrangements() {
        return [
            { id: 0, value: 0, label: "Street Child"},
            { id: 1, value: 1, label: "Street Family"},
            { id: 2, value: 2, label: "NGO Shelter"},
            { id: 3, value: 3, label: "Govt Agency"},
            { id: 4, value: 4, label: "Unknown"},
            { id: 5, value: 5, label: "Single Parent"},
            { id: 6, value: 6, label: "Immediate Family"},
            { id: 7, value: 7, label: "Extended Family"},
            { id: 8, value: 8, label: "Relatives"},
            { id: 9, value: 9, label: "None-Relatives"},
        ];
    }

    sleepingArrangements() {
        return [
            { id: 0, value: 0, label: 'At home' },
            { id: 1, value: 1, label: 'On the street' },
            { id: 2, value: 2, label: 'In a shelter' },
            { id: 3, value: 3, label: 'Others' },
        ];
    }

    sleepipngArrangmentWith() {
        return [
            { id: 0, value: 0, label: 'Adult Male' },
            { id: 1, value: 1, label: 'Adult Female' },
            { id: 2, value: 2, label: 'Male Child(ren)' },
            { id: 3, value: 3, label: 'Female Child(ren)' },
            { id: 4, value: 4, label: 'Alone' },
        ];
    }

    safetyAssessmentStatus() {
        return [
            { id: 0, value: 0, label: 'Yes' },
            { id: 1, value: 1, label: 'No' },
            { id: 2, value: 2, label: 'Unknown' },
        ];
    }

	async disabilities() {
        const response = await sqlite.db.select(`SELECT * FROM disabilities`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { 
                    id: item.id, 
                    value: item.id, 
                    label: item.name, 
                    name: item.name 
                };
			}) || [];
        } else {
            return [];
        }
	}

	async nationalities() {
        const response = await sqlite.db.select(`SELECT * FROM nationalities ORDER BY name ASC`);
        if (response.length > 0) {
            const items = response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
            items.push({id: 'others', value: 'others', label: 'Others', name: 'Others'});
            return items;
        } else {
            return [];
        }
	} 

	async offices() {
        const response = await sqlite.db.select(`SELECT * FROM offices`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async telCpuLocations() {
        const response = await sqlite.db.select(`SELECT * FROM telcpu_locations`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	} 
    
    async telCpuOffices() {
        const response = await sqlite.db.select(`SELECT * FROM telcpus`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name, telcpu_location_id: item.telcpu_location_id };
			}) || [];
        } else {
            return [];
        }
	}


	async personnels(position?: number) {
        await sqlite.connect();
        return await PersonnelProcessor.personnels();
	}

	async socialWorkers() {
        return await PersonnelProcessor.getPersonnels([getPosition.SOCIAL_WORKER]);
	}

	async physicians() {
        return await PersonnelProcessor.getPersonnels([getPosition.PHYSICIAN]);
	}

	async psychiatrists() {
        return await PersonnelProcessor.getPersonnels([getPosition.PSYCHIATRIST, getPosition.PSYCHOLOGIST]);
	}

	async mms() {
        return await PersonnelProcessor.getPersonnels([getPosition.MMS]);
	}

	async trainees() {
        return await PersonnelProcessor.getPersonnels([getPosition.TRAINEE]);
	}

	async primaryDiagnoses() {
        const response = await sqlite.db.select(`SELECT * FROM primary_diagnoses`); 
        if (response.length > 0) { 
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async mentalHealthDiagnoses() { 
        const response = await sqlite.db.select(`SELECT * FROM mental_health_diagnoses`);
        if (response.length > 0) { 
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async mentalHealthStaticRadioButtonId() {
        return await PersonnelProcessor.mentalHealthStaticRadioButtonId()
	}

	async assignedGroups() {
        const response = await sqlite.db.select(`SELECT * FROM positions`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	interventionTypeLists() {
		let res = JSON.parse(storage.get("interventionTypeLists")) ?? []
		res = this.intervention_type_id
			? res.filter((x: any) => x.intervention_type == this.intervention_type_id)
			: res

		return res.map((item: any) => ({ id: item.id, value: item.id, label: item.name, name: item.name }))
	}

	caseNumbers() {
		return (
			JSON.parse(storage.get("cases"))?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.case_number };
			}) || []
		);
	}

	cases() {
		return (
			JSON.parse(storage.get("cases")) || []
		);
	}

	async educationalAttainments() { 
        const response = await sqlite.db.select(`SELECT * FROM educational_attainments`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	} 
	guardian() {
		return; 
	}

	office() {
		return JSON.parse(storage.get("user")).office || null;
	} 
	async interventionTypes() {
        await sqlite.connect();
		const response = await sqlite.db.select(`SELECT * FROM intervention_types`);
		
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.label };
			}) || [];
        } else {
            return [];
        }
	}

    async wcpuPersonnels() {
        return await PersonnelProcessor.wcpuPersonnels();
	}

    async agencies() {
        const response = await sqlite.db.select(`SELECT * FROM agencies`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

    async aggravatingFactors() {
        await sqlite.connect();
        const response = await sqlite.db.select(`SELECT * FROM aggravating_factors`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

    async mitigatingFactors() {
        await sqlite.connect();
        const response = await sqlite.db.select(`SELECT * FROM mitigating_factors`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

    async recommendationOptions() {
        const response = await sqlite.db.select(`SELECT * FROM recommendation_options`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.label, name: item.label };
			}) || [];
        } else {
            return [];
        }
	}

    async endorsedToOptions() {
        return await PersonnelProcessor.getPersonnels([getPosition.OB_RESIDENT, getPosition.PEDIATRIC_RESIDENT, getPosition.TRAINEE]);
    }

	async createPatientOptions() {
        const behaviorTypes = await this.behaviorTypes();
        const civilStatuses = await this.civilStatusOptions();
        const comorbidities = await this.comorbidities();
        const disabilities = await this.disabilities();
        const indigeneous = await this.indigeneousMultiSelect();
        const mentalHealthDiagnoses = await this.mentalHealthDiagnoses();
        const mms = await this.mms();
        const nationalities = await this.nationalities();
        const physicians = await this.physicians();
        const primaryDiagnoses = await this.primaryDiagnoses();
        const psychiatrists = await this.psychiatrists();
        const referralSources = await this.referralSources();
        const religions = await this.religions();
        const socialWorkers = await this.socialWorkers();
        const soughtServices = await this.soughtServices();
        const trainees = await this.trainees();
        const mentalHealthStaticRadioButtonId = await this.mentalHealthStaticRadioButtonId();
        const educationalAttainments = await this.educationAttainments();
        const employmentStatuses = await this.employmentStatus();
        const hospitalAreas = await this.hospitalAreas();
		return {
			behaviorTypes: behaviorTypes,
			behaviors: Behavior,
			civilStatuses: civilStatuses,
			comorbidities: comorbidities,
			disabilities: disabilities,
			genders: Gender,
			highSuicidalRisks: HighSuicidalRisk,
			incidentTimes: IncidentTime,
			indigeneous: indigeneous,
			mentalHealthDiagnoses: mentalHealthDiagnoses,
			mms: mms,
			nationalities: nationalities,
			physicians: physicians,
			primaryDiagnoses: primaryDiagnoses,
			psychiatrists: psychiatrists,
			referralSources: referralSources,
			religions: religions,
			sexAtBirth: SexAtBirth,
			socialWorkers: socialWorkers,
			soughtServices: soughtServices,
			trainees: trainees,
			mentalHealthStaticRadioButtonId: mentalHealthStaticRadioButtonId,
			childTypes: ChildType,
			educationalAttainments: educationalAttainments,
			employmentStatuses: employmentStatuses,
			hospitalAreas: hospitalAreas,
			pwds: Pwd,
			pwdSpecific: PwdSpecific,
		};
	}

    setOtherToEnd(items = []) {
        const getOther = items.find((item: any) => item?.label?.toLowerCase() == 'other');
        const newItems: any = items.filter((item: any) => item?.label?.toLowerCase() != 'other');
        newItems[newItems.length] = getOther;
        return newItems;
    }

}
