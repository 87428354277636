<template>
	<user-layout
		hasBack
		:backLink="`/patient/${patientId}/cases/${caseId}/edit`"
		backTitle="Add New Guardian"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>
			<div class="flex md:flex-row flex-col items-center md:space-x-3 md:space-y-0 space-y-3">
				<a
					@click="
						router.replace(
							`/patient/${patientId}/cases/${caseId}/edit`
						)
					"
					class="md:w-auto w-full md:mt-0 mt-6">
					<button-component btnWidth="quinary" customClass="quinary md:w-auto w-full">
						Cancel
					</button-component>
				</a>
				<button-component
					customClass="primary md:w-auto w-full md:mt-0 mt-6"
					form="form"
					@click="validateForm()"
				>
					<span>Save Changes</span>
				</button-component>
			</div>
		</template>
		<p class="mt-6 md:text-2xl text-lg font-medium text-neutral-600">Patient's Name:
			<span class="md:inline-block block font-semibold text-neutral-900">{{ patient }}</span>
		</p>
		<div class="w-full lg:p-6 p-4 mx-auto my-6 border border-gray-200 rounded">
			<div class="flex items-center justify-between">
				<section-title title="Guardian Details" />
			</div>
			<form-section
				ref="formRef"
				:form="form"
				:errors="errors"
				:genders="genders"
				:guardianRelationships="guardianRelationships" 
                @update:form="(value: any) => form = value"
                />
		</div>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Save!"
			:content="successMessage" />
	</user-layout>
 </template>
 <script setup lang="ts">
 import { ref, computed } from "vue";
 import UserLayout from "@/layouts/UserLayout.vue";
 import SectionTitle from "@/components/partials/SectionTitle.vue";
 import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
 import SuccessModal from "@/components/modals/SuccessModal.vue";
 import FormSection from "./FormSection.vue";
 import { useRouter, useRoute } from "vue-router";
 import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
 import { GuardianCreate } from "@/interfaces/Cases/Guardian";
 import { errorResponse } from "@/helpers/errorMessage";
 import pickBy from "lodash/pickBy";
 import { useRecordStatusChecker } from "@/store/recordStatusChecker";
 import { onIonViewWillEnter, onIonViewWillLeave } from "@ionic/vue";
 
 
 
 
 /******************************* For Offline Imports************************************ */
 import { useNetworkStore } from "@/store/network";
 import Rules from "@/classes/Cases/CaseGuardian/Offline/Validation/Rules";
 import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
 import useVuelidate from "@vuelidate/core";
 import Relationships from "@/classes/Forms/Relationships"; 
 import CaseGuardianService from "@/classes/Cases/CaseGuardian/CaseGuardianService";
 import CaseGuardianForm from "@/classes/Cases/CaseGuardian/Offline/Formatters/CaseGuardianForm";
 import CaseGuardianOfflineProcessor from "@/classes/Cases/CaseGuardian/Offline/Processor/OfflineProcessor";
 const { createRules } = Rules;
 const networkStore = useNetworkStore();
 const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
 const relationship = new Relationships()
 /*************************************************************************************** */
 
 const formRef = ref<any>([]); 
 const router = useRouter();
 const route = useRoute();
 const showSuccess = ref<boolean>(false);
 const patient = ref<string>('');
 const patientId = ref<number | null>(route.params.patient);
 const caseId = ref<number | null>(route.params.case);
 const genders = ref<renderSelectV2[]>([]);
 const guardianRelationships = ref<renderSelect[]>([]);
 
 //This form used by both online and offline
 const form = ref<any>(CaseGuardianForm.createForm());
 
 const errors = ref<object>({});
 const successMessage = ref<string>("New guardian has been added!");
 const recordStatusChecker = useRecordStatusChecker();
 const loading = ref<boolean>(false);
 
 
 /**
 * Save data if online or offline
 */
 const save = async (): Promise<any> => {
	errors.value = {};
	CaseGuardianService
		.store(
			patientId.value,
			caseId.value,
			null,
			{ ...form.value },
			isNetworkAvailable.value
		)
		.then(async (data) => {
            if (isNetworkAvailable.value) {
                await CaseGuardianOfflineProcessor.syncToOffline(caseId.value,data.data.data.item);
            }

			showSuccess.value = true;
			successMessage.value = data.message;
		})
		.catch((error: object) => {
			if(error?.data?.errors){
				errors.value = errorResponse(error?.data?.errors);
			}
		})
 };
 
 
 /**
 * Validate the form using Vuelidator
 */
 const validateForm = () =>{
	const vuelidate = useVuelidate(createRules({...form.value}), form.value)
	const result = vuelidate.value.$validate();
	result
		.then((res) => {
			if(res) { 
				save()
			} else {
				errors.value = ErrorValidation.getErrors(vuelidate.value)
                errorResponse(errors.value);
			}
		})
 }
 
 
 const resetForm = () => {
	form.value.is_no_guardian = false;
	form.value.first_name = null;
	form.value.last_name = null;
	form.value.middle_name = null;
	form.value.gender = null;
	form.value.birthday_classification = null;
	form.value.birthdate = null;
	form.value.approximate_age_month = null;
	form.value.approximate_age_year = null;
	form.value.guardian_relationship_id = null;
	form.value.other_relationship = null;
	form.value.contacts = null;

	formRef.value.birthdateForm.datePickerData.picker.clearValue()
 }
 
 
 const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.push(`/patient/${patientId.value}/cases/${caseId.value}/edit`);
 };
 
 
 const getData = async () => {
	const response = await CaseGuardianService.create(
		patientId.value,
		caseId.value,
		pickBy({}),
		isNetworkAvailable.value
	); 

    if (response?.response?.status === 404) {
        return;
    }

	if (response?.status === 200 || response.data !== undefined) {
		form.value.is_no_guardian = false;
		genders.value = response.data.genders;
		patient.value = response.data.patient
		guardianRelationships.value = response.data.guardianRelationships;
		
		if(!isNetworkAvailable.value){//Render additional form data 
			genders.value = response.data.genders;
			guardianRelationships.value = response.data.guardianRelationships;  
		}
	}
 };
 
 
 onIonViewWillLeave(() => resetForm());
 onIonViewWillEnter(async () => {
	recordStatusChecker.isCreatedGuardian = false;
	resetForm(); 
	await getData();
 });
 </script>