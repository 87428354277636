import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "../defaultMigration";

export const case_safety_assessments:SchemaTableOptions = {
    name:<string> 'case_safety_assessments',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
        { column: 'cases_id', value: 'INTEGER UNSIGNED'},
        { column: 'case_number', value: 'VARCHAR(255) NOT NULL'},
        { column: 'assessment_date', value: 'TIMESTAMP'},
        { column: 'first_name', value: 'VARCHAR(255) NOT NULL'},
        { column: 'last_name', value: 'VARCHAR(255) NOT NULL'},
        { column: 'middle_name', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'is_court_hearing_included', value: 'TINYINT UNSIGNED'},
        { column: 'is_assessment_patient_sibling', value: 'TINYINT UNSIGNED'},
        { column: 'appointment', value: 'TEXT DEFAULT NULL'},
        { column: 'brief_family_background', value: 'TEXT DEFAULT NULL'},
        { column: 'family_background', value: 'TEXT DEFAULT NULL'},
        { column: 'yes_count', value: 'TINYINT UNSIGNED'},
        { column: 'no_count', value: 'TINYINT UNSIGNED'},
        { column: 'unknown_count', value: 'TINYINT UNSIGNED'},
        { column: 'risk_code_id  ', value: 'INTEGER UNSIGNED'},
        { column: 'immediate_safety_plan', value: 'TEXT DEFAULT NULL'},
        { column: 'overall_risk_assessment', value: 'TEXT DEFAULT NULL'},
        { column: 'encoded_by', value: 'INTEGER UNSIGNED'},
        ...defaultMigration
    ]
}