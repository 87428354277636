<template>
    <case-conference-layout :patient="patient" v-if="loaded">
        <template #actionButtons>
            <div class="flex flex-col flex-shrink-0 space-y-3 sm:space-x-3 sm:space-y-0 sm:flex-row">
                <!-- Create -->
                <template v-if="!isView || isEdit">
                    <button-component 
                        btnWidth="quinary"
                        fill="outline"
                        @click="router.replace(`/patient/${patientId}/cases/${caseId}/edit/`)"
                        customClass="quinary md:w-auto w-full"
                    >
                        Cancel
                    </button-component>
                    <button-component
                    @click="showConfirmation = showConfirmation = true"
                    customClass="primary md:w-auto w-full">
                        Save
                    </button-component>
                </template>

                <!-- View -->
                <template v-if="isView && !isEdit">
                    <button-component @click="isEdit = true" customClass="primary md:w-auto w-full">
                        <!-- <img
                            src="/assets/icon/ic-pencil-underline.svg"
                            alt="edit_icon"
                            class="object-contain w-4 h-4 mr-1 icon" /> -->
                        <pencil-underline-icon class="object-contain w-4 h-4 mr-1 icon" />
                        <span>Edit</span>
                    </button-component>
                    <button-component
                        @click="generatePDFAccess()"
                        customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700 md:w-auto w-full"
                    >
                        <EyeIcon class="w-5 h-5 mr-2 -ml-1 icon" />
                        <span>View PDF</span>
                    </button-component>
                </template>

                <!-- Edit -->
                <template v-if="!createMode && editMode">
                    <button-component 
                        btnWidth="quinary"
                        fill="outline"
                        @click="editMode = !editMode"
                        customClass="quinary md:w-auto w-full"
                    >
                        Cancel
                    </button-component>
                    <button-component
                    @click="showConfirmation = showConfirmation = true"
                    customClass="primary md:w-auto w-full">
                        Save Changes
                    </button-component>
                </template>
                
            </div>
        </template>

        <information-tab 
            :form="informationForm"
            :agencies="agencies"
            :invitees="invitees"
            :socialWorkers="socialWorkers"
            :recommendations="recommendations"
            :item="informationForm"
            @setAgencies="setAgencies"
            @setInvitees="setInvitees"
            :isEdit="isEdit"
            :errors="errors"
            :inviteeIds="[]"
            @update:form="(value) => informationForm = value"
        />

        <!-- MODAL -->
        <confirmation-modal
            :typeTwo="true"
            :show="showConfirmation"
            @cancel="showConfirmation = false"
            @confirm="validate()"
            cancel-text="Cancel"
            action-text="Confirm"
            title="Save Changes"
            content="Are you sure do you want to save added details?" />
            
        <success-modal
            :show="showSuccess"
            @confirm="redirectSuccess()"
            action-text="Close"
            title="Success!"
            content="Details has been successfully saved!" />
        <page-loader :show="loading"/>
        
    </case-conference-layout>
</template>
<script setup lang="ts">
import PageLoader from "@/components/loaders/PageLoader.vue"
import CaseConferenceLayout from '../../Component/CaseConferenceLayout.vue';
import InformationTab from "../../Tabs/InformationTab.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { EyeIcon } from "@heroicons/vue/24/solid";

import { ref, onMounted, computed, watch} from 'vue';
import { useRouter, useRoute } from "vue-router";

import PersonalInformationService from "@/classes/Cases/IntakeForms/PersonalInformationService";
import CaseConferenceService from "@/classes/Cases/CaseConference/CaseConferenceService";
import { errorResponse } from "@/helpers/errorMessage";
import { useNetworkStore } from '@/store/network';

import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'

import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
import PencilUnderlineIcon from "@/components/icons/pencilUnderlineIcon.vue";

const networkStore = useNetworkStore();

const router = useRouter();
const route = useRoute();
const loading = ref(false);
const loaded = ref(false);
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const createMode = ref<boolean>(true);
const editMode = ref<boolean>(true);


const route_params = route.params;
const errors = ref<any>();
const patient = ref<string>('');
const caseConference = ref<number|null>(null);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const isEdit = ref(true)

const item = ref([])
const agencies = ref([])
const invitees = ref([])
const socialWorkers = ref([])
const recommendations = ref([])

const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const informationForm = ref<any>({
    id: null,
    social_worker_id: null,
    date_time: null,
    invitees: [],
    agencies: [],
})

const clearForm = () => {
    informationForm.value.id = null,
    informationForm.value.social_worker_id = null,
    informationForm.value.date_time = null,
    informationForm.value.invitees = null,
    informationForm.value.agencies = null
}

const isView = ref(false)


const setAgencies = (value: any) => {
    informationForm.value.agencies = value
}

const setInvitees = (value: any) => {
    informationForm.value.invitees = value
}

const redirectSuccess = (): void => {
    showSuccess.value = false;
    createMode.value = false;
    editMode.value = false;

    router.replace(`/patient/${patientId.value}/cases/${caseId.value}/case-conference/information/${caseConference.value?.id}/edit`)
};

const getData = () => {
    loading.value = true
    
	if (caseId.value) {
		CaseConferenceService
        .create(
			patientId.value,
			caseId.value,
            isNetworkAvailable.value
		)
			.then(({data}) => {
                console.log((data));
                
                agencies.value = data.agencies
                invitees.value = data.invitees
                patient.value = data.patient_name
                socialWorkers.value = data.socialWorkers
                recommendations.value = [];
                
                loading.value = false
			})
			.catch((error: object) => {
                loading.value = false
                console.log(error)
            }) 
            .finally(() => {
                loaded.value = true
                loading.value = false
            });
	}
};
//Validations
const rules = computed(() => {
    return  {
        social_worker_id: {
            required,
        },
        date_time: {
            required,
        },
        // invitees: {
        //     required,
        //     minLength: 1,
        // },
        // agencies: {
        //     required,
        //     minLength: 1,
        // },

    }; 
})

const vuelidate = useVuelidate(rules, informationForm.value);

const validate = ():void => {
    const result = vuelidate.value.$validate();
	loading.value = true;
	showConfirmation.value = false;
    
    result
        .then((res) => {
			if(res) {
				submit()
			} else {
				errors.value = ErrorValidation.getErrors(vuelidate.value)
                errorResponse(errors.value);
			}
		}).catch((err) => {
			console.log(err);
		})
		.finally(() => {
			loading.value = false;
		});
}

const submit = () => {
    loading.value = true

    if (caseId.value) {
		CaseConferenceService.store(
			patientId.value,
			caseId.value,
            informationForm.value,
            isNetworkAvailable.value
		)
			.then(async ({data}) => {
                caseConference.value = data.data.item

                await storeOffline(caseConference.value);
                
                showConfirmation.value = false
                isView.value = true
                loading.value = false
                showSuccess.value = true;
                clearForm()
			})
			.catch((error: object) => { 
                console.log(error)
                loading.value = false
                showConfirmation.value = false
                errors.value = errorResponse(error.response?.data?.errors)
            });
	}
}

const generatePDFAccess = ():void => {
	CaseConferenceService
		.generatePDFAccess(
			patientId.value,
			caseId.value,
			informationForm.value.id,
            isNetworkAvailable.value
		)
		.then(({data}) => {
			if(data.token){
				downloadPDF(data.token)
			}
		}).catch((errors) => {
			console.log(errors);
		})

}
const downloadPDF = (token:string):void => {
	CaseConferenceService
		.downloadPDF(
			informationForm.value.id,
			token,
            isNetworkAvailable.value
		)
		.then(({data}) => {
			console.log(data);
		}).catch((errors) => {
			console.log(errors);
		})

}

const storeOffline = async (item: any): Promise<void> => {
	if(isNetworkAvailable.value) {
		CaseConferenceService.storeIndexOffline([item])
	}
};

watch(
    () => isNetworkAvailable.value,
    () => {
        loading.value = false;
        getData();
    }
);

onMounted(() => { 
    getData();
})

</script>

