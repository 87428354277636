import Syncable from "@/extenders/models/Syncable";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Storage from "@/helpers/storage";  
import EmailForm from "../Formatter/EmailForm";
import PatientOfflineProcessor from "@/classes/Patients/Offline/processor/PatientOfflineProcessor";
import Relationships from "@/classes/Forms/Relationships";

const storage  = new Storage();
const sqlite = new SqliteProcessor;
const relationship = new Relationships();
const table = `intake_email_addresses`;

class OfflineProcessor extends Syncable{ 
     /**
     * Render data from storage
     * @param patient 
     * @param patientCase 
     * @returns 
     */
     async index(patient: number, patientCase: number){
        await sqlite.connect(); 
        const emailAddress = await sqlite.db.select(`SELECT * FROM ${table} WHERE cases_id=${patientCase} AND deleted_at IS NULL`);
        const patients = await sqlite.db.select(`SELECT * FROM patients WHERE ${patient} LIMIT 1`)
        
        const patientDetail = await PatientOfflineProcessor.getFullName(patient);
        return {
            'data': {
                'items': { 
                    data: emailAddress.length > 0 ?  EmailForm.viewForm({emailAddress})  : [],
                    total: emailAddress.length
                },
                'patient' : patientDetail,
            },
        };
    }

    /**
     * Update or Create data from storage
     * @param patient 
     * @param patientCase 
     * @param payload 
     * @returns 
     */
    async updateCreate(patient:any, patientCase: any, payload: any){
        this.table = table; 
        const encoded_by = JSON.parse(storage.get('user'));
        
        const additionalData = {
            cases_id: patientCase,
            encoded_by: encoded_by.id,
        }   
   
        const structuredData = {...payload,...additionalData};  
        await sqlite.connect(); 
        const emailAddress = await this.sync(sqlite.db,[structuredData]);
 
        return {
            data: {
                data: {
                    item: emailAddress
                }
            }
        } 
    }
    
    async syncToOffline(case_id: number, payload: any) {
        await sqlite.connect();
        this.table = table; 
        const encoded_by = JSON.parse(storage.get('user'));
        const structuredData = {
            cases_id: case_id,
            encoded_by: encoded_by.id,
            is_created_offline: false,
            is_updated_offline: false,
            is_deleted_offline: false,
            is_sync: false,
            is_sync_failed: false,
            synching_remarks: null,
            label: payload.label,
            email: payload.email,
            id: payload.id || null,
        } 

        await this.sync(sqlite.db, [structuredData]);
    }

    async delete(id:number, patientCase: number, forceDelete = false){
        await sqlite.connect();
        if (forceDelete) {
             await this.forceDelete(id, patientCase)
        }
 
        // check if record exist offline
        const response = await sqlite.db.select(`SELECT * FROM ${table} WHERE id=${id} AND cases_id=${patientCase}`);
        if (response.length > 0) {
             const item = response[0];
 
             if (item.is_created_offline) {
                 await this.forceDelete(id, patientCase);
             } else {
                 const form = {
                     id: id,
                     is_sync: false,
                     synching_remarks: null,
                     is_deleted_offline: true,
                     deleted_at: relationship.dateFormatFromIso(new Date),
                 }
                 await sqlite.db.updateWithFilters(table, form, { cases_id: patientCase, id: id });
             }
        }
        return;
    }

    async forceDelete(id: number, case_id: number) {
        await sqlite.db.delete(table,{id: id, cases_id: case_id});
    }
}

export default new OfflineProcessor();