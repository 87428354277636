<template>
    <hr class="h-0.5 border-t-0 bg-gray-500 first:hidden" />

    <div :id="form.label + form.id" class="flex items-start scroll-to">
        <div class="h-[calc(100vh-146px)] flex-1 flex justify-center space-y-6 overflow-auto">
            <div class="w-full max-w-[700px] py-6 flex flex-col space-y-6">

                <div id="form_top" class="relative p-6 bg-white border rounded border-neutral-300 scroll-to">
                    <section-title
                        fontSmall
                        :title="form.label"
                        description="Relevant data and other details"
                        cstmTitleClass="!text-base"
                    />
                    <form class="grid grid-cols-6 gap-6 mt-6">
                        <div class="col-span-6">
                            <text-input
                                type="text"
                                label="Intervention Type Name"
                                placeholder="Intervention Type Name"
                                name="intervention_type_name"
                                id="intervention_type_name" 
                                v-model="form.label"
                            />
                        </div>
                    </form>
                </div>
        
                <div 
                    :id="'form_' + form.id" class="relative p-6 bg-white border rounded border-neutral-300 scroll-to"
                    :class="editMode == form.id ? 'shadow-[-5px_0px_0px_0px_rgba(0,113,250,1)]' : ''"
                    @click="editMode = form.id "
                >
                    <p class="font-semibold text-neutral-900">
                        {{ form.label }} Form Builder
                    </p>
                    <p class="text-sm font-medium text-gray-500">Relevant data and other details</p>
    
                    <!-- <div class="p-4 mt-6 border rounded-lg bg-primary-100 border-neutral-100">
                        <p class="text-sm text-neutral-900"><b>Note:</b> Field will only show if HIV value is Rapid Screening.</p>
                    </div> -->
                    <form class="w-full mt-6">
                        <draggable
                            :list="form.fields"
                            item-key="label"
                            class="grid grid-cols-6 gap-6"
                            handle=".handle"
                            :move="checkMove"
                            v-bind="dragOptions"
                            @start="dragging = true"
                            @end="dragging = false"
                            :component-data="{
                                tag: 'ul',
                                type: 'transition-group',
                                name: !dragging ? 'flip-list' : null
                            }"
                        >
                            <template #item="{ element }">
                                <div 
                                class="col-span-3 px-6 pt-3 pb-6 bg-white border rounded border-neutral-300"
                                :class="[ 
                                    element.is_half_width && element.input_type != FormFieldType.DROPDOWN_EXISTING  ? 'col-span-3' : 'col-span-6',
                                    selectedField == element ? 'bg-blue-600 border-2 border-blue-600' : 'bg-white border-neutral-300',
                                    ]"
                                >
                                    <div
                                        class="flex items-center justify-end w-full"
                                    >
                                        <div class="flex justify-end flex-1">
                                            <button class="w-6 h-6 cursor-grab">
                                                <!-- <img src="/assets/icon/intervention/ic-sort.svg"
                                                class="object-contain w-6 h-6 handle" > -->
                                                <sort-icon class="object-contain w-6 h-6 handle" />
                                            </button>
                                        </div>
                                        <div class="flex justify-end flex-1 space-x-2"> 
                                            <PencilSquareIcon 
                                                class="w-6 h-6 transition cursor-pointer text-neutral-900 hover:text-secondary-600" 
                                                @click="openFieldForm(element)"
                                            />
                                    
                                            <div 
                                                v-if="element.form_tag || element.report_code"
                                                class="relative group"
                                            >
                                                <InformationCircleIcon class="w-6 h-6 cursor-pointer text-primary-600" />
                                                <div class="absolute top-6 right-0 z-10 rounded bg-gray-500 p-3 text-white text-xs min-w-[220px] group-hover:block hidden">
                                                    <p>This field is connected to a report or other parts of the system. Removing this field or any of it's content is not allowed.</p>
                                                </div>
                                            </div>

                                            <TrashIcon
                                                v-else
                                                class="w-6 h-6 text-red-600 transition cursor-pointer hover:text-red-700" 
                                                @click="showRemoveFieldForm(element)"
                                            />

                                        </div>
                                    </div>
                                    <FieldType 
                                        :field="element"
                                        :errors="errors"
                                    />
                                </div>
                            </template>
                        </draggable>
                    </form>
        
                    <!-- <p class="w-full mt-6 text-sm italic text-center text-gray-500">No added fields yet</p> -->
                </div>
        
                <div class="py-2 spacer"></div>
                <!-- <button class="btn btn-secondary button" @click="$emit('add')">Add</button> -->
        
                <confirmation-modal
                    :typeTwo="true"
                    :show="showConfirmation"
                    @cancel="showConfirmation = false"
                    @confirm="removeFieldForm()"
                    cancel-text="Cancel"
                    action-text="Confirm"
                    title="Removal Warning"
                    content="This field is connected to a sub-form. Removing this field will remove the child forms as well."
                />
        
            </div>
        </div>

        <div 
            v-if="editingField"
            class="h-[calc(100vh-146px)] w-[400px] p-6 bg-white flex flex-col relative" 
        >
            <ion-skeleton-text 
                v-if="!showFieldForm"
                :animated="true" 
                style="width: 100%; height: 100%" 
            />  
    
            <div class="flex-1 overflow-auto">
                <InputType
                    v-if="showFieldForm"
                    :show="showFieldForm"
                    :form="form"
                    :item="selectedField"
                    :formField="selectedField"
                    :fieldType="selectedField.input_type"
                    :existingDropdowns="existingDropdowns"
                    editMode
                    @open="open()"
                    @close="() => close()"
                    @load:form="(form) => loadForm(form)"
                />
            </div>
        </div>

        <div 
            v-else 
            class="h-[calc(100vh-146px)] w-[400px] p-6 bg-white flex flex-col relative"
        >
            <div class="pb-2">
                <div class="text-sm text-neutral-900">
                    <p>Field Types</p>
                </div>
                <div class="flex mt-5 overflow-hidden border rounded border-neutral-100">
                    <div class="flex-1 py-3 text-center cursor-pointer"
                        @click="selected = false"
                        :class="selected ? 'text-gray-500' : 'bg-primary-100 text-primary-500'">
                        <p class="text-xs">All Fields</p>
                    </div>
                    <div class="flex-1 py-3 text-center cursor-pointer"
                        @click="selected = true"
                        :class="selected ? 'bg-primary-100 text-primary-500' : 'text-gray-500'">
                        <p class="text-xs">Fields w/ Sub-form</p>
                    </div>
                </div>
            </div>
            <div class="flex-1 overflow-auto">
                <div class="grid w-full grid-cols-2 gap-5">
                    
                    <template v-for="(item) in formFieldTypes" :key="item.id">
                        <InputType
                            v-if="selected && item.has_sub_form"
                            :form="form"
                            :formField="getDefaultForm(item)"
                            :item="item"
                            :fieldType="item.value"
                            @load:form="(form) => loadForm(form)"
                            @open="open()"
                            @close="() => close()"
                            :existingDropdowns="existingDropdowns"
                        />
                        <InputType
                            v-if="!selected"
                            :form="form"
                            :formField="getDefaultForm(item)"
                            :item="item"
                            :fieldType="item.value"
                            @load:form="(form) => loadForm(form)"
                            @open="open()"
                            @close="() => close()"
                            :existingDropdowns="existingDropdowns"
                        />
                    </template>
                </div>
            </div>
        </div>
    </div>

    <!-- Add Sub Form -->
    <content-modal
        :show="showSubfromModal" 
        title="Add Conditional Form Trigger"
    >
        <div class="flex space-x-6">
            <div class="w-1/2">
                <multi-select
                    :options="changeMeOptions"
                    label="Parent Field"
                    id="parent_field"
                    name="parent_field"
                    placeholder="Select.."
                    searchable
                />
            </div>
            <div class="w-1/2">
                <multi-select
                    :options="changeMeOptions"
                    label="Option Value"
                    id="option_value"
                    name="option_value"
                    placeholder="Select.."
                    searchable
                />
            </div>
        </div>

        <template #button>
            <button-component  
                btnWidth="quinary"
                @click="showSubfromModal = false"
            >
                Cancel
            </button-component >
            <button-component 
                customClass="primary" 
            >
                Submit
            </button-component>
        </template>
    </content-modal>

    <template v-if="showSubFrom && subFrom != null">
        <FormSection
        :form="subFrom"
        :formFieldTypes="formFieldTypes"
        />
    </template>
    
    <ion-skeleton-text 
        v-if="!showSubFrom && subFrom != null"
        :animated="true" 
        style="width: 100%; height: 100%" 
    />  
</template>
<script setup lang="ts">
import { ref, type PropType, computed } from "vue";
import { IonSkeletonText } from '@ionic/vue';

import SectionTitle from "@/components/partials/SectionTitle.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";

import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import SortIcon from "@/components/icons/sortIcon.vue";

import FormSection from "@/views/settings/InterventionTypes/FormSection.vue";

import ContentModal from "./Components/ContentModal.vue";

import draggable from "vuedraggable";
import { PencilSquareIcon, TrashIcon, InformationCircleIcon } from "@heroicons/vue/24/outline";

import { 
    FieldInterface,
    FormInterface,
} from "@/interfaces/pages/InterventionForm/InterventionFormInterface";


import InputType from "./Modals/InputType.vue";

import FieldType from "@/views/settings/InterventionTypes/Components/Inputs/FieldType.vue";
import { FormFieldType } from "@/views/enums/FormFieldType";
import InterventionTypeService from "@/classes/Settings/InterventionTypeService.ts"


const emit = defineEmits(['open', 'close']);

const props = defineProps({
    form: {
        type: Object as PropType<FormInterface>,
        required: true,
        default: () => ({ attachment: '' })
    },
    formFieldTypes: {
        type: Object as PropType<FieldInterface>,
        required: true,
        default: () => ({ attachment: '' })
    },
    existingDropdowns: {
        type: Object,
        required: true,
    },
    errors: {
        type: Object,
        required: true,
    },
});


const selected = ref<boolean>(false);
const selectedField = ref<any>({});

const showConfirmation = ref<boolean>(false)

const changeMeOptions = [
	{ id: '1', label: 'Change', value: 'Change'},
	{ id: '2', label: 'Me', value: 'Me'},
]

const showSubFrom = ref<boolean>(false);
const showSubfromModal = ref<boolean>(false);
const showFieldForm= ref<boolean>(false);
const editingField = ref<boolean>(false);

const subFrom = ref<FormInterface|null>(null);
const editMode = ref<number>(1);
const selectedRemove = ref<number|null>(null);
const selectedRemoveFieldId = ref(null);

const dragging = ref(false);

const checkMove = (e) => {
    console.log("Future index: " + e.draggedContext.futureIndex);
};

const openFieldForm = (item:FieldInterface) => {
    console.log(item);
    editingField.value = true;
    showFieldForm.value = false;
    selectedField.value = item;
    emit('open')
    setTimeout(() => {
        showFieldForm.value = true;
    }, 300);
};

const showRemoveFieldForm = (item:FieldInterface) => {
    selectedRemove.value = form.value.fields.indexOf(item)
    showConfirmation.value = true;
    selectedRemoveFieldId.value = item?.id || null;
};

const removeFieldForm = () => {
    showConfirmation.value = false;

    form.value.fields.splice(selectedRemove.value, 1);

    if (selectedRemoveFieldId.value) {
        InterventionTypeService.removeField(selectedRemoveFieldId.value)
    }
};


const loadForm = (form:FormInterface):void => {
    showSubFrom.value = false;
    subFrom.value = form
    setTimeout(() => {
        showSubFrom.value = true;
        // console.log(form.order);
        // const element = document.getElementById(form.order)
        // console.log(element);
        // element.scrollIntoView({
        //     behavior: "smooth",
        //     inline: "start",
        // });

    }, 300);
};


const close = () => {
    editingField.value = false;
    showFieldForm.value = false;
    selectedField.value = null;
    emit('close')
};

const getDefaultForm = (field:FieldInterface):any => {
    return {
        input_type: field.id,
        has_options: field?.has_options ?? false,
        has_sub_form: field?.has_sub_form ?? false,
        is_half_width: true,
        is_required: false,
        label: null,
        options: null,
        order: null,
    }
};

const form = ref<object>(props.form);

const dragOptions = computed(() => ({
    animation: 200,
}));

</script>


<style scoped>
.buttons {
    margin-top: 35px;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.not-draggable {
    cursor: no-drop;
}
</style>