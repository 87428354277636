import {maxValue, minValue, numeric, required, requiredIf, helpers } from "@vuelidate/validators"
const createRules = (form: any): any => { 
   return {
        legal_status: {
            required:  helpers.withMessage(errorMessage('legal status'), required)
        },
        housing_classification_id: {
            required: helpers.withMessage(errorMessage('housing classification'), required)
        },
        socio_economic_status_id: {
            required: helpers.withMessage(errorMessage('socio economic status'), required)
        },
        no_children_in_family: {
            numeric: numeric,
            required: helpers.withMessage(errorMessage('no of children in family'), required)
        },
        no_household_members: {
            numeric: numeric,
            required: helpers.withMessage(errorMessage('no of household members'), required)
        },
        no_family_in_household: {
            numeric: numeric,
            required: helpers.withMessage(errorMessage('no of family household'), required)
        },
        date_initiated: {
            required:  helpers.withMessage(errorMessage('date initiated'), required)
        },
   }
}

const errorMessage = (field: any) => {
   return `The ${field} field is required`;
}
export default {
   createRules,
}
