<template>
	<div>
		<div class="border rounded-lg border-neutral-100">

			<div class="flex">
				<button 
				@click="displayMode = false"
				class="flex items-center px-4 py-2 border-b border-l border-r border-solid border-neutral-100 group/mode"
				:class="!displayMode ? 'bg-primary-300' : ''">
					<!-- <img
						src="/assets/icon/intervention/ic-list.svg"
						alt="list_icon"
						class="object-contain w-6 h-6 transition"
						:class="!displayMode ? '' : 'grayscale brightness-[1.5] group-hover/mode:grayscale-0 group-hover/mode:brightness-100'" /> -->
					<list-icon class="w-6 h-6 object-contain grayscale brightness-[0.3]" :class="!displayMode ? '' : 'grayscale brightness-[1.5] group-hover/mode:grayscale-0 group-hover/mode:brightness-100'" />
					<span class="ml-2 text-xs font-bold transition md:text-base" :class="!displayMode ? 'text-primary-600' : 'text-gray-600 group-hover/mode:text-primary-600'">List View</span>
				</button>
				<button 
				@click="displayMode = true"
				class="flex items-center px-4 py-2 border-b border-r border-solid border-neutral-100 group/mode"
				:class="displayMode ? 'bg-primary-300' : ''">
					<!-- <img
						src="/assets/icon/intervention/ic-card.svg"
						alt="board_icon"
						class="object-contain w-6 h-6 transition"
						:class="displayMode ? '' : 'grayscale brightness-[1.5] group-hover/mode:grayscale-0 group-hover/mode:brightness-100'" /> -->
					<card-icon class="w-6 h-6 object-contain" :class="displayMode ? '' : 'grayscale brightness-[1.5] group-hover/mode:grayscale-0 group-hover/mode:brightness-100'" />
					<span class="ml-2 text-xs font-bold transition md:text-base" :class="displayMode ? 'text-primary-600' : 'text-gray-600 group-hover/mode:text-primary-600'">Board View</span>
				</button>
			</div>

			<div class="flex items-center justify-between p-6 border-b tabs border-neutral-100">
				<div class="flex items-center space-x-4">
					<tab-component :tabs="tabs" @update:tab="(value: string) => updateTab(value)" />
				</div>
			</div>

			<div v-if="displayMode && filters.tab != 'activity_logs'" class="flex flex-col p-4 border-b md:items-center md:justify-between border-neutral-100 md:flex-row">
				<div class="flex items-center">
					<button type="submit" class="mr-3 border-0">
						<!-- <img
							src="/assets/icon/ic-search-2.svg"
							alt="search_icon"
							class="w-5 h-5 object-contain grayscale brightness-[0.3]" /> -->
						<search-two-icon class="w-5 h-5 object-contain grayscale brightness-[0.3]" />
					</button>
					<input
						v-model="filters.query"
						type="text"
						name="search"
						id="search"
						placeholder="Search intervention, to be done by"
						class="p-0 text-neutral-600 placeholder:font-medium placeholder-neutral-400 border-0 focus:ring-0 focus:outline-none min-w-[300px]" />
				</div>
				<div class="mt-6 md:mt-0">
					<button
						type="button"
						class="flex items-center px-3 py-2 space-x-1 transition border border-solid rounded border-neutral-100 w-max hover:bg-primary-100"
						@click="showFilter = true">
						<span class="text-xs font-medium text-neutral-600">Showing:</span>
						<span class="text-xs font-semibold">All Records</span>
					</button>
					<FilterPanel
						:show="showFilter"
						@cancel="showFilter = false"
						@close="showFilter = false"
						@apply:reset="applyReset()"
						@apply:filters="applyFilters()">
						<template #fields>
							<div class="space-y-5">`
								<date-picker
									id="schedule_date"
									name="schedule_date"
									label="Schedule Date"
									v-model="filters.scheduleDate"
									class="filter-range"
									range
									multiCalendars
								/>
								<date-picker 
									id="done_on_date" 
									name="done_on_date" 
									label="Done On Date"
									class="filter-range"
									v-model="filters.doneOnDate"
									range
									multiCalendars
								/>
								<multi-select
									:options="interventionOption"
									label="Intervention"
									id="intervention"
									name="intervention"
									placeholder="Select.." 
									v-model="filters.intervention_type"
									searchable
								/>
								<multi-select
									:options="legendOption"
									label="Legend"
									id="legend"
									name="legend"
									placeholder="Select.." 
									v-model="filters.legend"
									searchable
								/>
                                <multi-select
                                    :options="caseNumbers"
                                    label="Case Number"
                                    id="select_case_number"
                                    name="select_case_number"
                                    placeholder="Select..." 
                                    v-model="filters.case_number"
                                    searchable
                                />
							</div>
						</template>
					</FilterPanel>
				</div>
			</div>
			<div v-if="filters.tab != 'activity_logs'">
				<div v-if="displayMode" class="grid grid-cols-1 gap-4 p-4 lg:grid-cols-3 md:grid-cols-2 md:p-6">
					<template v-for="item in items" :key="item">
						<intervention-card
							:item="item"
							:interventionType="item.intervention_type_name"
							:intervention="item.intervention_type_name"
							:status="item.status"
							:caseNumber="item.case_number"
							:doneBy="item.done_by"
							:doneOn="item.done_on" 
                            @update:refresh="getData()"
						/>
					</template>
				</div>
				<div v-if="!displayMode">
					<data-table
						:headers="headers"
						:no-action="false"
						:count="items.length"
						:hasSearch="true"
						searchPlaceholder="Search intervention, to be done by"
						tableClass="header-frm-table"
						dataTableClass="border-0"
						:search="filters.query"
						@update:searchText="(value: string) => (filters.query = value)"
						parentTableClass="overflow-auto">
						<template #topContent>
							<div class="mt-6 md:mt-0">
								<button
									type="button"
									class="flex items-center px-3 py-2 space-x-1 transition border border-solid rounded border-neutral-100 hover:bg-primary-100 w-max"
									@click="showFilter = true">
									<span class="text-xs font-medium text-neutral-600">Showing:</span>
									<span class="text-xs font-semibold">All Records</span>
								</button>
								<FilterPanel
									:show="showFilter"
									@apply:reset="applyReset()"
									@apply:filters="applyFilters()"
									@close="showFilter = false"
									@cancel="showFilter = false"
									@confirm="showFilter = false"
									customClass="!overflow-visible overflow-x-visible"
								>
									<template #fields>
										<div class="space-y-5">
											<date-picker
												id="schedule_date"
												name="schedule_date"
												label="Schedule Date"
												class="filter-range"
												range
												multiCalendars
												v-model="filters.scheduleDate"
												@update:modelValue="(value) => (filters.scheduleDate = value)"
											/>
											<date-picker 
												id="done_on_date" 
												name="done_on_date" 
												label="Done On Date"
												class="filter-range"
												range
												multiCalendars
												v-model="filters.doneOnDate"
												@update:modelValue="(value) => (filters.doneOnDate = value)"
											/>
											<multi-select
												:options="interventionOption"
												label="Intervention"
												id="intervention"
												name="intervention"
												placeholder="Select.." 
												v-model="filters.intervention_type"
												searchable
											/>
											<multi-select
												:options="legendOption"
												label="Legend"
												id="legend"
												name="legend"
												placeholder="Select.." 
												v-model="filters.legend"
												searchable
											/>
                                            <multi-select
                                                :options="caseNumbers"
                                                label="Case Number"
                                                id="select_case_number"
                                                name="select_case_number"
                                                placeholder="Select..." 
                                                v-model="filters.case_number"
                                                searchable
                                            />
										</div>
									</template>
								</FilterPanel>
							</div>
						</template>
						<template v-slot:body>
							<template v-for="item in items" :key="item">
								<intevention-table 
									:item="item"
									:status="item.status"
									:interventionType="item.intervention_type_name"
									:caseNumber="item.case_number"
									:doneBy="item.done_by"
									:doneOn="item.done_on" 
                                    @update:refresh="getData()"
								/>
							</template>
						</template>
					</data-table>
				</div>
				<div class="w-full px-6 border-t py-7">
					<minify-pagination
						:numRows="numRows"
						@update:rows="(value: any) => changeRows(value)"
						@update:page="(value: number) => paginate(value)"
						:current-page="filters.page"
						:last-page="lastPage"></minify-pagination>
				</div>
			</div>

			<div v-if="filters.tab == 'activity_logs'">
				<ActivityLogsTable
					:items="items"
				/>

				<div class="w-full px-6 border-t py-7">
					<simple-pagination 
						:numRows="filters.rows" 
						:currentPage="items.current_page" 
						:last-page="items.last_page" 
						@update="(value: any) => paginate(value)"
					/>
				</div>
			</div>
		</div>
	</div>
	<page-loader :show="loading" />

</template>
<script setup lang="ts">
import { onMounted, ref, watch, computed } from "vue";
import { onIonViewWillEnter } from '@ionic/vue';

import DataTable from "@/components/partials/DataTable.vue";
import MinifyPagination from "@/components/partials/MinifyPagination.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";

import TabComponent from "@/components/partials/TabComponent.vue";
import InterventionCard from "@/components/cards/InterventionCard.vue";
import { useRouter, useRoute } from "vue-router";
import { InterventionItems } from "@/interfaces/Cases/Interevention";
import throttle from "lodash/throttle";
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import PageLoader from "@/components/loaders/PageLoader.vue";
import { useNetworkStore } from '@/store/network';
import InteventionTable from "./Tables/InteventionTable.vue";
import ActivityLogsTable from "@/views/activity-logs/ActivityLogsTable.vue"
import SimplePagination from "@/components/partials/SimplePagination.vue";
import InterventionService from "@/classes/Interventions/InterventionService";
import SearchTwoIcon from "@/components/icons/searchTwoIcon.vue";
import ListIcon from "@/components/icons/listIcon.vue";
import CardIcon from "@/components/icons/cardIcon.vue";
import OnlineSynching from "@/classes/Synching/OnlineSynching";

const showFilter = ref<boolean>(false);
const displayMode = ref<boolean>(false);

const route = useRoute();
const router = useRouter();
const patient = ref<string | null>('');
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const items = ref<InterventionItems[]>([]);
const lastPage = ref<number | null>(null);
const loading = ref<boolean>(false);
const interventionOption = ref<Array<any>>([]);
const legendOption = ref<Array<any>>([]);
const onlineSync = new OnlineSynching();

const filters = ref<{
	scheduleDate: Array<any>
	doneOnDate: Array<any>
	query: string
	page :number
	rows :number
	tab :string|null
	intervention_type : number|null
	legend : number|null
    case_number: string | null,
}>({
	scheduleDate: [],
	doneOnDate: [],
	query: '',
	page: 1,
	rows: 20,
	tab: route.query.tab || '',
	intervention_type: null,
	legend: null,
    case_number: null,
})

const numRows = computed<string | null>(() => (filters.value.rows ? filters.value.rows + " Rows" : "All"));

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const offlineParams = ref<string>(route.query.offline);
const caseNumbers = ref<[]>([]);

const tabs = ref<{ name: string; count: number; tab: string; href: string; allowed: boolean }[]>([
	{
		name: "All",
		count: 0,
		tab: "",
		href: caseId.value
			? `/patient/${patientId.value}/cases/${caseId.value}/interventions`
			: `/intervention/${patientId.value}/view`,
		allowed: true,
	},
	{
		name: "Archived",
		count: 0,
		tab: "archived",
		href: caseId.value
			? `/patient/${patientId.value}/cases/${caseId.value}/interventions?tab=archived`
			: `/intervention/${patientId.value}/view?tab=archived`,
		allowed: true,
	},
	{
		name: "Activity logs",
		count: 0,
		tab: "activity_logs",
		href: caseId.value
			? `/patient/${patientId.value}/cases/${caseId.value}/interventions?tab=activity_logs`
			: `/intervention/${patientId.value}/view?tab=activity_logs`,
		allowed: true,
	},
]);

const headers: { text: string; haveSort?: boolean }[] = [
	{ text: "Schedule Date/Time", haveSort: true },
	{ text: "Intervention" },
	{ text: "Type" },
	{ text: "Sub-type" },
	{ text: "Case Number" },
	{ text: "To Be Done By" },
	{ text: "Done On" },
	{ text: "Status" },
];

const emit = defineEmits(['update:patientName', 'update:interventionType']);

const getData = (search: any = null): void => {
	loading.value = true;
	InterventionService.index(
		patientId.value,
		caseId.value,
		filters.value,
		isNetworkAvailable.value
	)
		.then(async ({data}) => {
			emit('update:patientName', data.patient); 
			emit('update:interventionType', data.interventionType); 
			
			patient.value = data.patient;
			interventionOption.value = data.interventionType;
			legendOption.value = data.legends;
			filters.value.page = data.items.current_page;
			lastPage.value = data.items.last_page;
            caseNumbers.value = data.caseNumbers;
			
			tabs.value[0].count = data.allItemsCount;
			tabs.value[1].count = data.archivedItemsCount;
			tabs.value[2].count = data.activityLogsCount;

			
			if(filters.value.tab == 'activity_logs'){
				items.value = data.items;
			} else {
				items.value = data.items.data;

				await storePatientInterventionsForOffline(items.value);
			}

			loading.value = false;
		})
		.catch((error) => {
			console.log(error);
			loading.value = false;
		});
};

const storePatientInterventionsForOffline = async (items: any): Promise<void> => {
	if(items.length > 0 && isNetworkAvailable.value) {
		await InterventionService.storeOffline(items)
	}
};

const reloadPage = async () => {
	return await router.push({
		path: caseId.value
			? `/patient/${patientId.value}/cases/${caseId.value}/interventions`
			: `/intervention/${patientId.value}/view`,
		query: pickBy(filters.value),
	});
};

const applyFilters = () => {
	reloadPage().then(() => {
		showFilter.value = false
		getData();
	});
};

const applyReset = () => {
	filters.value = {
		scheduleDate: [],
		doneOnDate: [],
		query: '',
		page: 1,
		rows: 20,
		tab: '',
		intervention_type: null,
		legend: null
	}
	
	reloadPage().then(() => {
		getData();
		showFilter.value = false;
	});
};

const paginate = (data: number|object): void => {
	if(filters.value.tab == 'activity_logs'){

		filters.value = Object.assign(filters.value, data)
	} else {
		filters.value.page = data;
	}
	applyFilters();
};

const changeRows = (row: any): void => {
	filters.value.rows = row;
	applyFilters();
};

const updateTab = (currentTab: string) => {
	filters.value.tab = currentTab;
	filters.value.page = 1;
	applyFilters();
};

/**
 * WATCHERS
 */
watch(
	() => filters.value.query,
	debounce((val: string) => {
		reloadPage().then(() => {
			getData(val);
		});
	}, 1000)
);

watch(
	() => isNetworkAvailable.value,
	async (val) => {
        loading.value = false;
		await getData();
        await onlineSync.checkDrafts();
	}
);

onMounted(() => getData() );
onIonViewWillEnter(async () => {
    await getData();
    await onlineSync.checkDrafts();
} );
</script>
