<template>
	<user-layout>
		<div class="w-full py-6 mx-auto">
			<div class="flex flex-col mb-6 md:items-end md:justify-between md:flex-row">
				<section-title title="Patient" description="View the data analytics in the system." />
				<div class="flex flex-col mt-6 space-y-3 md:items-center md:space-x-3 md:space-y-0 md:flex-row md:mt-0">
					<a v-if="hasPermission('can-search-patients')" @click="router.replace('/patient/search')">
						<button-component customClass="quaternary md:w-auto w-full">
							<!-- <img
								src="/assets/icon/ic-search.svg"
								alt="search_icon"
								class="object-contain w-4 h-4 mr-1 -ml-2 icon" /> -->
							<search-icon class="object-contain w-4 h-4 mr-1 -ml-2 icon" />
							<span>Search</span>
						</button-component>
					</a>
					<a
						v-if="hasPermission('can-create-patient-information')"
						@click="router.replace('/patient/create')">
						<button-component customClass="primary md:w-auto w-full">
							<PlusIcon class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
							<span>Add Record</span>
						</button-component>
					</a>
				</div>
			</div>

			<div>
				<data-table
					:headers="headers"
					:no-action="false"
					:count="items?.length"
					tableClass="header-frm-table two three four five">
					<template v-slot:body>
						<template v-for="item in items" :key="item">
							<tr>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									<div class="flex items-center">
										
										<div class="w-6 h-6 bg-[#D2851B] rounded-full object-cover text-center mr-2" v-if="item?.patient?.abbr">
											<span class="text-white text-[0.625rem] font-bold leading-6">{{
												item.patient?.abbr
											}}</span>
										</div>
                                        <img v-else class="object-cover w-6 h-6 mr-2 text-center rounded-full" :src="item?.patient?.profile_photo_url || item.profile_photo_url" />
										<span class="text-sm">{{ item?.patient?.first_name || item.first_name }}</span>
									</div>
								</td>
								<td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap">
									{{ item?.patient?.last_name || item.last_name }}
								</td>
								<td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap">
									{{ item?.patient?.gender || item.gender }}
								</td>
                                <td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap" v-if="item?.patient?.birthdate">
									{{ item?.patient?.birthdate ? DateFormatter.customDateFormat(item?.patient?.birthdate, 'MM/dd/yyy') : item?.patient?.computed_age }}
								</td>
								<td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap" v-else>
									{{ item?.birthdate ? DateFormatter.customDateFormat(item?.birthdate, 'MM/dd/yyy') : item.computed_age }}
								</td>
								<td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap">
									{{ item.primary_diagnosis || item.primary_diagnoses }}
								</td>
                                <td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap" v-if="item?.patient?.registered_at">
									{{ DateFormatter.customDateFormat(item?.patient?.registered_at, 'MM/dd/yyy - hh:mm:ss') }}
								</td>
								<td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap" v-else>
									{{ DateFormatter.customDateFormat(item.registered_at, 'MM/dd/yyy - hh:mm:ss') }}
								</td>
								<td class="block px-6 py-6 space-y-3 text-sm text-gray-900">
									<div class="flex items-center justify-center mx-auto">
										<view-button
											class="min-w-[130px]"
											custom-class="flex items-center justify-center w-full"
											v-if="hasPermission('can-view-patients-information')"
											@click="
												// router.replace(
												// 	`/patient/${item.patient_id ? item.patient_id : item.offlineData?.offline_id}/view${
												// 		item.id ? '' : '?offline=true'
												// 	}`
												// )
                                                router.replace(`/patient/${item.patient_id}/view`)
											">
											<p class="pl-1 text-white"> View Patient </p>
										</view-button>
									</div>
									<div class="flex items-center justify-center mx-auto">
										
										<view-button
											class="min-w-[130px]"
											custom-class="flex items-center justify-center w-full"
											v-if="hasPermission('can-view-patients-information')"
											@click="
												// router.replace(
												// 	`/patient/${item.patient_id ? item.patient_id : item.offlineData?.offline_id}/cases/${item.id}/edit`
												// )
                                                router.replace(`/patient/${item.patient_id}/cases/${item.id}/edit`)
											"> 
											<p class="pl-1 text-white"> View Case </p>
										</view-button>
									</div>
								</td>
							</tr>
						</template>
					</template>
					<template #tfoot>
						<div class="w-full px-6 border-t py-7">
							<minify-pagination
								:numRows="numRows"
								@update:rows="(value: any) => changeRows(value)"
								@update:page="(value: number) => paginate(value)"
								:current-page="page"
								:last-page="lastPage"></minify-pagination>
						</div>
					</template>
				</data-table>
			</div>
		</div>
		<page-loader :show="loading" />
	</user-layout>
</template>
<script setup lang="ts">
import { ref, computed, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ViewButton from "@/components/buttons/ViewButton.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import DataTable from "@/components/partials/DataTable.vue";
import MinifyPagination from "@/components/partials/MinifyPagination.vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import { useRouter, useRoute } from "vue-router";
import PatientService from "@/classes/PatientService";
import pickBy from "lodash/pickBy";
import Storage from "@/helpers/storage";
import PageLoader from "@/components/loaders/PageLoader.vue";
import { hasPermission } from "@/classes/AuthService";
import { useNetworkStore } from "@/store/network";
import DateFormatter from "@/helpers/DateFormatter"; 
import { onIonViewWillEnter } from "@ionic/vue";
import SearchIcon from "@/components/icons/searchIcon.vue"; 
import PatientRecordService from "@/classes/Patients/PatientRecordService";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const router = useRouter();
const route = useRoute();
const storage = new Storage();

/**
 * VARIABLES
 */
interface itemsInterface {
	id: number;
	last_name: string;
	first_name: string;
	middle_name: string;
	nickname: string;
	birthdate: string;
	created_at_formatted: string;
	abbr: string;
	gender_meta: object;
}
 
const items = ref<itemsInterface[]>([]);
const page = ref<string | null>(route.query.page);
const lastPage = ref<number | null>(null);
const rows = ref<number | null>(route.query.rows || 5);
const numRows = computed<string | null>(() => (rows.value ? rows.value + " Rows" : "All"));
const lastName = ref<string | null>(route.query.last_name);
const birthdate = ref<string | null>(route.query.birthdate);
const firstName = ref<string | null>(route.query.first_name);
const loading = ref<boolean>(false);
const headers: { text: string; haveSort?: boolean }[] = [
	{ text: "First Name" },
	{ text: "Last Name" },
	{ text: "Sex" },
	{ text: "Birthday" },
	{ text: "Primary Diagnosis" },
	{ text: "Date Registered", haveSort: false },
];

const getData = async () => {
	loading.value = true;

    // Call this service when network is online or offline
    const response = await PatientRecordService.search({
        last_name: lastName.value,
        first_name: firstName.value,
        birthdate: birthdate.value,
        page: page.value,
        rows: rows.value,
    }, isNetworkAvailable.value);

    if (response?.response?.status === 404) {
        loading.value = false;
        return;
    }
    
    if (response?.status === 200 || response?.data != undefined) {
        storage.set(
            "search",
            JSON.stringify({
                last_name: lastName.value,
                first_name: firstName.value,
                birthdate: birthdate.value,
            })
        );
        page.value = response.data.items.current_page;
        lastPage.value = response.data.items.last_page;
         
        items.value = response.data.items.data;
        
        loading.value = false;
    } else {
        const error = response;
        loading.value = false;
        console.log(error);
    }
};

const reloadPage = async () => {
	return await router.push({
		path: "/patient/index",
		query: pickBy({
			last_name: lastName.value,
			first_name: firstName.value,
			birthdate: birthdate.value,
			page: page.value,
			rows: rows.value,
		}),
	});
};

const applyFilters = async () => {
    await reloadPage();
    await getData();
};

const paginate = async (data: number): void => {
	page.value = data;
	await applyFilters();
};

const changeRows = async (row: any): void => {
	rows.value = row;
	await applyFilters();
};

const getSearchFromStorage = async (): void => {
	const searchData = await JSON.parse(storage.get("search"));
	lastName.value = searchData ? searchData.last_name : lastName.value;
	firstName.value = searchData ? searchData.first_name : firstName.value;
	birthdate.value = searchData ? searchData.birthdate : birthdate.value;
};

watch(
    () => isNetworkAvailable.value,
    () => {
        loading.value = false;
        getData();
    }
);

onIonViewWillEnter(async () => {
    await getSearchFromStorage();
    await applyFilters();
});

</script>
