<template>
    <intake-layout
        :isShowNav="isShowNav"
        :patient="patient"
    >


        <template #header>
            <p class="text-xl font-semibold">Edit Persons Related to Case</p>
        </template>


        <template #actionButtons>


            <!-- Edit -->
            <template v-if="!isShowNav">
                <button-component
                    btnWidth="quinary"
                    fill="outline"
                    @click="isShowNav = true"
                >
                    Cancel
                </button-component>
                <button-component
                    @click="validateForm()"
                >
                    Save Changes
                </button-component>
            </template>
        </template>


        <!-- Persons Case Information -->
        <template v-if="isShowNav">
            <div class="col-span-12">
                <data-table
                    :headers="headers"
                    :no-action="false"
                    :count="items?.data?.length"
                    dataTableClass="border-t-0 border-x-0 "
                >
                    <template #body>
                        <template v-for="item in items?.data" :key="item?.id"> 
                            <tr v-if="item?.id > 0">
                                <td class="p-6 text-sm font-normal text-gray-900 ">
                                    {{ item?.name }}
                                </td>
                                <td class="text-sm font-normal text-gray-900">
                                    {{ item?.gender_formatted }}
                                </td>
                                <td class="text-sm font-normal text-gray-900">
                                    {{ item?.companion_relationship ?? "" }}
                                </td>
                                <td class="text-sm font-normal text-gray-900">
                                    <p v-if="BirthdayClassification.BIRTHDATE == item?.birthday_classification">  {{DateFormatter.customDateFormat(item?.birthdate, 'MM/dd/yyy')}} </p>
                                    <p v-else-if="BirthdayClassification.APPROXIMATE_AGE == item?.birthday_classification"> Approximate age </p>
                                    <p v-else> Unknown </p>
                                </td>
                                <td class="text-sm font-normal text-gray-900">
                                    {{ item?.computed_age }}
                                </td>
                                <td class="text-sm font-normal text-gray-900">
                                    {{ item?.contacts ? "+63"+item?.contacts : '-' }}
                                </td>
                                <td class="flex justify-center p-6 space-x-2">
                                    <view-button
                                        :isEyeIcon="false"
                                        @click="showForm(item)"
                                        class="shrink-0"
                                    />
                                    <delete-button
                                        class="shrink-0"
                                        @click="showConfirmationModal(item?.id)"
                                    />
                                </td>
                            </tr>
                        </template>
                    </template>
                </data-table>
            </div>


            <div class="flex justify-end col-span-12"> 
                <button-component
                    customClass="primary sm:w-auto w-full"
                    @click="addNew()"
                >
                    <PlusIcon class="w-3.5 h-3.5 "/>
                    <p>Add New</p>
                </button-component>
            </div>
        </template>


        <!-- Edit Page -->
        <template v-else>
            <div class="col-span-12">
                <edit-page
                    ref="editForm"
                    :form="form"
                    :errors="errors"
                    :optionRelationChild="companionRelationship"
                    :optionBirthday="birthdayClassification"
                    :optionSelected="personRelatedType"
                    :optionCivilStatus="civilStatus"
                    :optionYerOrNo="optionYerOrNo"
                    :optionEducationAttain="educationalAttainment"
                    :optionHousingClass="housingClassification"
                    :optionGenders="genders"
                />
            </div>
        </template>
       
        <!-- MODAL -->
        <success-modal
            noBtn
            :show="showSuccess"
            action-text="Close"
            title="Record Save"
            content="Intake has been updated"
            @close="showSuccess = false"
        />


        <confirmation-modal
            :show="showConfirmation"
            @cancel="showConfirmation = false"
            @confirm="archive()"
            cancel-text="Cancel"
            action-text="Confirm"
            title="Delete Record"
            content="Are you sure you wish to delete this Person?"
        />


        <page-loader :show="loading"/>
    </intake-layout>


</template>


<script setup lang="ts">
import PageLoader from "@/components/loaders/PageLoader.vue"
import IntakeLayout from '../../Component/IntakeLayout.vue';
import ViewButton from "@/components/buttons/ViewButton.vue";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import DataTable from "@/components/partials/DataTable.vue";
import EditPage from './EditPage.vue';
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";


import { ref, onMounted,computed } from 'vue';
import { PlusIcon } from "@heroicons/vue/24/solid";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { useRouter, useRoute } from "vue-router";
import PersonRelatedToCaseService from '@/classes/Cases/IntakeForms/PersonRelatedToCaseService';
import { OptionInterface } from '@/interfaces/OptionInterface';
import { BirthdayClassification } from "@/views/enums/BirthdayClassification";
import { errorResponse } from "@/helpers/errorMessage";
import DateFormatter from "@/helpers/DateFormatter";
import { onIonViewWillEnter } from "@ionic/vue";

 
 /******************************* For Offline Imports************************************ */
 import { useNetworkStore } from "@/store/network";
 import Rules from "@/classes/Cases/Intake/RelatedToCase/Validation/Rules";
 import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
 import useVuelidate from "@vuelidate/core";
 import Relationships from "@/classes/Forms/Relationships";
 import RelatedToCaseService from "@/classes/Cases/Intake/RelatedToCase/RelatedToCaseService";
 import RelatedToCaseForm from "@/classes/Cases/Intake/RelatedToCase/Formatters/RelatedToCaseForm";
 import PersonRelatedOfflineProcessor from "@/classes/Cases/Intake/RelatedToCase/Processor/OfflineProcessor";
 import OnlineSynching from "@/classes/Synching/OnlineSynching";
 import CaseGuardianOfflineProcessor from "@/classes/Cases/CaseGuardian/Offline/Processor/OfflineProcessor";

 const { createRules } = Rules;
 const networkStore = useNetworkStore();
 const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
 const relationship = new Relationships()
 /*************************************************************************************** */
 
const route = useRoute();
const form = ref({})
const errors = ref<any>();

const isShowNav = ref<boolean>(true);
const showSuccess = ref<boolean>(false);
const loading = ref<boolean>(false);
const showConfirmation = ref<boolean>(false);
const editForm = ref<any>({});

const selectedItem = ref<number>(0);
const route_params = route.params;
const onlineSync = new OnlineSynching();

const headers = [
    { text: 'Full Name' },
    { text: 'Gender' },
    { text: 'Relationship' },
    { text: 'Birthdate' },
    { text: 'Age' },
    { text: 'Contact Number' }
]
const birthdayClassification = ref<OptionInterface[]>([]);
const personRelatedType = ref<OptionInterface[]>([]);
const companionRelationship = ref<OptionInterface[]>([]);
const civilStatus = ref<OptionInterface[]>([]);
const educationalAttainment = ref<OptionInterface[]>([]);
const housingClassification = ref<OptionInterface[]>([]);
const genders = ref<OptionInterface[]>([]);
const patient = ref<string>('');
const isIonWillEnter = ref<boolean>(false);
const patientCase = ref(route_params?.case);
const optionYerOrNo = [
    { id: 0, value: 0, label: 'No' },
    { id: 1, value: 1, label: 'Yes' },
]

const data = {
    id:  0,
    name: '-',
    gender_formatted: '-',
    companion_relationship: '-',
    birthdate: null,
    computed_age: '-',
    contacts: '-'
}


const items = ref({
    data: [ data ]
})

const addNew = () => {
    isShowNav.value = false;
    errors.value = null;
    Object.assign(form.value, RelatedToCaseForm.createForm(patientCase)); 
}

const save = async () => {
    loading.value = true;
    form.value = editForm.value.formData;
     
    if(form.value.id){
        await RelatedToCaseService.update(form.value.id, route_params?.patient, route_params?.case, isNetworkAvailable.value, form.value)
        .then(async({data}) => {
            loading.value = false; 
            items.value = data.items;
            showSuccess.value = true;
            isShowNav.value = true;
            await fetch();
            if(isNetworkAvailable.value){
                await PersonRelatedOfflineProcessor.syncToOffline(route_params?.case, form.value);
                setTimeout(() => {
                    location.reload()
                }, 1000);
                // clearForm()
            }
        })
        .catch(error => {
            loading.value = false;
            errors.value = errorResponse(error.response?.data?.errors)
        })
    } else {
        await RelatedToCaseService.store(form.value.id, route_params?.patient, route_params?.case,isNetworkAvailable.value, form.value)
        .then(async({data}) => {
            loading.value = false; 
            items.value = data.items;
            showSuccess.value = true;
            isShowNav.value = true;
            await fetch();
            if(isNetworkAvailable.value){
                await PersonRelatedOfflineProcessor.syncToOffline(route_params?.case, form.value);
                setTimeout(() => {
                    location.reload()
                }, 1000);
                // clearForm()
            }
        })
        .catch(error => {
            loading.value = false;
            errors.value = errorResponse(error.response?.data?.errors)
        })
    }  
}


const fetch = async ():void => {
    loading.value = true;
    // PersonRelatedToCaseService
    await RelatedToCaseService
        .index(route_params?.patient, route_params?.case, isNetworkAvailable.value)
        .then(async ({data}) => {
            console.log(data.items);
            items.value = data.items;
            console.log(items.value.data);
            if (isNetworkAvailable.value) {
                await syncToOffline();
            }
            birthdayClassification.value = data.birthdayClassification
            personRelatedType.value = data.personRelatedType
            companionRelationship.value = data.companionRelationship
            civilStatus.value = data.civilStatus
            educationalAttainment.value = data.educationalAttainment
            housingClassification.value = data.housingClassification
            patient.value = data.patient
            genders.value = data.genders
            loading.value = false;
        })
        .catch(errors => {
            console.log(errors)
        })
}

const syncToOffline = async () => {
    for (const index in items.value.data) {
        const item = items.value.data[index];
        await PersonRelatedOfflineProcessor.syncToOffline(route_params?.case, item);
    }
}
 
 /**
 * Validate the form using Vuelidator
 */
 const validateForm = () =>{
    form.value = editForm.value.formData;
	const vuelidate = useVuelidate(createRules({...form.value}), form.value)
	const result = vuelidate.value.$validate();

	result
		.then((res) => {
			if(res) {
				save()
			} else {
				errors.value = ErrorValidation.getErrors(vuelidate.value)
                errorResponse(errors.value);
			}
		}) 
 }
 
const showConfirmationModal = (item:number):void => {
    showConfirmation.value = true;
    selectedItem.value = item
}


const archive = async ():void => {
    loading.value = true;
    showConfirmation.value = false;

    await RelatedToCaseService
    .archive(route_params?.patient, route_params?.case, selectedItem.value, isNetworkAvailable.value)
    .then(async ({data}) => {
        if (isNetworkAvailable.value) {
            await CaseGuardianOfflineProcessor.delete(selectedItem.value, route_params?.case, true);
        }
        await fetch()
        loading.value = false;
    })
    .catch(errors => {
        console.log(errors)
        loading.value = false;
    })
}

const showForm = (item:any) => { 
    errors.value = null;
    Object.assign(form.value, RelatedToCaseForm.updateForm(item));
    form.value.cases_id = route_params?.case;
    isShowNav.value = false;
} 

 
onIonViewWillEnter(async () => {
    if (isIonWillEnter.value) {
        await fetch(); 
        await onlineSync.checkDrafts();
    }
});

onMounted(async () => {
    await fetch();
    await onlineSync.checkDrafts();
    isIonWillEnter.value = true;
})


</script>