<template>
	<intake-layout :patient="patient">
        <template #actionButtons>
			<button-component btnWidth="quinary">Cancel</button-component>
			<button-component 
				customClass="primary" 
				@click="validateForm()"
			>
				Save Changes
			</button-component>
		</template>

		<div class="col-span-12">
			<p class="text-xs font-semibold text-neutral-600">Regular sleeping arrangement 
				<span class="text-red-600">*</span>
			</p>

			<div class="flex lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-3">
				<div class="flex md:flex-row flex-col flex-shrink-0 md:mt-5 mt-3 md:space-x-3 md:space-y-0 space-y-3">
					<radio-group 
						:options="sleepingArrangement" 
						name="option_selected" 
						required 
						id="sleeping_arrangement" 
						v-model="form.sleeping_arrangement" 
						:error="errors?.sleeping_arrangement"
					/>
				</div>
				
				<text-input
					v-if="form.sleeping_arrangement === SleepingArrangement.SHELTER || form.sleeping_arrangement === SleepingArrangement.OTHERS"
					:label="form.sleeping_arrangement === SleepingArrangement.SHELTER ? 'Please specify shelter' : 'Others'"
					:placeholder="form.sleeping_arrangement === SleepingArrangement.SHELTER ? 'Please specify shelter' : 'Others'"
					name="specify"
					id="others"
					v-model="form.others"
					:error="errors?.others"
				/>
			</div>
		</div>

		<div class="col-span-12">
			<p class="text-xs font-semibold text-neutral-600">Same bed  / mat with: <span class="text-neutral-400">(check all that apply)</span></p>

			<div class="flex md:flex-row flex-col flex-shrink-0 md:mt-5 mt-3 md:space-x-6 md:space-y-0 space-y-3" id="same_bed_with">
				<checkbox-input
					v-for="option in sleepingArrangementWith"
					:key="option.label"
					:id="'bed' +option.id"
					:name="option.value"
					:label="option.label"
					:checked="form.same_bed_with.indexOf(option.id) > -1"
					@click="removeAddRelatedTypes(form.same_bed_with, option.id)"
				/>
			</div>
			<p
				v-if="errors?.same_bed_with"
				class="text-[10px] mt-1 text-red-600 "
				id="email-error"
			>
				{{ errors?.same_bed_with }}
			</p>
		</div>

		<div class="col-span-12">
			<p class="text-xs font-semibold text-neutral-600">Same room with: <span class="text-neutral-400">(check all that apply)</span></p>

			<div class="flex md:flex-row flex-col flex-shrink-0 md:mt-5 mt-3 md:space-x-6 md:space-y-0 space-y-3" id="same_room_with">
				<checkbox-input
					v-for="option in sleepingArrangementWith"
					:key="option.label"
					:id="'room' + option.id"
					:name="option.value"
					:label="option.label"
					:checked="form.same_room_with.indexOf(option.id) > -1"
					@click="removeAddRelatedTypes(form.same_room_with, option.id)"
				/>
			</div>
			<p
				v-if="errors?.same_room_with"
				class="text-[10px] mt-1 text-red-600 "
				id="email-error"
			>
				{{ errors?.same_room_with }}
			</p>
		</div>

		<div class="col-span-12 flex lg:flex-row flex-col align-baseline gap-5 lg:pr-5">
			<div class="lg:w-1/2 flex-shrink-0 border rounded p-4">
				<p class="text-xs font-semibold text-neutral-600 mb-2">Living arrangement at time of abuse: <span class="text-neutral-400">(check all that apply)</span></p>

				<div class="sm:columns-2 incest_checklist--livingArr gap-0">
					<div
						v-for="option in livingArrangements"
						:key="option.label"
						class="sm:px-2 sm:py-2 py-1.5"
					>
						<checkbox-input
							:id="'abuse' + option.id"
							:name="option.value"
							:label="option.label"
							:checked="form.abused_living_arrangement?.indexOf(option.id) > -1"
							@click="removeAddRelatedTypes(form.abused_living_arrangement, option.id)"
						/>

						<p
							v-if="errors?.abused_living_arrangement"
							class="text-[10px] mt-1 text-red-600 "
							id="email-error"
						>
							{{ errors?.abused_living_arrangement }}
						</p>
					</div>
				</div>
			</div>
			<div class="lg:w-1/2 flex-shrink-0 border rounded p-4">
				<p class="text-xs font-semibold text-neutral-600 mb-2">Living arrangement at present: <span class="text-neutral-400 sm:inline-block block">(check all that apply)</span></p>

				<div class="sm:columns-2 incest_checklist--livingArr gap-0">
					<div
						v-for="option in livingArrangements"
						:key="option.label"
						class="sm:px-2 sm:py-2 py-1.5"
					>
						<checkbox-input
							:id="'present' + option.id"
							:name="option.value"
							:label="option.label"
							:checked="form.present_living_arrangement?.indexOf(option.id) > -1"
							@click="removeAddRelatedTypes(form.present_living_arrangement, option.id)"
							/>

						<p
							v-if="errors?.present_living_arrangement"
							class="text-[10px] mt-1 text-red-600 "
							id="email-error"
						>
							{{ errors?.present_living_arrangement }}
						</p>
					</div>
				</div>
			</div>
		</div>

		<!-- MODAL -->
		<success-modal
			noBtn
			:show="showSuccess"
			action-text="Close"
			title="Record Save"
			content="Intake has been updated" 
            @close="showSuccess = false"
		/>
		<page-loader :show="loading"/>
	</intake-layout>
	
</template>

<script setup lang="ts">
import PageLoader from "@/components/loaders/PageLoader.vue"
import IntakeLayout from '../../Component/IntakeLayout.vue';
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import { ref, onMounted, watch, computed } from 'vue';
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { useRoute } from "vue-router";
import { OptionInterface } from '@/interfaces/OptionInterface'; 
import { SleepingArrangement } from '@/enums/SleepingArrangement';
import { errorResponse } from "@/helpers/errorMessage"; 
import { onIonViewWillEnter } from "@ionic/vue"; 
 
 
 /******************************* For Offline Imports************************************ */
 import { useNetworkStore } from "@/store/network";
 import Rules from "@/classes/Cases/Intake/Incest/Validation/Rules";
 import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
 import useVuelidate from "@vuelidate/core"; 
 import IncestForm from "@/classes/Cases/Intake/Incest/Formatter/IncestForm"; 
 import IncestService from "@/classes/Cases/Intake/Incest/IncestService";
 import IncestOfflineProcessor from "@/classes/Cases/Intake/Incest/Processor/OfflineProcessor";
 import OnlineSynching from "@/classes/Synching/OnlineSynching";

 const { createRules } = Rules;
 const networkStore = useNetworkStore();
 const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable); 
 /*************************************************************************************** */
 
const route = useRoute();
const showSuccess = ref<boolean>(false);
const loading = ref<boolean>(false);
const errors = ref<any>();
const patient = ref<string>(''); 

const route_params = route.params; 

const form = ref(IncestForm.createForm());

const sleepingArrangement = ref<OptionInterface[]>([])
const sleepingArrangementWith = ref<OptionInterface[]>([])
const livingArrangements = ref<OptionInterface[]>([])
const isIonWillEnter = ref<boolean>(false);
const onlineSync = new OnlineSynching();

let incestId;
const fetch = async (): Promise<any> => {
	loading.value = true
 
	await IncestService
	.show(route_params?.patient, route_params?.case, isNetworkAvailable.value)
	.then(async ({data}) => {
		console.log(data);
		sleepingArrangement.value = data.sleepingArrangement
		patient.value = data.patient
		sleepingArrangementWith.value = data.sleepingArrangementWith
		livingArrangements.value = data.livingArrangements
		
		Object.assign(form.value, data.item); 
		incestId = form.value.id;
        if (isNetworkAvailable.value) {
            await syncToOffline();
        }
		loading.value = false
	})
	.catch(errors => {
		console.log(errors);
	})
   
}

const syncToOffline = async () => {
    await IncestOfflineProcessor.syncToOffline(route_params?.case, form.value);
}
 
 /**
 * Validate the form using Vuelidator
 */
 const validateForm = () =>{
	const vuelidate = useVuelidate(createRules({...form.value}), form.value)
	const result = vuelidate.value.$validate();
	result
		.then((res) => {
			if(res) { 
				update()
			} else {
				errors.value = ErrorValidation.getErrors(vuelidate.value)
                errorResponse(errors.value);
			}
		})
 }
 

const update = async (): Promise<any> => {
	loading.value = true 
	await IncestService
		.update(incestId,route_params?.patient, route_params?.case, isNetworkAvailable.value, form.value, )
		.then(async ({data}) => {
			loading.value = false
			showSuccess.value = true;
            errors.value = {};
			await fetch(); 
            setTimeout(() => showSuccess.value = false, 2000);
		})
		.catch(error => {
			errors.value = errorResponse(error.response?.data?.errors)
			loading.value = false
		})
    
}


const removeAddRelatedTypes = (list:any, item:number): void => {
	const index = list.indexOf(item)
 
	if(index > -1){
		list.splice(index, 1);
	} else {
		list.push(item);
	} 
}

watch(
	()=> form.value.sleeping_arrangement,
	()=>{
		if(form.value.sleeping_arrangement !== SleepingArrangement.OTHERS
			&& form.value.sleeping_arrangement !== SleepingArrangement.SHELTER
		){
			form.value.others = null;
		}
	}
)
watch(
	() => [form.value.abused_living_arrangement, form.value.present_living_arrangement],
	([abused, present]) => {
		if(!abused){
			form.value.abused_living_arrangement = [];
		}
		if(!present) {
			form.value.present_living_arrangement = [];
		}
	}
)
watch(
	()=> form.value.sleeping_arrangement,
	()=>{
		if(form.value.sleeping_arrangement !== SleepingArrangement.OTHERS
			&& form.value.sleeping_arrangement !== SleepingArrangement.SHELTER
		){
			form.value.others = null;
		}
	}
)
watch(
	()=> form.value.sleeping_arrangement,
	()=>{
		if(form.value.sleeping_arrangement !== SleepingArrangement.OTHERS
			&& form.value.sleeping_arrangement !== SleepingArrangement.SHELTER
		){
			form.value.others = null;
		}
	}
)

onIonViewWillEnter(async () => {
    if (isIonWillEnter.value) {
        errors.value = null;
        await fetch();
        await onlineSync.checkDrafts();
    }
});

onMounted(async () => {
	await fetch();
    await onlineSync.checkDrafts();
    isIonWillEnter.value = true;
    errors.value = null;
})

</script>