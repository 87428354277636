<template>
	<user-layout>
		<div class="w-full py-6 mx-auto">
			<section-title title="Intervention: Patient" description="View the data analytics in the system." />
			<div class="flex items-center mt-5 mb-6 space-x-3">
				<a @click="router.replace(`/intervention/search`)">
					<button-component customClass="quaternary md:w-auto w-full">
						<!-- <img
							src="/assets/icon/ic-search.svg"
							alt="search_icon"
							class="object-contain w-4 h-4 mr-1 -ml-2 icon" /> -->
						<search-icon class="object-contain w-4 h-4 mr-1 -ml-2 icon" />
						<span>Search</span>
					</button-component>
				</a>
			</div>

			<div>
				<data-table
					:headers="headers"
					:no-action="false"
					:count="items?.length"
					tableClass="header-frm-table two three">
					<template v-slot:body>
						<template v-for="item in items" :key="item">
							<tr>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									<div class="flex items-center">

										<img class="object-cover w-6 h-6 mr-2 text-center rounded-full" :src="item.profile_photo_url" />
										<!-- <div>
											<span class="text-white text-[0.625rem] font-bold leading-6">{{
												item.profile_photo_url
											}}</span>
										</div> -->
										<span class="text-sm">{{ item.first_name }} {{ item.last_name }}</span>
									</div>
								</td>
								<td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap">
									{{ item?.birthdate ? DateFormatter.customDateFormat(item?.birthdate, 'MM/dd/yyy') : item.computed_age }}
								</td>
								<td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap">
									{{ DateFormatter.customDateFormat(item.registered_at, 'MM/dd/yyy - hh:mm:ss') }}
								</td>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									<div class="flex items-center justify-center mx-auto">
										<a @click="router.replace(`/intervention/${item.patient_id}/view`)">
											<button-component
												customClass="primary w-max"
											>
												<!-- <img
													src="/assets/icon/ic-paper.svg"
													alt="paper_icon"
													class="w-4 h-4 mr-1 -ml-2 icon" /> -->
												<paper-icon class="w-4 h-4 mr-1 -ml-2 icon" />
												<span>View Record</span>
											</button-component>
										</a>
									</div>
								</td>
							</tr>
						</template>
					</template>
					<template #tfoot>
						<div class="w-full px-6 border-t py-7">
							<minify-pagination
								:numRows="numRows"
								@update:rows="(value: any) => changeRows(value)"
								@update:page="(value: number) => paginate(value)"
								:current-page="page"
								:last-page="lastPage"></minify-pagination>
						</div>
					</template>
				</data-table>
			</div>
		</div>
        <page-loader :show="loading" />
	</user-layout>
</template>
<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import DataTable from "@/components/partials/DataTable.vue";
import MinifyPagination from "@/components/partials/MinifyPagination.vue";
import PatientService from "@/classes/PatientService";
import pickBy from "lodash/pickBy";
import Storage from "@/helpers/storage";
import { useRouter, useRoute } from "vue-router";
import { PatientSearch } from "@/interfaces/PatientInterface";
import { useNetworkStore } from "@/store/network";
import DateFormatter from "@/helpers/DateFormatter";
import InterventionService from "@/classes/Interventions/InterventionService"
import SearchIcon from "@/components/icons/searchIcon.vue";
import PaperIcon from "@/components/icons/paperIcon.vue";
import PageLoader from "@/components/loaders/PageLoader.vue";

const networkStore = useNetworkStore();

const isNetworkAvailable = computed<boolean>(():boolean => networkStore.isNetworkAvailable);

const headers: { text: string; haveSort?: boolean }[] = [
	{ text: "Patient Name", haveSort: true },
	{ text: "Birthday" },
	{ text: "Date Registered", haveSort: true },
];

const router = useRouter();
const route = useRoute();
const storage = new Storage();

interface itemsInterface {
	id: number;
	name: string | null;
	birthdate_formatted: string | null;
	registered_at_formatted: string | null;
}

const loading = ref<boolean>(false);
const items = ref<itemsInterface[]>([]);
const page = ref<string | null>(route.query.page);
const lastPage = ref<number | null>(null);
const rows = ref<number | null>(route.query.rows || 5);
const numRows = computed<string | null>(() => (rows.value ? rows.value + " Rows" : "All"));
const search = ref<PatientSearch>({
	last_name: route.query.last_name,
	first_name: route.query.birthdate,
	birthdate: route.query.first_name,
	mode: 'patient',
});

const getData = () => {
    loading.value = true;
	InterventionService
		.patientSearch(
			{ 
				...search.value,
				...{ page: page.value, rows: rows.value } 
			}, 
			isNetworkAvailable.value
		)
		.then((response: any) => {
            console.log(response.data);
            items.value = response.data.items.data;
			page.value = response.data.items.current_page;
			lastPage.value = response.data.items.last_page;
			storage.set("search", JSON.stringify(search.value));
            loading.value = false;
		})
		.catch((error) => {
            loading.value = false;
			console.log(error);
		});
};

const reloadPage = async () => {
	return await router.push({
		path: "/intervention/index",
		query: pickBy({ ...search.value, ...{ page: page.value, rows: rows.value } }),
	});
};

const applyFilters = () => {
	reloadPage().then(() => {
		getData();
	});
};

const paginate = (data: number): void => {
	page.value = data;
	applyFilters();
};

const changeRows = (row: any): void => {
	rows.value = row;
	applyFilters();
};

const getSearchFromStorage = (): void => {
	const searchData = JSON.parse(storage.get("search"));
	search.value.last_name = searchData ? searchData.last_name : search.value.last_name;
	search.value.first_name = searchData ? searchData.first_name : search.value.first_name;
	search.value.birthdate = searchData ? searchData.birthdate : search.value.birthdate;
};

onMounted(() => {
	Promise.allSettled([getSearchFromStorage()]).then(() => {
		applyFilters();
	});
});
</script>
