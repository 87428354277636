<template>
	<user-layout
		hasBack
		:backLink="`/patient/${patientId}/cases/${caseId}/edit`"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>
			<div class="flex md:flex-row flex-col items-center md:space-x-3 md:space-y-0 space-y-3">
				<a
					@click="
						router.push(
							`/patient/${patientId}/cases/${caseId}/edit`
						)
					"
					class="md:w-auto w-full md:mt-0 mt-6">
					<button-component btnWidth="quinary" customClass="quinary md:w-auto w-full">
						Cancel
					</button-component>
				</a>
				<button-component
					customClass="primary md:w-auto w-full md:mt-0 mt-6"
					form="form"
					@click="validateForm()"
				>
					<span>Save Changes</span>
				</button-component>
			</div>
		</template>
		<p class="mt-6 md:text-2xl text-lg font-medium text-neutral-600">Patient's Name:
			<span class="md:inline-block block font-semibold text-neutral-900">{{ patient }}</span>
		</p>
		<div class="w-full lg:p-6 p-4 mx-auto my-6 border border-gray-200 rounded">
			<div class="flex items-center justify-between">
				<section-title title="Address Details" />
			</div>
			<form-section
				v-if="loaded"
				:form="form"
				:errors="errors"
				:regions="regions"
				:provinces="provinces"
				:cities="cities"
				:barangays="barangays"
				:residenceTypes="residenceTypes" 
                @update:form="(value: any) => form = value"
                />
		</div>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Save!"
			:content="successMessage"
		/>
 
 
		<page-loader :show="loading"/>
 
 
	</user-layout>
 </template>
 <script setup lang="ts">
 import { ref, computed, watch} from "vue";
 import UserLayout from "@/layouts/UserLayout.vue";
 import SectionTitle from "@/components/partials/SectionTitle.vue";
 import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
 import SuccessModal from "@/components/modals/SuccessModal.vue";
 import FormSection from "./FormSection.vue";
 import { useRouter, useRoute } from "vue-router";
 import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface"; 
 import { errorResponse } from "@/helpers/errorMessage";
 import pickBy from "lodash/pickBy";
 import PageLoader from "@/components/loaders/PageLoader.vue"
 import { useRecordStatusChecker } from "@/store/recordStatusChecker";
 import { onIonViewWillEnter } from "@ionic/vue";
 /******************************* For Offline Imports************************************ */
 import { useNetworkStore } from "@/store/network";
 import CasePatientAddressForm from "@/classes/Cases/CasePatientAddress/Offline/Formatters/CasePatientAddressForm";
 import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
 import Rules from "@/classes/Cases/CasePatientAddress/Offline/Validation/Rules";
 import CasePatientAddressService from "@/classes/Cases/CasePatientAddress/CasePatientAddressService";
 import PatientAddressCreate from "@/classes/Cases/CasePatientAddress/Offline/Interface/CasePatientInterface";
 import useVuelidate from "@vuelidate/core";
 import CasePatientAddressOfflineProcessor from "@/classes/Cases/CasePatientAddress/Offline/Processor/OfflineProcessor";
 import OnlineSynching from "@/classes/Synching/OnlineSynching";
 const { createRules } = Rules;
 const networkStore = useNetworkStore();
 const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
 /*************************************************************************************** */
 
 
 const router = useRouter();
 const route = useRoute();
 const showSuccess = ref<boolean>(false);
 const loading = ref<boolean>(false);
 const patientId = ref<number | null>(route.params.patient);
 const caseId = ref<number | null>(route.params.case);
 const patientAddressId = ref<number | null>(route.params.patientAddress);
 const regions = ref<renderSelect[]>([]);
 const cities = ref<renderSelect[]>([]);
 const provinces = ref<renderSelect[]>([]);
 const barangays = ref<renderSelect[]>([]);
 const residenceTypes = ref<renderSelectV2[]>([]);
 
 
 //This form used by both online and offline
 const form = ref<PatientAddressCreate>(CasePatientAddressForm.updateForm({}));
 
 
 const errors = ref<object>({});
 const successMessage = ref<string>("Patient address details has been updated");
 const patient = ref<string>("");
 const loaded = ref<boolean>(false);
 const intakeParams = ref<string>(route.query.intake);
 const recordStatusChecker = useRecordStatusChecker();
 const onlineSync = new OnlineSynching();
 
 
 /**
 * Validate the form using Vuelidator
 */
 const validateForm = () =>{
	const vuelidate = useVuelidate(createRules({...form.value}), form.value)
	const result = vuelidate.value.$validate();
 
 
	result
		.then((res) => {
			if(res) {
				save()
			} else {
				errors.value = ErrorValidation.getErrors(vuelidate.value)
                errorResponse(errors.value);
			}
		})
 }
 
 
 
 
 const save = async (): Promise<any> => {
	errors.value = {};
 
 
	const response = await CasePatientAddressService.update(
		patientId.value,
		caseId.value,
		patientAddressId.value,
		{ ...form.value },
		isNetworkAvailable.value
	);
 
 
	if (response?.status === 200 || response?.data != undefined) {
        if (isNetworkAvailable.value) {
            await CasePatientAddressOfflineProcessor.syncToOffline(caseId.value, response.data.data.item);
        }

		setTimeout(() => {
			recordStatusChecker.isCreatedPatientAddress = true;
			showSuccess.value = true;
			successMessage.value = response.data.message;
			loading.value = false;
		}, 500);
	} else {
		const error = response;
		errors.value = errorResponse(error.response?.data?.errors);
	}
 };
 const redirectSuccess = async (): void => {
	showSuccess.value = false;
	if (intakeParams.value) {
		router.replace(
			`/patient/${patientId.value}/cases/${caseId.value}/intake/address-present-location`
		);
	} else {
		router.replace(`/patient/${patientId.value}/cases/${caseId.value}/edit`);
	}
 };
 
 
 const getData = async (): Promise<any> => {
	const response = await CasePatientAddressService.edit(
		patientId.value,
		caseId.value,
		patientAddressId.value,
		pickBy({}),
		isNetworkAvailable.value
	);

    if (response?.response?.status === 404) {
        loaded.value = true;
        return;
    }
   
	if (response?.status === 200 || response?.data != undefined) {
		form.value = CasePatientAddressForm.updateForm(response.data.item);
		patient.value = response.data.patient;
		residenceTypes.value = response.data.residenceTypes;
		
	if(!isNetworkAvailable.value){
			form.value = CasePatientAddressForm.updateForm(response.data.item[0]);
		}

        if (isNetworkAvailable.value) {
            await CasePatientAddressOfflineProcessor.syncToOffline(caseId.value, response.data.item);
        }
        
		loaded.value = true;
	}
 };

 watch(
    () => isNetworkAvailable.value,
    async () => {
        loaded.value = false;
        await getData();
        await onlineSync.checkDrafts();
    }
)

 onIonViewWillEnter(async () => {
	recordStatusChecker.isCreatedPatientAddress = false;
	await getData();
    await onlineSync.checkDrafts();
 });
 </script>