<template>
	<user-layout
		hasBack
		:backLink="`/patient/${patientId}/cases/${caseId}/edit`"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>
			<div class="flex flex-col items-center space-y-3 md:flex-row md:space-x-3 md:space-y-0">
				<a
					@click="
						router.push(
							`/patient/${patientId}/cases/${caseId}/edit`
						)
					"
					class="w-full mt-6 md:w-auto md:mt-0">
					<button-component btnWidth="quinary" customClass="quinary md:w-auto w-full">
						Cancel
					</button-component>
				</a>
				<button-component
					customClass="primary md:w-auto w-full md:mt-0 mt-6"
					form="form" 
					@click="save()"
				>
					<span>Save Changes</span>
				</button-component>
			</div>
		</template>
		<p class="mt-6 text-lg font-medium md:text-2xl text-neutral-600">Patient's Name:
			<span class="block font-semibold md:inline-block text-neutral-900">{{ patient }}</span>
		</p>
		<div class="w-full p-4 mx-auto my-6 border border-gray-200 rounded lg:p-6">
			<div class="flex items-center justify-between">
				<section-title title="Perpetrator Details" />
			</div>
			<form-section
				ref="formRef"
				:item="item"
				:form="form"
				:errors="errors"
				:genders="genders"
				:relationships="relationships"
				:nationalities="nationalities"
				:religions="religions"
				:currentLocations="currentLocations"
				:identities="identities" 
			/>
		</div>
		
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Save!"
			:content="successMessage"
		/>

		<page-loader :show="loading"/>
	</user-layout>

</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import FormSection from "./FormSection.vue";
import { useRouter, useRoute } from "vue-router";
import PerpetratorService from "@/classes/Perpetrators/PerpetratorService";
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import { PerpetratorCreate } from "@/interfaces/Cases/Perpetrator";
import PerpetratorForm from "@/classes/Forms/Cases/PerpetratorForm";
import { errorResponse } from "@/helpers/errorMessage";
import { useNetworkStore } from "@/store/network";
import pickBy from "lodash/pickBy"; 
import SqliteRecordChecker from "@/classes/Forms/SqliteRecordChecker";
import PageLoader from "@/components/loaders/PageLoader.vue"
import { useRecordStatusChecker } from "@/store/recordStatusChecker"; 
import { onIonViewWillEnter } from "@ionic/vue"; 
import PerpetratorOfflineService from "@/classes/Perpetrators/Offline/OfflineService";
import PerpetratorOfflineForm from "@/classes/Perpetrators/Offline/Formatters/PerpetratorOfflineForm";
import Rules from "@/classes/Perpetrators/Offline/Validation/Rules";
import useVuelidate from "@vuelidate/core";
import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";

const networkStore = useNetworkStore(); 
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const checkRecord = new SqliteRecordChecker(); 
const router = useRouter();
const route = useRoute();
const showSuccess = ref<boolean>(false);
const loading = ref<boolean>(false); 
const patient = ref<string>('');
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const genders = ref<renderSelectV2[]>([]);
const identities = ref<renderSelectV2[]>([]);
const nationalities = ref<renderSelect[]>([]);
const religions = ref<renderSelect[]>([]);
const relationships = ref<renderSelect[]>([]);
const currentLocations = ref<renderSelect[]>([]);
const formRef = ref<any>([]); 
const form = ref<PerpetratorCreate>(PerpetratorOfflineForm.formStructure());
const { createRules } = Rules;

const errors = ref<object>({});
const successMessage = ref<string>("New perpetrator has been added");
const item = ref<PerpetratorCreate>(PerpetratorOfflineForm.formStructure()); 
const recordStatusChecker = useRecordStatusChecker(); 

/**
 * Validate the form using Vuelidator
 */
 const validateForm = async () =>{
	const vuelidate = useVuelidate(createRules({...form.value}), form.value)
	const result = await vuelidate.value.$validate();

    if (result) {
        return null;
    } else {
        errors.value = ErrorValidation.getErrors(vuelidate.value);
        errorResponse(errors.value);
        return errors.value;
    }
}

const save = async (): Promise<any> => {
	loading.value = true;
	errors.value = {};

    // Set validation rule for Online and Offline
    const validate = await validateForm();
    if (validate) {
        loading.value = false;
        return;
    }

    const response = await PerpetratorService.store(
		patientId.value,
		caseId.value,
		{ ...form.value },
        isNetworkAvailable.value,
	);

    if (response?.status === 200 || response?.data != undefined) { 
        const data = response.data.data;
        // if (isNetworkAvailable.value) {
        //     await PerpetratorOfflineService.syncToOffline(patientId.value, caseId.value, data.item);
        // }
        resetForm()
        loading.value = false;
        showSuccess.value = true;
        successMessage.value = response.data.message;
        recordStatusChecker.isCreatedPerpetrator = true;
    } else {
        loading.value = false;
        const error = response;
        errors.value = errorResponse(error.response?.data?.errors);
    }
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.push(
		`/patient/${patientId.value}/cases/${caseId.value}/edit`
	);
};

const getData = async (): Promise<any> => {
    const response = await PerpetratorService.create(
		patientId.value,
		caseId.value,
		pickBy({}),
        isNetworkAvailable.value
	);

    if (response?.response?.status === 404) {
        return;
    }

    if (response?.status === 200 || response.data != undefined) {
		genders.value = response.data.genders;
		identities.value = response.data.identities;
		nationalities.value = response.data.nationalities;
		religions.value = response.data.religions;
		relationships.value = response.data.perpetratorRelationships;
		currentLocations.value = response.data.currentLocations;
		form.value.is_minor = false;
		form.value.tag = response.data.unknownTag;
		patient.value = response.data.patient;
		item.value = { tag: form.value.tag };
       
    } else {
        console.log(response);
    }
};

const resetForm = () => {
    form.value.tag = null;
	form.value.details = null;
	form.value.identity = null;
	form.value.is_minor = null;
	form.value.last_name = null;
	form.value.first_name = null;
	form.value.middle_name = null;
	form.value.gender = null;
	form.value.birthday_classification = null;
	form.value.birthdate = null;
	form.value.approximate_age_year = null;
	form.value.approximate_age_month = null;
	form.value.perpetrator_relationship_id = null;
	form.value.other_relationship = null;
	form.value.nickname = null;
	form.value.occupation = null;
	form.value.nationality_id = null;
	form.value.other_nationality = null;
	form.value.religion_id = null;
	form.value.current_location = null;
	form.value.perpetrator_location = null;
	form.value.about_perpetrator = null;
	form.value.contacts = null;

	formRef.value.birthdateForm.datePickerData.picker.clearValue()
 
}

watch(
    () => isNetworkAvailable.value,
    () => {
        loading.value = false;
        getData();
    }
);
 

onIonViewWillEnter(async () => {
    recordStatusChecker.isCreatedPerpetrator = false;  
    resetForm();
    await getData();
});

onMounted(async () => {
	recordStatusChecker.isCreatedPerpetrator = false; 
    resetForm();
    await getData();
});
</script>
