import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "../defaultMigration";


export const case_patient_addresses: SchemaTableOptions = {
   name:<string> 'case_patient_addresses',
   schema:<Array<SchemaColumnOptions>> [
       { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
       { column: 'cases_id', value: 'INTEGER UNSIGNED'},
       { column: 'region_id', value: 'INTEGER UNSIGNED'},
       { column: 'province_id', value: 'INTEGER UNSIGNED'},
       { column: 'city_id', value: 'INTEGER UNSIGNED'},
       { column: 'barangay_id', value: 'INTEGER UNSIGNED'},
       { column: 'residence_type', value: 'TINYINT UNSIGNED'},
       { column: 'address', value: 'VARCHAR(255) NOT NULL'},
       { column: 'direction', value: 'VARCHAR(255) DEFAULT NULL'},
       { column: 'remarks', value: 'TEXT DEFAULT NULL'},
       { column: 'encoded_by', value: 'INTEGER UNSIGNED'},
       ...defaultMigration
   ]
}
