<template>
    <template v-if="form[fieldIndex]">
        <div  :class=" field.is_half_width ? 'md:col-span-3 col-span-6' : 'col-span-6' "  >
            <div v-if="FormFieldType.DROPDOWN == field.input_type">
                <MultiSelect
                    :options="field.options"
                    :id="field.source_id"
                    :name="field.label"
                    :label="field.label"
                    placeholder="Select.."
                    :required="field.is_required"
                    searchable
                    v-model="form[fieldIndex].value"
                    :error="errors[fieldIndex]" 
                    :disabled="disabledField"
                    @update:modelValue="hasForm()"
                /> 
            </div>

            <div v-if="FormFieldType.DROPDOWN_EXISTING == field.input_type">
                <MultiSelect
                    :options="field.options"
                    :id="field.source_id"
                    :name="field.label"
                    :label="field.label"
                    placeholder="Select.."
                    :required="field.is_required"
                    searchable
                    v-model="form[fieldIndex].value"
                    :error="errors[fieldIndex]" 
                    :disabled="disabledField"
                    @update:modelValue="existingDropdown()"
                /> 
            </div>

            <div v-if="FormFieldType.MULTI_SELECT == field.input_type">
                <MultiSelect
                    mode="tags"
                    :options="field.options"
                    :id="field.source_id"
                    :name="field.label"
                    :label="field.label"
                    placeholder="Select.."
                    :required="field.is_required"
                    searchable
                    v-model="form[fieldIndex].value"
                    :error="errors[fieldIndex]" 
                    :disabled="disabledField"
                /> 
            </div>
    
            <div v-if="FormFieldType.TOGGLE == field.input_type">

                <div class="p-3 space-y-3" :id="field.source_id">

                    <p class="text-xs font-semibold text-neutral-600">
                        {{ field.label }} <span class="text-red-600" v-if="field.is_required">*</span>
                    </p>

                    <toggle-switch
                        :value="form[fieldIndex].value"
                        noPadding
                        @change="toggleChange()"
                    />
                </div>

                <p
                    v-if="errors[fieldIndex]"
                    class="mt-2 text-sm text-red-600" 
                    id="email-error"
                >
                    {{ errors[fieldIndex] }}
                </p>

            </div>
    
            <div v-if="FormFieldType.DATE_TIME == field.input_type">
                <date-picker
                    :id="field.source_id"
                    :name="field.label"
                    :label="field.label"
                    :enableTimePicker="true"
                    :timePickerInline="true"
                    :required="field.is_required"
                    :isTwentyFour="false"
                    v-model="form[fieldIndex].value"
                    :error="errors[fieldIndex]" 
                    :disabled="disabledField"
                />
            </div>

            <div v-if="FormFieldType.DATE == field.input_type">
                <date-picker
                    :id="field.source_id"
                    :name="field.label"
                    :label="field.label"
                    :required="field.is_required"
                    v-model="form[fieldIndex].value"
                    :error="errors[fieldIndex]" 
                    :enableTimePicker="false"
                    :disabled="disabledField"
                />
            </div>

            <div v-if="FormFieldType.TIME == field.input_type">
                <time-picker
                    :id="field.source_id"
                    :name="field.label"
                    :label="field.label"
                    :required="field.is_required"
                    v-model="form[fieldIndex].value"
                    :error="errors[fieldIndex]" 
                    :disabled="disabledField"
                    timePicker
                    enableTimePicker
                />
            </div>

            <div v-if="FormFieldType.TEXT == field.input_type">
                <CKEditor
                    :name="field.label"
                    :placeholder="field.label"
                    :label="field.label"
                    :id="field.source_id"
                    :required="field.is_required"
                    v-model="form[fieldIndex].value"
                    :error="errors[fieldIndex]" 
                    :disabled="disabledField"
                />
            </div>

            <div v-if="FormFieldType.INPUT == field.input_type">
                <text-input
                    :name="field.label"
                    :placeholder="field.label"
                    :label="field.label"
                    :id="field.source_id"
                    :required="field.is_required"
                    v-model="form[fieldIndex].value"
                    :error="errors[fieldIndex]" 
                    :disabled="disabledField"
                />
            </div>

            <div v-if="FormFieldType.NUMBER == field.input_type">
                <text-input
                    type="number"
                    :name="field.label"
                    :placeholder="field.label"
                    :label="field.label"
                    :id="field.source_id"
                    :required="field.is_required"
                    v-model="form[fieldIndex].value"
                    :error="errors[fieldIndex]" 
                    :disabled="disabledField"
                />
            </div>
            
            
            <div v-if="FormFieldType.LINK == field.input_type">
                <text-input
                    type="text"
                    :name="field.label"
                    :placeholder="field.label"
                    :label="field.label"
                    :id="field.source_id"
                    :required="field.is_required"
                    :trailingIcon="LinkIcon"
                    @iconClick="openLink()"
                    customClass="!pr-10"
                    v-model="form[fieldIndex].value"
                    :error="errors[fieldIndex]" 
                    :disabled="disabledField"
                />
            </div>

            <div v-if="FormFieldType.CHECKBOX_SINGLE == field.input_type">
                
                <div class="flex flex-col space-y-3 " :id="field.source_id">

                    <div 
                        class="flex items-center space-x-3 " 
                        id="actions"
                    >
                        <checkbox-input
                            :label="field.label"
                            :placeholder="field.label"
                            :id="field.source_id"
                            :checked="field?.options[0]?.value == form[fieldIndex].value"
                            @update:modelValue="checkboxSingleSelect(field?.options[0]?.value ?? null)"
                        />

                        <span class="text-red-600" v-if="field.is_required">*</span>
                    </div>

                    <p
                        v-if="errors[fieldIndex]"
                        class="mt-2 text-sm text-red-600" 
                        id="email-error"
                    >
                        {{ errors[fieldIndex] }}
                    </p>

                </div>
            </div>

            <div v-if="FormFieldType.CHECKBOX_OPTION == field.input_type">
                
                <div class="space-y-3" :id="field.source_id">

                    <p class="text-xs font-semibold text-neutral-600">
                        {{ field.label }} <span class="text-red-600" v-if="field.is_required">*</span>
                    </p>
                    
                    <div class="flex flex-col space-y-3">
                        <div 
                            class="items-center p-1 space-x-3" 
                            id="actions"
                            v-for="(option, index) in field.options" :key="index"
                        >
                            <checkbox-input
                                :name="option.label"
                                :label="option.label"
                                :id="option.source_id"
                                :checked="inFieldValue(option.value)"
                                @update:modelValue="checkboxOptionsSelect(option.value)"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="FormFieldType.RADIO == field.input_type">
                <div class="mb-4">
                    <p class="mb-2 text-xs font-semibold text-neutral-600">
                        {{ field.label }} <span class="text-red-600" v-if="field.is_required">*</span>
                    </p>
                    <div class="flex flex-col space-y-3">
                        <radio-group 
                            :id="field.source_id"
                            :options="field?.options ?? yesNoOption" 
                            :name="field.label + field.source_id" 
                            v-model="form[fieldIndex].value"
                            @update:modelValue="radioChange"
                            :readyOnly="disabledField"
                        />

                    </div>
                    <p
                        v-if="errors[fieldIndex]"
                        class="mt-2 text-sm text-red-600" 
                        id="email-error"
                    >
                        {{ errors[fieldIndex] }}
                    </p>
                </div>
                
            </div>
            <div
                v-if="hasPrerequisiteInfo()"
                class="flex text-xs italic text-gray-600"
            >
                <InformationCircleIcon class="w-5 h-5 cursor-pointer text-primary-600" />
                <p>This field is connected to patient information record. If blank, there is no data encoded.</p>
            </div>
        </div>

        <template v-if="subForms?.fields != null && loaded">
            <template 
                v-for="(subForm, index) in subForms.fields" 
                :key="index"
            >
                <form-field 
                    :field="subForm"
                    :form="form"
                    :errors="errors"
                    :patient="patient"
                />
            </template>
        </template>
        <template v-else-if="subForms?.fields != null && !loaded">
            <div :class=" field.is_half_width ? 'md:col-span-3 col-span-6' : 'col-span-6' " >
                <ion-skeleton-text :animated="true" style="width: 100%; height: 100%" />  
            </div>
        </template>

        <template v-if="FormFieldType.DROPDOWN_EXISTING == field.input_type && field.option_child && reloadChildDropdown">
            <form-field 
                :field="field.option_child"
                :form="form"
                :errors="errors"
                :patient="patient"
            />
        </template>
        <template v-else-if="FormFieldType.DROPDOWN_EXISTING == field.input_type && field.option_child && !reloadChildDropdown">
            <div :class=" field.is_half_width ? 'md:col-span-3 col-span-6' : 'col-span-6' " >
                <ion-skeleton-text :animated="true" style="width: 100%; height: 100%" />  
            </div>
        </template>

    </template>
</template>

<script setup lang="ts">
import { ref, type PropType, onMounted, watch, computed } from "vue";
import { IonSkeletonText, onIonViewWillEnter } from '@ionic/vue';

//Main Components
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import CKEditor from "@/components/inputs/CKEditor.vue";
import TimePicker from "@/components/inputs/TimePicker.vue";
import ToggleSwitch from "@/components/inputs/ToggleSwitch.vue";

//Components
import FormField from "@/views/interventions/Components/Input/FormField.vue"

//Icons
import { LinkIcon, InformationCircleIcon } from "@heroicons/vue/24/outline";

//Enums
import { FormFieldType } from "@/views/enums/FormFieldType";

//Classes
import DropdownService from "@/classes/Dropdowns/DropdownService";
import { useNetworkStore } from "@/store/network";

//Interfaces
import { 
    FieldInterface,
    FormInterface,
    PatientInterface, 
} from "@/interfaces/pages/InterventionForm/InterventionFormInterface";

const networkStore = useNetworkStore();

const props = defineProps({
    field: {
        type: Object as PropType<FieldInterface>,
        required: true
    },
    form: {
        type: Object as PropType<FormInterface[]>,
        required: true
    },
    disabled:  {
        type: Boolean,
        required: false
    },
    errors: {
        type: Object,
        required: true
    },
    isEdit: {
        type: Boolean,
        default: false
    },
    isDone: {
        type: Boolean,
        default: false
    },
    patient:  {
        type: Object as PropType<PatientInterface>,
        required: true
    },
});

const form = ref<any>(props.form)

const field = ref<FieldInterface>(props.field)

const subForms = ref<any>({
    parent: null,
    fields: null
})

const errors = ref<any>(props.errors)
const loaded = ref<boolean>(false)
const reloadChildDropdown = ref<boolean>(true)

const isNetworkAvailable = computed<boolean>(():boolean => networkStore.isNetworkAvailable);

const defaultFormTags: string[] = [
    "patient_name",
    "patient_age",
    "patient_birthday",
    "patient_education",
    "patient_guardian",
    "office",
    "patient_assessment_date",
    "patient_education_name",
    "patient_sex",
]

const yesNoOption = [
	{ id: 1, label: "Yes", value: true },
	{ id: 0, label: "No", value: false },
];

const disabledField = computed<boolean>(():boolean => {
    if(defaultFormTags.includes(field.value.form_tag)){
        return true;
    }

    if(field.value.form_tag == 'done_date_time' && props.isDone){
        return false;
    }

    return field.value.form_tag == 'type' && props.disabled
})

const hasPrerequisiteInfo = ():boolean => {
    if(defaultFormTags.includes(field.value.form_tag)){
        return true;
    }

    return false;
}

const fieldIndex = computed<number>(():number => {
    return Object.values(form.value).map((item:any) => item.source_id).indexOf(field.value.source_id)
})

const init = async ():void => {
    loaded.value = false;
    
    if(fieldIndex.value == -1){
        form.value.push({
            "source_type": field.value.source_type,
            "source_id": field.value.source_id,
            "label": field.value.label,
            "input_type": field.value.input_type,
            "is_required": field.value.is_required,
            "form_tag": field.value.form_tag,
            "value": null,
            "option_child": field.value?.option_child?.source_id ?? null,
        });

        if(FormFieldType.MULTI_SELECT == field.value.input_type){
            form.value[fieldIndex.value].value = [];
        }

        errors.value[fieldIndex.value] = null;
    } else {
        loadFormData();
    }

    if(field.value?.option_class){
        await getFieldOptions()
    }
    
    setFormTagsDefaultValue()

    setTimeout(() => {
        loaded.value = true;
    }, 300);
}

const setFormTagsDefaultValue = ():void => {
    if(defaultFormTags.includes(field.value.form_tag)){
        form.value[fieldIndex.value].value = props.patient[field.value.form_tag]
    }
}

const loadFormData = ():void => {
    const multiFormFlied = [
        FormFieldType.DROPDOWN,
        FormFieldType.RADIO,
        FormFieldType.CHECKBOX_SINGLE,
        FormFieldType.CHECKBOX_OPTION,
        FormFieldType.TOGGLE,
    ];
    
    if(multiFormFlied.includes(form.value[fieldIndex.value].input_type)){
        hasForm()
    }
}

const openLink = (): void => {

    if(form.value[fieldIndex.value].value){
        errors.value[fieldIndex.value] = null;
        window.open(form.value[fieldIndex.value].value, "_blank");
    } else {
        errors.value[fieldIndex.value] = "Please enter url"
    }
}

const checkboxSingleSelect = (value:number|null):void => {
    if(value){
        if(form.value[fieldIndex.value].value == value){
            form.value[fieldIndex.value].value = null
        } else {
            form.value[fieldIndex.value].value = value
        }
    
        hasForm();
    } else {
        console.error('error');
    }
}

const toggleChange = ():void => {

    if(form.value[fieldIndex.value].value == field.value?.options[0]?.id){
        form.value[fieldIndex.value].value = null
    } else {
        form.value[fieldIndex.value].value = field.value?.options[0]?.id
    }

    hasForm()
}

const inFieldValue = (value:number): boolean => {
    if(!Array.isArray(form.value[fieldIndex.value].value)){
        return false;
    }

    return form.value[fieldIndex.value].value.includes(value);
}

const checkboxOptionsSelect = (value:number):void => {

    if(!Array.isArray(form.value[fieldIndex.value].value)){
        form.value[fieldIndex.value].value = [];
    }

    const index = form.value[fieldIndex.value].value.indexOf(value)

    if(index == -1) {
        form.value[fieldIndex.value].value.push(value)
    } else {
        form.value[fieldIndex.value].value.splice(index, 1) 
    }

    hasForm();
}

const radioChange = (value:number):void => {
    form.value[fieldIndex.value].value = value
    hasForm();
}

const getFieldOptions = async ():void => {
    reloadChildDropdown.value = false;
    let parentValue = null;
    
    if(field.value.option_trigger_id){
        field.value.options = null;

        const parentIndex = form.value.map((item:any) => item.source_id).indexOf(field.value.option_trigger_id)

        parentValue = form.value[parentIndex]?.value ?? null;
    }

    await DropdownService
        .interventionFieldOptions(isNetworkAvailable.value, field.value.option_class, field.value.option_trigger_column, parentValue, )
        .then(({data}) => {
            reloadChildDropdown.value = true;
            field.value.options = data
        })
}

//Builds the sub-form 
const hasForm = async ():Promise<void> => {
    loaded.value = false;

    // Check if the selected option does or does not have a sub-form
    if(!field.value?.option_trigger_id != null && field.value?.options){
        const index = field.value.options.map((item:any) => item.value).indexOf(form.value[fieldIndex.value].value)
        
        form.value[fieldIndex.value].value_label = field.value.options[index]?.label;
        
        //Remove previous sub-form from the form
        if(subForms.value.fields != null && form.value[fieldIndex.value].value != subForms.value?.parent){
            await removePreviousSubForm();
        } 
        
        // Resets the sub-form if the selected option does not have a sub-form
        if(field.value.options[index]?.form == undefined) {
            subForms.value = {
                parent: null,
                fields: null
            }
        } 
        
        // Creates the new sub-form if the selected options has a sub-form
        if(index != -1 && field.value.options[index]?.form != undefined){
            subForms.value = {
                parent: form.value[fieldIndex.value].value,
                fields: field.value.options[index]?.form.fields
            }
        }
    }

    setTimeout(() => {
        loaded.value = true;
    }, 500);
}

const removePreviousSubForm = async ():Promise<void> => {

    new Promise((resolve, reject) => {

        const formValue = form.value.map((item:any) => item.source_id)

        subForms.value.fields.forEach((element:FormInterface) => {

                const fieldIndex = formValue.indexOf(element.source_id)

                if(form.value[fieldIndex] != undefined){

                    if(form.value[fieldIndex].option_child) {
                        removeChildOptionFiled(formValue, form.value[fieldIndex].option_child)
                    }
                    
                    form.value.splice(fieldIndex, 1);
                }
            }
        );   
        
        resolve(null);
    })
}


const removeChildOptionFiled = (formValue:any, option_child:number):void => 
{
    const fieldIndex = formValue.indexOf(option_child)

    if(form.value[fieldIndex] != undefined){
        form.value.splice(fieldIndex, 1);
    }
}

const existingDropdown = ():void => {
    reloadChildDropdown.value = false;

    if(field.value.child_option_trigger_id){
        const index = form.value.map((item:any) => item.source_id).indexOf(field.value.child_option_trigger_id)
        form.value[index].value = null
    }
    
    // hasForm()

    setTimeout(() => {
        reloadChildDropdown.value = true;
    }, 300);
    
}
    
watch(
    form.value,
    () => {
        if(field.value.option_trigger_id ){
            getFieldOptions();
        }
    },
);

onMounted(() => {
    init()
})
    
onIonViewWillEnter(() => {
    init()
})

</script>